import { lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IColorBox {
  color: string;
}

export const Container = styled.div`
  .bd-color {
    border-radius: 8px;
    border: 1px solid #e1e1e1;

    background: #fff;
  }

  .input {
    border: 1px solid #d3d3d3 !important;

    input {
      color: #202020;
    }
  }

  .archives {
    border-radius: 8px;
    border: 1px solid #e1e1e1;

    img {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      border: 1px solid #002f6c;
      object-fit: contain;
    }
  }

  .label-modal {
    padding: 34px 30px;
    border-radius: 20px;
    border: 1px dashed #ff6900;
  }

  .label-modal {
    span:nth-child(2) {
      font-family: 'Roboto';
      color: #8c8c8c;
      font-size: 16px;
      font-weight: 400;
    }

    span:nth-child(3) {
      border-radius: 4px;
      border: 1px solid #ff6900;
      color: #ff6900;
      font-size: 16px;
      font-weight: 400;
      padding: 8px 16px;
    }

    span:nth-child(4) {
      color: #8c8c8c;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
    }
  }

  .variations {
    .variation-row {
      > div:first-child {
        width: 50px;

        .size {
          width: 32px;
          height: 32px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 4px !important;
          border: 1px solid #ff842f !important;
          color: #ff842f !important;
          background-color: #fff;
          margin: 0 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      > div:last-child {
        width: calc(100% - 50px);
      }

      + .variation-row {
        margin-top: 10px;
      }
    }
  }
`;

export const ColorBox = styled.div<IColorBox>`
  min-width: 24px;
  min-height: 24px;
  border-radius: 4px;
  background: ${(props) => props.color};
  border: 2px solid ${(props) => lighten(0.251, props.color)};
`;

export const Modal = styled(ModalComponent)`
  backdrop-filter: blur(3px);
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.5);

  .btn-filter {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .input {
    border: 1px solid #d3d3d3 !important;

    input {
      color: #202020;
    }
  }
`;
