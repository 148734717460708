import styled from 'styled-components';

interface IMenuProps {
  active: boolean;
}

export const Container = styled.header`
  min-width: 88px;
`;

export const Menu = styled.div<IMenuProps>`
  min-width: ${(props) => (props.active ? 215 : 72)}px;
  background-color: #002f6c;
  min-height: calc(100vh - 32px);
  top: 16px;
  left: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
  z-index: 2;

  button:not(.not-path),
  a {
    color: #bfcbda;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    line-height: 15px;
    svg {
      width: 24px;
      height: 24px;
      color: #bfcbda;
      margin-right: 5px !important;
      path:not(.question-mark) {
        fill: #bfcbda;
      }
    }

    :hover:not(.btn-arrow) {
      color: #fff !important;
      span {
        color: #fff !important;
      }
      svg {
        color: #fff !important;
        path:not(.question-mark) {
          fill: #fff !important;
        }
      }
    }
  }

  li::marker {
    color: #bfcbda;
  }

  .active {
    color: #fff !important;
    span {
      color: #fff !important;
    }
    svg {
      color: #fff !important;
      path:not(.question-mark) {
        fill: #fff !important;
      }
    }
  }

  .arrow {
    transition-duration: 0.3s;
  }

  .arrow-left {
    transform: rotateZ(90deg);
  }

  .logo-active {
    width: 139px;
    height: 32px;
  }

  .logo-not-active {
    width: 27px;
    height: 32px;
  }

  .btn-arrow {
    top: 20px;
    right: -25px;
    padding: 10px 13px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;

    :hover {
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    }

    svg {
      transition: all 0.2s ease-in-out;
      transform: ${(props) =>
        props.active ? 'rotate(180deg)' : 'rotate(360deg)'};
    }
  }
`;
