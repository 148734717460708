import React from 'react';
import { IProps } from './interfaces';

const KrTargetEnrolled: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2703)">
        <path
          d="M10.4 12.96C9.96 12.55 9.28 12.58 8.87 13.02C8.46 13.46 8.49 14.14 8.93 14.55L16.07 21.19L31.55 4.27999C31.95 3.83999 31.92 3.14999 31.48 2.74999C31.04 2.34999 30.35 2.37999 29.95 2.81999L15.93 18.11L10.39 12.96H10.4Z"
          fill={color}
        />
        <path
          d="M31.25 11.72C31.09 11.14 30.49 10.81 29.91 10.97C29.33 11.13 29 11.73 29.16 12.31C29.49 13.51 29.66 14.75 29.66 16C29.66 23.54 23.53 29.67 15.99 29.67C8.45 29.67 2.33 23.53 2.33 16C2.33 8.46997 8.46 2.32997 16 2.32997C17.56 2.32997 19.1 2.58997 20.56 3.10997C21.46 3.42997 22.33 3.83997 23.15 4.33997C23.66 4.65997 24.33 4.48997 24.64 3.97997C24.95 3.46997 24.79 2.79997 24.28 2.48997C23.34 1.90997 22.33 1.42997 21.28 1.05997C19.59 0.459973 17.81 0.159973 16 0.159973C7.27 0.159973 0.160004 7.25997 0.160004 16C0.160004 24.74 7.26 31.84 16 31.84C24.74 31.84 31.84 24.74 31.84 16C31.84 14.55 31.64 13.11 31.25 11.72Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2703">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrTargetEnrolled;
