import React from 'react';
import { IProps } from './interfaces';

const KrHeart: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2 4.13999C26.97 4.13999 30.04 7.20999 30.04 10.98C30.04 12.85 29.3 14.59 27.92 15.93L16.12 27.87C16.12 27.87 16.09 27.87 16.08 27.87C16.02 27.87 15.99 27.87 15.92 27.88L4.05 15.9C2.7 14.59 1.96 12.85 1.96 10.98C1.96 7.20999 5.03 4.13999 8.8 4.13999C11.02 4.13999 13.11 5.22999 14.39 7.04999C14.76 7.56999 15.36 7.87999 15.99 7.87999C16.62 7.87999 17.23 7.56999 17.59 7.04999C18.87 5.22999 20.96 4.13999 23.18 4.13999M23.2 2.17999C20.23 2.17999 17.59 3.65999 16 5.92999C14.41 3.65999 11.77 2.17999 8.8 2.17999C3.94 2.17999 0 6.11999 0 10.98C0 13.46 1.03 15.71 2.69 17.31L14.53 29.26C14.9 29.63 15.39 29.83 15.89 29.83C15.93 29.83 15.97 29.83 16 29.83C16.04 29.83 16.08 29.83 16.11 29.83C16.6 29.83 17.09 29.63 17.47 29.26L29.31 17.31C30.97 15.71 32 13.47 32 10.98C32 6.11999 28.06 2.17999 23.2 2.17999Z"
        fill={color}
      />
    </svg>
  );
};

export default KrHeart;
