import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

import Header from '~/components/Header';
import api from '~/services/api';

const DefaultLayout: React.FC = ({ children }) => {
  const { signOut } = useAuth();
  const location = useLocation();

  useEffect(() => {
    api.get('check-login-organizer').catch(() => {
      signOut();
    });
  }, [signOut, location.pathname]);

  return (
    <div className="d-flex p-3">
      <Header />
      {children}
    </div>
  );
};

export default DefaultLayout;
