import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AuthLayout: React.FC = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <div className="d-flex vh-100">{children}</div>;
};

export default AuthLayout;
