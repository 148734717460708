import React from 'react';
import { IProps } from './interfaces';

const KrMessage: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1962)">
        <path
          d="M30.32 31.97C30.17 31.97 30.03 31.94 29.89 31.87L23.88 28.8C21.44 30.3 18.63 31.09 15.74 31.09C7.17 31.09 0.199997 24.13 0.199997 15.57C0.199997 7.01 7.17 0.0299988 15.74 0.0299988C24.31 0.0299988 31.28 7 31.28 15.57C31.28 18.7 30.36 21.69 28.61 24.26L31.21 30.67C31.36 31.04 31.26 31.46 30.97 31.73C30.79 31.89 30.56 31.98 30.33 31.98L30.32 31.97ZM15.74 1.93C8.22 1.93 2.1 8.05 2.1 15.57C2.1 23.09 8.22 29.2 15.73 29.2C18.44 29.2 21.05 28.41 23.29 26.92C23.58 26.73 23.94 26.71 24.25 26.86L28.48 29.02L26.65 24.49C26.53 24.19 26.57 23.84 26.76 23.57C28.47 21.24 29.37 18.47 29.37 15.57C29.37 8.05 23.25 1.94 15.73 1.94L15.74 1.93Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1962">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrMessage;
