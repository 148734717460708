/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MdCheck } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { TableList, Container, Filter, Status } from './styles';

import Table, { IColumn } from '~/components/Table';
import FiltersLists from '~/components/FiltersLists';
import ExportCsv from '~/components/ExportCsv';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { IOption } from '~/components/Select';

interface IDataItem {
  id: number;
  organizer: string;
  event: string;
  date: string;
  tickets_qty: number;
  total: string;
  payment: string;
  parcels: number;
  status_payment: string;
  status_event: string;
  to_receive: string;
  active: boolean;
}

interface IItem {
  id: number;
}

interface IOrderData {
  id: number;
  total: string;
  status: string;
  created_at: string;
  items: IItem[];
  event: {
    name: string;
    date: string;
    user: {
      name: string;
    };
    status: {
      name: string;
    };
  };
  payment: {
    payment_type: string;
    installment: number;
  };
}

interface IOrderResponse {
  data: IOrderData[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface IStatus {
  id: number;
  name: string;
}

const Payments: React.FC = () => {
  const [pageSelected, setPageSelected] = useState(1);
  const [data, setData] = useState<IDataItem[]>([]);
  const [tableData, setTableData] = useState({
    from: 0,
    to: 0,
    total: 0,
    totalPage: 0,
  });
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [eventName, setEventName] = useState<string | undefined>(undefined);
  const [statusId, setStatusId] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<IOption[]>([]);

  useEffect(() => {
    api.get<IStatus[]>('events-status').then((response) => {
      const dataStatus = response.data.map<IOption>((statusData) => ({
        id: statusData.id,
        value: statusData.name,
        selected: false,
      }));
      setStatus(dataStatus);
    });
  }, []);

  const handleLoadOrders = useCallback(
    async (pageData, user?: string, event?: string, status_id?: string) => {
      const response = await api.get<IOrderResponse>('orders/organizers', {
        params: {
          page: pageData,
          user,
          event,
          status_id,
        },
      });
      const dataAux = response.data.data.map<IDataItem>((order) => {
        const to_receive =
          parseFloat(order.total) - parseFloat(order.total) * 0.03;

        return {
          id: order.id,
          organizer: order.event.user?.name,
          event: order.event.name,
          date: format(parseISO(order.created_at), 'dd/MM/yyyy'),
          tickets_qty: order.items.length,
          total: formatPrice(parseFloat(order.total)),
          payment:
            order.payment.payment_type === 'credit-card'
              ? 'Cartão de Crédito'
              : 'PIX',
          parcels: order.payment.installment,
          status_payment: order.status,
          status_event: order.event.status.name,
          to_receive: formatPrice(to_receive),
          active: true,
        };
      });

      setData(dataAux);
      setTableData({
        from: response.data.from,
        to: response.data.to,
        total: response.data.total,
        totalPage: response.data.pages,
      });
    },
    []
  );

  useEffect(() => {
    handleLoadOrders(1);
  }, [handleLoadOrders]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
        sortable: false,
      },

      {
        name: 'Evento',
        selector: 'event',
        sortable: true,
      },
      {
        name: 'Data Venda',
        selector: 'date',
        sortable: true,
      },
      {
        name: 'Qtd ingressos',
        selector: 'tickets_qty',
        sortable: true,
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
      },
      {
        name: 'Forma pgto',
        selector: 'payment',
        sortable: true,
      },
      {
        name: 'Parcelas',
        selector: 'parcels',
        sortable: true,
      },
      {
        name: 'Status pagto',
        selector: 'status_payment',
        sortable: true,
      },

      {
        name: 'Status evento',
        selector: 'status_event',
        sortable: true,
        cell: (row) => (
          <Status
            status={row.status_event}
            className="text-white rounded-pill mb-0"
          >
            {row.status_event}
          </Status>
        ),
      },
      {
        name: 'A receber',
        selector: 'to_receive',
        sortable: true,
      },
      {
        name: 'Ativo',
        selector: 'active',
        sortable: true,
        cell: () => (
          <button type="button" className="pe-none btn-icons border-0">
            <MdCheck size={16} color="#fff" />
          </button>
        ),
      },
    ],
    []
  );

  const handleChangePage = useCallback(
    (page) => {
      if (page <= tableData.totalPage) {
        handleLoadOrders(page, userName, eventName, statusId);
        setPageSelected(page);
      }
    },
    [eventName, handleLoadOrders, statusId, tableData.totalPage, userName]
  );

  const handleSubmitFilters = useCallback(
    (formData) => {
      setUserName(formData.name);
      setEventName(formData.event);
      setStatusId(formData.status !== 'todos' ? formData.status : undefined);
      handleLoadOrders(
        1,
        formData.name,
        formData.event,
        formData.status !== 'todos' ? formData.status : undefined
      );
    },
    [handleLoadOrders]
  );

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="zoom col-12 px-0">
            <div className="bg-white d-sm-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Lista de pagamentos</h1>
              <ExportCsv
                data={data}
                columns={columns}
                fileName="Lista_de_pagamentos"
              />
            </div>
          </div>
          <Filter className="col-12 px-0 mt-3">
            <FiltersLists
              onSubmitFilters={handleSubmitFilters}
              event
              name
              statusEvent
              statusOptions={status}
            />
          </Filter>
          <div className="col-12 px-0">
            <div className="row">
              <TableList className="col-12 mt-3">
                <div className="bg-white pb-2">
                  <div className="bg-white overflow-auto">
                    <div className="">
                      <Table
                        className="mb-0 p-0 table"
                        columns={columns}
                        data={data}
                        pagination
                        totalData={tableData.total}
                        selectedPage={pageSelected}
                        onChangePage={handleChangePage}
                      />
                    </div>
                  </div>
                </div>
              </TableList>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Payments;
