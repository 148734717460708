import React from 'react';
import { IPropsSecondary } from './interfaces';

const KrTargetAverageTicket: React.FC<IPropsSecondary> = ({
  size = 32,
  color = '#FF6900',
  secondaryColor = '#fff',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2723)">
        <path
          d="M12.55 10.69L23.04 4.91001C23.84 4.47001 24.8 4.63001 25.17 5.27001L26.21 7.02001C26.5 7.52001 26.36 8.16001 25.89 8.65001C25.02 9.55001 24.76 10.78 25.33 11.73C25.9 12.68 27.13 13.09 28.37 12.83C29.05 12.69 29.7 12.89 29.99 13.39L31.03 15.14C31.41 15.78 31.07 16.66 30.27 17.1L19.78 22.88L19.66 22.95L9.16999 28.73C8.36999 29.17 7.40999 29.01 7.03999 28.37L5.99999 26.62C5.70999 26.12 5.84999 25.48 6.31999 24.99C7.18999 24.09 7.44999 22.86 6.87999 21.91C6.30999 20.96 5.07999 20.55 3.83999 20.81C3.15999 20.95 2.50999 20.75 2.21999 20.25L1.17999 18.5C0.799987 17.86 1.13999 16.98 1.93999 16.54L12.43 10.76"
          fill={secondaryColor}
        />
        <path
          d="M8.23999 29.88C7.41999 29.88 6.65999 29.51 6.25999 28.83L5.21999 27.08C4.72999 26.25 4.90999 25.16 5.66999 24.36C6.26999 23.74 6.44999 22.94 6.10999 22.36C5.75999 21.77 4.91999 21.5 4.02999 21.68C2.94999 21.91 1.93999 21.52 1.44999 20.7L0.409994 18.95C0.129994 18.47 0.0599942 17.9 0.209994 17.34C0.389994 16.68 0.859994 16.1 1.50999 15.74L12 9.96002C12 9.96002 12.04 9.94002 12.06 9.93002C12.08 9.92002 12.1 9.91002 12.12 9.89002L22.61 4.11002C23.83 3.44002 25.33 3.75002 25.95 4.80002L26.99 6.55002C27.48 7.38002 27.3 8.47002 26.54 9.26002C25.94 9.88002 25.76 10.69 26.1 11.26C26.45 11.85 27.29 12.12 28.18 11.93C29.26 11.7 30.27 12.09 30.76 12.91L31.8 14.67C32.08 15.15 32.15 15.72 32 16.28C31.82 16.94 31.35 17.52 30.7 17.88L9.59999 29.51C9.16 29.75 8.68999 29.86 8.23999 29.86V29.88ZM4.56999 19.83C5.86999 19.83 7.04999 20.42 7.64999 21.45C8.40999 22.73 8.12999 24.41 6.95999 25.62C6.76999 25.81 6.68999 26.05 6.75999 26.17L7.79999 27.92C7.89999 28.09 8.30999 28.18 8.71999 27.95L29.83 16.32C30.05 16.2 30.21 16.01 30.26 15.82C30.28 15.73 30.28 15.66 30.25 15.6L29.21 13.84C29.14 13.72 28.88 13.63 28.55 13.7C26.91 14.05 25.3 13.44 24.55 12.18C23.79 10.9 24.07 9.22002 25.24 8.01002C25.43 7.82002 25.51 7.59002 25.44 7.46002L24.4 5.70002C24.3 5.53002 23.89 5.44002 23.48 5.67002L12.99 11.45C12.99 11.45 12.95 11.47 12.93 11.48C12.91 11.49 12.89 11.5 12.87 11.52L2.37999 17.3C2.15999 17.42 1.99999 17.61 1.94999 17.8C1.92999 17.89 1.92999 17.96 1.95999 18.02L2.99999 19.77C3.06999 19.89 3.31999 19.98 3.65999 19.91C3.96999 19.84 4.27999 19.81 4.57999 19.81L4.56999 19.83Z"
          fill={color}
        />
        <path
          d="M19.2 9.66003C18.99 9.66003 18.79 9.56003 18.68 9.37003L18.1 8.39003C17.93 8.10003 18.03 7.74003 18.31 7.57003C18.6 7.40003 18.96 7.50003 19.13 7.78003L19.71 8.76003C19.88 9.05003 19.78 9.41003 19.5 9.58003C19.4 9.64003 19.3 9.66003 19.2 9.66003Z"
          fill={color}
        />
        <path
          d="M23.69 17.24C23.48 17.24 23.28 17.14 23.17 16.95L22.05 15.05C21.88 14.76 21.98 14.4 22.26 14.23C22.55 14.06 22.91 14.16 23.08 14.44L24.2 16.34C24.37 16.63 24.27 16.99 23.99 17.16C23.89 17.22 23.79 17.24 23.69 17.24ZM21.44 13.45C21.23 13.45 21.03 13.35 20.92 13.16L19.8 11.26C19.63 10.97 19.73 10.61 20.01 10.44C20.3 10.27 20.66 10.37 20.83 10.65L21.95 12.55C22.12 12.84 22.02 13.2 21.74 13.37C21.64 13.43 21.54 13.45 21.44 13.45Z"
          fill={color}
        />
        <path
          d="M25.38 20.11C25.17 20.11 24.97 20.01 24.86 19.82L24.28 18.84C24.11 18.55 24.21 18.19 24.49 18.02C24.77 17.85 25.14 17.95 25.31 18.23L25.89 19.21C26.06 19.5 25.96 19.86 25.68 20.03C25.58 20.09 25.48 20.11 25.38 20.11Z"
          fill={color}
        />
        <path
          d="M14.87 16.94L26.95 16.82C27.87 16.82 28.62 17.41 28.63 18.14L28.65 20.16C28.65 20.73 28.21 21.23 27.54 21.43C26.33 21.81 25.48 22.76 25.49 23.86C25.5 24.96 26.37 25.89 27.59 26.25C28.26 26.44 28.72 26.93 28.72 27.5L28.74 29.52C28.74 30.26 28 30.86 27.09 30.87L15.01 30.99H14.87L2.79 31.11C1.87 31.11 1.12 30.52 1.11 29.79L1.09 27.77C1.09 27.2 1.53 26.7 2.2 26.5C3.41 26.12 4.26 25.17 4.25 24.07C4.24 22.97 3.37 22.04 2.15 21.68C1.48 21.49 1.02 21 1.02 20.43L1 18.41C1 17.67 1.74 17.07 2.65 17.06L14.73 16.94"
          fill={secondaryColor}
        />
        <path
          d="M2.77001 32.01C2.13001 32.01 1.52001 31.81 1.05001 31.44C0.520006 31.02 0.210006 30.43 0.210006 29.8L0.190006 27.78C0.190006 26.81 0.860006 25.97 1.93001 25.64C2.78001 25.38 3.35001 24.75 3.35001 24.08C3.35001 23.41 2.76001 22.8 1.90001 22.55C0.830006 22.24 0.130006 21.41 0.120006 20.44L0.100006 18.42C0.100006 17.88 0.310006 17.35 0.720006 16.94C1.20001 16.45 1.91001 16.16 2.65001 16.15L14.73 16.03C14.73 16.03 14.77 16.03 14.8 16.03C14.82 16.03 14.85 16.03 14.87 16.03L26.95 15.91C28.38 15.89 29.53 16.89 29.54 18.12L29.56 20.14C29.56 21.11 28.89 21.95 27.82 22.28C26.97 22.55 26.4 23.17 26.4 23.84C26.4 24.51 26.99 25.12 27.85 25.37C28.92 25.68 29.62 26.51 29.63 27.48L29.65 29.5C29.65 30.04 29.44 30.57 29.03 30.98C28.55 31.47 27.85 31.76 27.1 31.77L2.80001 32H2.77001V32.01ZM14.81 17.84C14.81 17.84 14.76 17.84 14.74 17.84L2.66001 17.96C2.39001 17.96 2.15001 18.05 2.00001 18.21C1.95001 18.26 1.90001 18.33 1.90001 18.41L1.92001 20.43C1.92001 20.55 2.09001 20.74 2.40001 20.83C4.03001 21.3 5.13001 22.61 5.15001 24.07C5.17001 25.54 4.09001 26.86 2.47001 27.36C2.16001 27.46 1.99001 27.64 2.00001 27.77L2.02001 29.79C2.02001 29.9 2.13001 30 2.17001 30.03C2.32001 30.15 2.54001 30.22 2.78001 30.22H2.79001L27.09 29.99C27.36 29.99 27.6 29.9 27.75 29.74C27.8 29.69 27.85 29.62 27.85 29.54L27.83 27.52C27.83 27.4 27.66 27.22 27.35 27.12C25.72 26.65 24.62 25.35 24.6 23.88C24.58 22.41 25.66 21.09 27.28 20.59C27.59 20.49 27.76 20.31 27.75 20.18L27.73 18.16C27.73 17.99 27.42 17.72 26.96 17.73L14.88 17.85C14.88 17.85 14.84 17.85 14.81 17.85V17.84Z"
          fill={color}
        />
        <path
          d="M7.03999 19.11C6.70999 19.11 6.43999 18.85 6.43999 18.52V17.39C6.41999 17.06 6.68999 16.79 7.01999 16.78C7.34999 16.78 7.61999 17.04 7.61999 17.37V18.5C7.63999 18.83 7.36999 19.1 7.03999 19.11Z"
          fill={color}
        />
        <path
          d="M7.11999 27.83C6.78999 27.83 6.51999 27.57 6.51999 27.24L6.49999 25.06C6.49999 24.73 6.75999 24.46 7.08999 24.45C7.41999 24.45 7.68999 24.71 7.68999 25.04L7.70999 27.22C7.70999 27.55 7.44999 27.82 7.11999 27.83ZM7.07999 23.47C6.74999 23.47 6.47999 23.21 6.47999 22.88L6.45999 20.7C6.45999 20.37 6.71999 20.1 7.04999 20.09C7.37999 20.09 7.64999 20.35 7.64999 20.68L7.66999 22.86C7.66999 23.19 7.40999 23.46 7.07999 23.47Z"
          fill={color}
        />
        <path
          d="M7.15999 31.1401C6.82999 31.1401 6.55999 30.8801 6.55999 30.5501V29.4201C6.53999 29.0901 6.80999 28.8201 7.13999 28.8101C7.42999 28.8101 7.73999 29.0701 7.74999 29.4001V30.5301C7.76999 30.8601 7.49999 31.1301 7.16999 31.1401H7.15999Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2723">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrTargetAverageTicket;
