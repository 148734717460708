/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FocusEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { IoMdRemove } from 'react-icons/io';

import Select, { IOption } from '~/components/Select';
import { IModalityData } from '~/pages/Events/NewEvent/Modalities';

interface IModality {
  modalities: IOption[];
  selectedModality: IModalityData;
  onSelectModality(value: IOption): void;
  onChangeModality(value: string): void;
  onFocus?: FocusEventHandler<any> | undefined;
  onBlur?: FocusEventHandler<any> | undefined;
  onClickAddNew(): void;
  onRemoveModality(): void;
}

const Modality: React.FC<IModality> = ({
  modalities: modalitiesData,
  selectedModality,
  onSelectModality,
  onChangeModality,
  onFocus,
  onBlur,
  onClickAddNew,
  onRemoveModality,
}) => {
  const [modalities, setModalities] = useState<IOption[]>([]);
  const [isWritten, setIsWritten] = useState(false);
  const [valueSelected, setValueSelected] = useState('');

  useEffect(() => {
    setModalities([
      ...modalitiesData.map((modality) => {
        return {
          ...modality,
          selected: !isWritten && selectedModality.id === modality.id,
        };
      }),
    ]);
  }, [isWritten, modalitiesData, selectedModality.id]);

  const handleChangeModality = useCallback(
    (value) => {
      setValueSelected(value);
      setIsWritten(true);
      onChangeModality(value);
    },
    [onChangeModality]
  );

  const handleSelectModality = useCallback(
    (option) => {
      onSelectModality(option);
      setValueSelected(option.value);
      setIsWritten(false);
    },
    [onSelectModality]
  );

  const handleClickRemoveModality = useCallback(() => {
    setValueSelected('');
    setIsWritten(false);
    onRemoveModality();
  }, [onRemoveModality]);

  return (
    <div className="bd-modality d-flex justify-content-between align-items-center p-2 mb-2">
      <Select
        name={`add-modality-${selectedModality.input_id}`}
        placeholder="Selecionar modalidade"
        className="border-0 p-0"
        options={modalities}
        onChange={handleSelectModality}
        onChangeText={handleChangeModality}
        onFocus={onFocus}
        onBlur={onBlur}
        addNew={modalities.length === 0 && !!selectedModality.name}
        textBtnAddNew={`Adicionar e usar a modalidade ${selectedModality.name}`}
        onClickAddNew={onClickAddNew}
        valueSelected={valueSelected}
      />
      <button
        type="button"
        className="bg-transparent border-0 ms-2"
        onClick={handleClickRemoveModality}
      >
        <IoMdRemove color="#000" size={16} />
      </button>
    </div>
  );
};

export default Modality;
