import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { Link } from 'react-router-dom';
import getValidationErros from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';

import { Container } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logos/logo-name.svg';

interface IFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [signIn]
  );

  return (
    <Container>
      <div className="container h-100">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="ret-up ret-left d-none d-lg-block" />
            <div className="ret-up ret-right d-none d-lg-block" />
            <img src={logo} alt="Logo Koro" className="logo" />
          </div>

          <div className="col-lg-4 px-3">
            <h2 className="h6 text-white fw-normal mb-3">
              Faça login para continuar
            </h2>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="d-flex flex-column pb-3"
            >
              <label className="mb-3">
                <span className="text-white fw-normal d-block mb-2 size">
                  E-mail
                </span>
                <Input type="email" name="email" />
              </label>
              <label className="mb-3">
                <span className="text-white fw-normal d-block mb-2 size">
                  Senha
                </span>
                <Input type="password" name="password" />
              </label>
              {/* <p className="text-end small text-white">
                <Link
                  to={`${process.env.PUBLIC_URL}/recuperar-senha`}
                  className="opacity-100 text-white"
                >
                  Esqueci a senha
                </Link>
              </p> */}
              <button
                type="submit"
                className="btn btn-login h6 fw-normal py-2 mt-4"
              >
                Entrar
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Login;
