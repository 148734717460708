import React from 'react';
import { IPropsSecondary } from './interfaces';

const KrDoubt: React.FC<IPropsSecondary> = ({
  size = 32,
  color = '#FF6900',
  secondaryColor = '#fff',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1968)">
        <path d="M16.85 21.86H14.86V24.43H16.85V21.86Z" fill={color} />
        <path
          d="M20.5 12.44C20.5 13.1 20.34 13.73 20.04 14.32C19.72 14.92 19.02 15.78 17.94 16.86C17.33 17.48 16.96 17.97 16.81 18.37C16.67 18.72 16.6 19.26 16.6 20.03H14.86V19.63C14.86 18.68 14.98 17.9 15.22 17.33C15.47 16.75 15.96 16.1 16.68 15.39C17.58 14.5 18.08 13.93 18.27 13.58C18.47 13.22 18.57 12.8 18.57 12.33C18.57 11.56 18.32 10.91 17.81 10.41C17.31 9.91 16.66 9.66 15.9 9.66C14.36 9.66 13.38 10.61 12.97 12.48L11.15 12.2C11.27 10.97 11.73 9.97 12.54 9.16C13.42 8.29 14.49 7.86 15.82 7.86C17.15 7.86 18.27 8.29 19.17 9.17C20.06 10.05 20.5 11.12 20.5 12.44Z"
          fill={color}
        />
        <path
          className="question-mark"
          d="M14.86 21.86V24.43H16.85V21.86H14.86ZM19.17 9.17C18.27 8.29 17.18 7.86 15.82 7.86C14.46 7.86 13.42 8.29 12.54 9.16C11.73 9.97 11.27 10.97 11.15 12.2L12.97 12.48C13.38 10.61 14.36 9.66 15.9 9.66C16.66 9.66 17.31 9.91 17.81 10.41C18.32 10.91 18.57 11.56 18.57 12.33C18.57 12.8 18.47 13.22 18.27 13.58C18.08 13.93 17.58 14.5 16.68 15.39C15.96 16.1 15.47 16.75 15.22 17.33C14.98 17.9 14.85 18.68 14.85 19.63V20.03H16.6C16.6 19.26 16.67 18.72 16.81 18.37C16.96 17.97 17.33 17.48 17.94 16.86C19.02 15.78 19.72 14.92 20.04 14.32C20.34 13.73 20.5 13.1 20.5 12.44C20.5 11.12 20.06 10.05 19.17 9.17Z"
          fill={secondaryColor}
        />
        <path
          d="M16 0.210007C7.29 0.210007 0.200001 7.29001 0.200001 16C0.200001 24.71 7.29 31.79 16 31.79C24.71 31.79 31.79 24.71 31.79 16C31.79 7.29001 24.71 0.210007 16 0.210007ZM14.86 24.43V21.86H16.85V24.43H14.86ZM20.04 14.32C19.72 14.92 19.02 15.78 17.94 16.86C17.33 17.48 16.96 17.97 16.81 18.37C16.67 18.72 16.6 19.26 16.6 20.03H14.86V19.63C14.86 18.68 14.98 17.9 15.22 17.33C15.47 16.75 15.96 16.1 16.68 15.39C17.58 14.5 18.08 13.93 18.27 13.58C18.47 13.22 18.57 12.8 18.57 12.33C18.57 11.56 18.32 10.91 17.81 10.41C17.31 9.91001 16.66 9.66001 15.9 9.66001C14.36 9.66001 13.38 10.61 12.97 12.48L11.15 12.2C11.27 10.97 11.73 9.97001 12.54 9.16001C13.42 8.29001 14.49 7.86001 15.82 7.86001C17.15 7.86001 18.27 8.29001 19.17 9.17001C20.06 10.05 20.5 11.12 20.5 12.44C20.5 13.1 20.34 13.73 20.04 14.32Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1968">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrDoubt;
