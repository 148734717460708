import React from 'react';
import { IProps } from './interfaces';

const KrDashboard: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2675)">
        <path
          d="M14.37 10.82H9.48C8.98 10.82 8.58 11.22 8.58 11.72V31.11C8.58 31.61 8.98 32.01 9.48 32.01H14.37C14.87 32.01 15.27 31.61 15.27 31.11V11.72C15.27 11.22 14.87 10.82 14.37 10.82ZM13.47 30.21H10.38V12.62H13.47V30.21Z"
          fill={color}
        />
        <path
          d="M6 18.01L0.959999 17.96C0.709999 17.96 0.489999 18.05 0.319999 18.22C0.149999 18.39 0.0499992 18.62 0.0499992 18.86V31.06C0.0499992 31.55 0.449999 31.96 0.939999 31.96L5.98 32.01C6.22 32.01 6.44 31.92 6.61 31.75C6.78 31.58 6.88 31.35 6.88 31.11V18.91C6.88 18.42 6.48 18.01 5.99 18.01H6ZM5.09 30.2L1.85 30.17V19.77L5.09 19.8V30.2Z"
          fill={color}
        />
        <path
          d="M22.75 18.01H17.86C17.36 18.01 16.96 18.41 16.96 18.91V31.11C16.96 31.61 17.36 32.01 17.86 32.01H22.75C23.25 32.01 23.65 31.61 23.65 31.11V18.91C23.65 18.41 23.25 18.01 22.75 18.01ZM21.85 30.21H18.76V19.81H21.85V30.21Z"
          fill={color}
        />
        <path
          d="M31.14 13.66H26.25C25.75 13.66 25.35 14.06 25.35 14.56V31.11C25.35 31.61 25.75 32.01 26.25 32.01H31.14C31.64 32.01 32.04 31.61 32.04 31.11V14.56C32.04 14.06 31.64 13.66 31.14 13.66ZM30.24 30.21H27.15V15.46H30.24V30.21Z"
          fill={color}
        />
        <path
          d="M3.33 12.43L11.63 4.66L20.16 14.36C20.28 14.49 20.44 14.56 20.61 14.56C20.73 14.56 20.85 14.52 20.96 14.45L29.03 8.71C29.3 8.52 29.36 8.14 29.17 7.87C28.98 7.6 28.6 7.54 28.33 7.73L20.7 13.16L12.13 3.41C12.02 3.29 11.87 3.22 11.71 3.21C11.54 3.2 11.39 3.26 11.27 3.37L2.52 11.56C2.28 11.79 2.27 12.17 2.49 12.41C2.72 12.65 3.1 12.66 3.34 12.44L3.33 12.43Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2675">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrDashboard;
