import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface IFilterBox {
  show?: boolean;
}
interface IButton {
  sorting?: boolean;
  order?: boolean;
}

interface INoData {
  width: number;
  scrollLeft: number;
}

interface ILoading {
  active?: boolean;
  fixed?: boolean;
}

export const Container = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 25px;

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    color: #31344e;
  }

  button.add-button {
    background: #2e90fd;
    color: #ffffff;
    border-radius: 10px;
    font-weight: 700;
    border: none;
  }

  button.btn-action {
    background: #f0f1f9;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    border: none;
  }
`;

export const FilterGroup = styled.div`
  position: relative;
`;

export const FilterBox = styled.div<IFilterBox>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  position: absolute;
  right: -10px;
  top: 50px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: #fff;
  border: 0.5px solid #e2e2e2;
  z-index: 100;

  :after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    top: -8px;
    right: 18px;
    background-color: #fff;
    border-top: 0.5px solid #e2e2e2;
    border-left: 0.5px solid #e2e2e2;
    transform: rotateZ(45deg);
  }
`;

export const Input = styled.div`
  background: #f9f9f9;
  border-radius: 19px;
  width: 100%;
  padding: 5px 15px;

  input {
    background: none;
    border: none;
    color: #acacac;
    width: calc(100% - 24px);

    ::placeholder {
      color: #acacac;
    }
  }

  @media screen and (min-width: 992px) {
    width: 320px;
  }
`;

export const TableContent = styled.div`
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  border: 0.5px solid #e2e2e2;

  .content {
    //padding-bottom: 17px;
    position: relative;
    z-index: 1;

    ::-webkit-scrollbar {
      background: transparent;
      border: 1px solid #efefef;
      width: 5px;
      height: 7px;
      border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #d2d2d2;
      border-radius: 4px;
    }
  }

  /* :before {
    content: '';
    display: block;
    width: 100%;
    height: 50px;
    background-color: transparent;
    border-radius: 10px 10px 0 0;
    border-bottom: 0.5px solid #e2e2e2;
  }

  :after {
    content: '';
    display: block;
    width: 100%;
    height: 27px;
    background-color: transparent;
    border-radius: 0 0 10px 10px;
    border-top: 0.5px solid #e2e2e2;
  } */

  @media screen and (min-width: 992px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

    .content {
      overflow: auto;
    }

    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }

    :after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
`;

export const Header = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    width: max-content;

    > div {
      width: max-content;
    }
  }
`;

export const Button = styled.button<IButton>`
  position: relative;
  text-align: left;
  padding: 0px 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background-color: transparent;
  color: #414141;
  width: 170px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    transition-duration: 0.3s;
    /* opacity: 0;
    visibility: hidden; */
    transform: rotate(${(props) => (props.order ? '180deg' : '0deg')});
  }

  .tooltip-total:after {
    background-color: #002f6c;
    color: #fff;
    border: 1px solid #002f6c;
    border-radius: 15px;
    box-sizing: border-box;
    content: attr(data-tooltip);
    display: none;
    padding: 15px;
    font-size: 11px;
    position: absolute;
    bottom: 20px;
    left: -50px;
    width: 250px;
    font-weight: 400;
    line-height: 16px;
    z-index: 10000;
  }

  .tooltip-total:hover:after {
    display: block;
  }

  /* :hover {
    ${(props) =>
    props.sorting
      ? css`
          svg {
            opacity: 1;
            visibility: visible;
          }
        `
      : ''};
  } */
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #414141;
`;

export const Body = styled.div`
  background-color: #fff;

  .tb-row-hover {
    cursor: pointer;
    transition-duration: 0.3s;

    :hover {
      background-color: ${darken(0.05, '#fff')};
    }
  }

  @media screen and (min-width: 992px) {
    width: max-content;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > div {
    padding: 8px 0px;
    font-size: 16px;
    line-height: 20px;
    color: #848484;
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    width: max-content;

    > div {
      width: 200px;
      padding: 8px 15px;
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;

  > div {
    min-width: 270px;
    max-width: 400px;
    width: 100%;

    small {
      color: #6b6b6b;
    }

    select {
      border: none;
      border-bottom: 1px solid #7c7c7c;
      color: #ababab;
      font-size: 90%;
    }

    .btn-pagination {
      color: #a0a0a0;
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 600;
      padding: 0 7px;
    }
    .selected {
      background-color: #002f6c;
      color: #fff;
      border-radius: 4px;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 0 10px;
    > div .btn-pagination {
      font-size: 20px;
      margin: 0 10px;
    }

    > div {
      min-width: 320px;
      max-width: 400px;
      width: 100%;
    }
  }
`;

export const NoData = styled.div<INoData>`
  display: flex;
  justify-content: center;
  width: ${(props) => props.width}px;
  position: relative;
  height: 150px;

  p {
    width: 100%;
    position: absolute;
    left: ${(props) => props.scrollLeft}px;
    top: 50%;
    transform: translateY(-50%);
    color: #31344e;
  }
`;

export const Loading = styled.div<ILoading>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  span {
    color: #fff !important;
  }
`;
