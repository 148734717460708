import React from 'react';
import { IProps } from './interfaces';

const KrUserCircle: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1955)">
        <path
          d="M19.55 15.07C20.32 14.22 20.79 13.1 20.79 11.87C20.79 9.22999 18.65 7.07999 16 7.07999C13.35 7.07999 11.21 9.21999 11.21 11.87C11.21 13.1 11.68 14.22 12.45 15.07C9.59001 16.31 7.57001 18.92 7.14001 22.04C7.08001 22.5 7.22001 22.97 7.52001 23.32C7.82001 23.66 8.25001 23.86 8.70001 23.86H23.29C23.74 23.86 24.17 23.66 24.47 23.32C24.78 22.97 24.91 22.5 24.85 22.04C24.42 18.92 22.4 16.31 19.54 15.07H19.55ZM16 8.27999C17.98 8.27999 19.59 9.88999 19.59 11.87C19.59 13.01 19.04 14.1 18.12 14.77C17.5 15.22 16.77 15.46 16.01 15.46C15.25 15.46 14.51 15.22 13.9 14.77C12.98 14.1 12.42 13.01 12.42 11.87C12.42 9.88999 14.03 8.27999 16.01 8.27999H16ZM23.58 22.53C23.53 22.59 23.44 22.66 23.3 22.66H8.71001C8.57001 22.66 8.48001 22.59 8.43001 22.53C8.35001 22.44 8.32001 22.32 8.33001 22.2C8.72001 19.34 10.66 16.95 13.38 15.97C13.41 15.96 13.44 15.93 13.47 15.92C14.21 16.38 15.07 16.66 16 16.66C16.93 16.66 17.79 16.38 18.53 15.92C18.56 15.94 18.58 15.96 18.62 15.97C21.35 16.95 23.28 19.34 23.67 22.2C23.69 22.32 23.65 22.44 23.57 22.53H23.58Z"
          fill={color}
        />
        <path
          d="M16 0C7.16 0 0 7.16 0 16C0 24.84 7.16 32 16 32C24.84 32 32 24.84 32 16C32 7.16 24.84 0 16 0ZM16 30.2C8.17 30.2 1.8 23.83 1.8 16C1.8 8.17 8.17 1.8 16 1.8C23.83 1.8 30.2 8.17 30.2 16C30.2 23.83 23.83 30.2 16 30.2Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1955">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrUserCircle;
