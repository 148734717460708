import React from 'react';
import { IProps } from './interfaces';

const KrBasketBall: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1905)">
        <path
          d="M16 -0.0100021C7.17 -0.0100021 0 7.17 0 16C0 24.83 7.18 32.01 16.01 32.01C24.84 32.01 32.02 24.83 32.02 16C32.02 7.17 24.83 -0.0100021 16 -0.0100021ZM26.76 25.24C24.6 22.84 23.29 19.8 23.07 16.59H30.17C30.03 19.89 28.77 22.89 26.76 25.24ZM4.92 7.12C7.07 9.41 8.41 12.31 8.76 15.39H1.83C1.96 12.27 3.09 9.4 4.93 7.12H4.92ZM15.4 30.17C11.98 30.03 8.87 28.67 6.49 26.52C8.77 23.72 10.04 20.23 10.04 16.6H15.4V30.18V30.17ZM16.6 16.6H21.87C22.11 20.16 23.55 23.5 25.94 26.13C23.51 28.52 20.23 30.02 16.6 30.18V16.6ZM16.6 1.82C20.13 1.97 23.32 3.4 25.73 5.67C23.37 8.38 22.02 11.8 21.88 15.39H16.6V1.82ZM30.17 15.4H23.08C23.21 12.14 24.44 9.02 26.57 6.54C28.7 8.91 30.03 12 30.17 15.4ZM15.4 1.82V15.4H9.96C9.6 11.96 8.11 8.74 5.72 6.22C8.18 3.63 11.6 1.98 15.4 1.82ZM1.83 16.6H8.84C8.84 19.92 7.7 23.11 5.63 25.68C3.39 23.28 1.97 20.1 1.82 16.6H1.83Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1905">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrBasketBall;
