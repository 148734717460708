import React from 'react';
import { IProps } from './interfaces';

const KrArrowRight: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1862)">
        <path
          d="M23.18 32C22.95 32 22.72 31.91 22.54 31.74L8.7 17.91C7.65 16.86 7.65 15.15 8.7 14.1L22.54 0.260001C22.89 -0.0899986 23.46 -0.0899986 23.81 0.260001C24.16 0.610001 24.16 1.18 23.81 1.53L9.97 15.36C9.62 15.71 9.62 16.28 9.97 16.63L23.81 30.46C24.16 30.81 24.16 31.38 23.81 31.73C23.63 31.91 23.4 31.99 23.17 31.99L23.18 32Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1862">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrArrowRight;
