import React from 'react';
import { IProps } from './interfaces';

const KrWakeboard: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.39 6.05002C30.34 5.28002 29.35 4.02002 27.52 2.41002C25.7 0.800019 24.29 1.91927e-05 23.58 0.0200192L23.45 0.0400192C23.45 0.0400192 23.37 0.0600192 23.33 0.0800192L23.13 0.160019C22.68 0.410019 15.58 4.46002 9.84001 10.98C4.04001 17.56 0.910007 25.21 0.790007 25.52L0.750007 25.64C0.720007 25.73 0.710007 25.82 0.720007 25.82V25.89L0.710007 25.95C0.760007 26.72 1.75001 27.98 3.58001 29.59C5.35001 31.15 6.69001 31.97 7.51001 31.97C7.54001 31.97 7.57001 31.97 7.59001 31.97C7.68001 31.97 7.76001 31.94 7.84001 31.9L7.94001 31.86C8.01001 31.82 15.39 27.69 21.25 21.03C27.08 14.41 30.19 6.79002 30.3 6.50002L30.34 6.36002C30.34 6.36002 30.35 6.29002 30.36 6.25002L30.38 6.12002V6.04002L30.39 6.05002ZM4.86001 28.14C3.47001 26.91 2.93001 26.21 2.73001 25.89C3.44001 24.24 6.36001 17.86 11.3 12.25C16.24 6.64002 22.21 2.94002 23.75 2.03002C24.09 2.19002 24.86 2.63002 26.25 3.86002C27.64 5.08002 28.18 5.79002 28.38 6.11002C27.67 7.76002 24.75 14.15 19.81 19.75C14.87 25.35 8.91001 29.06 7.36001 29.97C7.02001 29.81 6.25001 29.37 4.86001 28.14Z"
        fill={color}
      />
      <path
        d="M11.38 18.23C11.58 18 11.56 17.65 11.33 17.45C11.1 17.25 10.75 17.27 10.55 17.5L10.51 17.54C9.38999 16.9 7.93999 17.11 7.05999 18.11C6.17999 19.11 6.14999 20.58 6.92999 21.61L6.88999 21.65C6.68999 21.88 6.70999 22.23 6.93999 22.43C7.16999 22.63 7.51999 22.61 7.71999 22.38L10.63 24.94C11.77 25.95 13.53 25.84 14.53 24.69C15.54 23.55 15.43 21.79 14.28 20.79L11.37 18.23H11.38ZM7.68999 20.74C7.35999 20.14 7.40999 19.38 7.88999 18.84C8.36999 18.3 9.11999 18.15 9.74999 18.4L7.68999 20.74ZM10.38 21.03C9.96999 21.5 9.74999 22.07 9.70999 22.65L8.44999 21.54L10.64 19.05L11.9 20.16C11.33 20.28 10.79 20.56 10.38 21.03ZM13.7 23.96C13.1 24.65 12.04 24.71 11.36 24.11C10.68 23.51 10.61 22.45 11.21 21.77C11.81 21.09 12.87 21.02 13.55 21.62C14.24 22.22 14.3 23.28 13.7 23.96Z"
        fill={color}
      />
      <path
        d="M24.05 13.89C25.06 12.75 24.95 10.99 23.8 9.98998L20.89 7.42998C21.09 7.19998 21.07 6.84998 20.84 6.64998C20.61 6.44998 20.26 6.46998 20.06 6.69998L20.02 6.73998C18.9 6.09998 17.45 6.30998 16.57 7.30998C15.69 8.30998 15.66 9.77998 16.44 10.81L16.4 10.85C16.2 11.08 16.22 11.43 16.45 11.63C16.68 11.83 17.03 11.81 17.23 11.58L20.14 14.14C21.28 15.15 23.04 15.04 24.04 13.89H24.05ZM23.07 10.81C23.76 11.41 23.82 12.47 23.22 13.15C22.62 13.83 21.56 13.9 20.88 13.3C20.2 12.7 20.13 11.64 20.73 10.96C21.33 10.27 22.39 10.21 23.07 10.81ZM17.21 9.93998C16.88 9.33998 16.93 8.57998 17.41 8.03998C17.89 7.49998 18.64 7.34998 19.27 7.59998L17.21 9.93998ZM19.23 11.85L17.97 10.74L20.16 8.24998L21.42 9.35998C20.85 9.47998 20.31 9.75998 19.9 10.23C19.49 10.7 19.27 11.27 19.23 11.85Z"
        fill={color}
      />
    </svg>
  );
};

export default KrWakeboard;
