import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Home';
import Dashboard from '~/pages/Dashboard';
import Events from '~/pages/Events';
import NewEvent from '~/pages/Events/NewEvent';
import UpdateEvent from '~/pages/Events/UpdateEvent';
import Clients from '~/pages/Clients';
import Vouchers from '~/pages/Vouchers';
import Financial from '~/pages/Financial';
import Help from '~/pages/Help';
import Configurations from '~/pages/Configurations';
import Login from '~/pages/Login';
import UserList from '~/pages/Listings/Users';
import EventsList from '~/pages/Listings/Events';
import PaymentsList from '~/pages/Listings/Payments';
import TicketList from '~/pages/Listings/Tickets';
import ForgotPassword from '~/pages/ForgotPassword';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Login} />
      <Route
        path={`${process.env.PUBLIC_URL}/recuperar-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/inicio`}
        exact
        component={Home}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        exact
        component={Dashboard}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/eventos`}
        exact
        component={Events}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/eventos/novo-evento`}
        exact
        component={NewEvent}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/eventos/editar-evento/:slug`}
        exact
        component={UpdateEvent}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/eventos/:slug/resumo`}
        exact
        component={UpdateEvent}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/clientes`}
        exact
        component={Clients}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cupons`}
        exact
        component={Vouchers}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/financeiro`}
        exact
        component={Financial}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/ajuda`}
        exact
        isPrivate
        component={Help}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/listagens/usuarios`}
        exact
        component={UserList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/listagens/eventos`}
        exact
        component={EventsList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/listagens/pagamentos`}
        exact
        component={PaymentsList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/listagens/ingressos`}
        exact
        component={TicketList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/configuracao`}
        exact
        component={Configurations}
        isPrivate
      />
    </Switch>
  );
};

export default routes;

//
