import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { MdArrowForwardIos } from 'react-icons/md';

import { IoIosArrowDown } from 'react-icons/io';

import { KrSignout } from '../KoroIcons';

import { Container, Menu } from './styles';
import Icons from '../Icons';

import logo from '~/assets/logos/logo-menu.svg';
import logoText from '~/assets/logos/logo-menu-text.svg';
import { ISetting, useAuth } from '~/hooks/Auth';

const Header: React.FC = () => {
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [active, setActive] = useState(false);
  const [settings, setSettings] = useState<ISetting[]>([]);

  const listPages = useMemo(() => [/\/lista.*/], []);

  useEffect(() => {
    setActive(false);
  }, [location]);

  useEffect(() => {
    const newSettings = user.profile.settings.reduce((previous, current) => {
      const { module } = current;
      if (module.module_id === null) {
        previous.push({ ...current, module: { ...module, subModules: [] } });
      } else {
        const parentModule = previous.find(
          (item) => item.module.id === module.module_id
        );
        if (parentModule) {
          parentModule.module.subModules.push(module);
        }
      }
      return previous;
    }, [] as ISetting[]);

    setSettings(newSettings);
  }, [user.profile.settings]);

  useEffect(() => {
    setSettings((oldState) => {
      return oldState.map((setting) => ({
        ...setting,
        module: {
          ...setting.module,
          active: false,
        },
      }));
    });
    setActive(false);
  }, [location.pathname]);

  const hanldeClickArrow = useCallback(() => {
    if (active) {
      const newSettings = settings.map((setting) => ({
        ...setting,
        module: {
          ...setting.module,
          active: false,
        },
      }));
      setSettings(newSettings);
    }
    setActive(!active);
  }, [active, settings]);

  const handleActiveList = useCallback(
    (settingIndex) => {
      const newSettings = settings.slice();
      newSettings[settingIndex].module.active =
        !newSettings[settingIndex].module.active;
      if (!active && newSettings[settingIndex].module.active) {
        setActive(true);
      }
      setSettings(newSettings);
    },
    [active, settings]
  );

  return (
    <>
      <Container className="position-relative ">
        <Menu
          active={active}
          className="position-fixed d-flex flex-column justify-content-between px-4"
        >
          <button
            type="button"
            className="not-path zoom border-0 btn-arrow position-absolute"
            onClick={hanldeClickArrow}
          >
            <MdArrowForwardIos size={18} color="#002F6C" />
          </button>
          <div className="zoom d-flex flex-column ">
            <div className="d-flex align-items-center mt-4">
              <img src={logo} alt="logo" />
              <img
                src={logoText}
                alt="logo"
                className={`${active ? 'd-flex' : 'd-none'} ms-2`}
              />
            </div>
            {settings.map((setting, index) => (
              <Fragment key={setting.module.id}>
                {setting.module.type === 'Button' && (
                  <>
                    <button
                      type="button"
                      onClick={() => handleActiveList(index)}
                      className={`${
                        active ||
                        listPages.every((item) => item.test(location.pathname))
                          ? 'active'
                          : ''
                      } mt-4 pt-xxl-2 border-0 bg-transparent d-flex justify-content-start`}
                    >
                      <Icons title={setting.module.icon} />
                      <span>
                        {active && 'Listagens'}{' '}
                        {active && (
                          <IoIosArrowDown
                            size={14}
                            color="#BFCBDA"
                            className={`ms-4 arrow ${
                              setting.module.active
                                ? 'arrow-down'
                                : 'arrow-left'
                            }`}
                          />
                        )}
                      </span>
                    </button>
                    {setting.module.subModules.length > 0 &&
                      setting.module.active && (
                        <ul className="ms-2 ps-3">
                          {setting.module.subModules.map((subModule) => (
                            <li key={subModule.id} className="mt-2 mt-xxl-3">
                              <NavLink
                                activeClassName="active"
                                to={`${process.env.PUBLIC_URL}/${subModule.link}`}
                              >
                                {subModule.title}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                  </>
                )}
                {setting.module.type === 'Link' && (
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/${setting.module.link}`}
                    activeClassName="active"
                    exact
                    className="d-flex align-items-center mt-4"
                  >
                    <Icons title={setting.module.icon} />{' '}
                    <span>{active && setting.module.title}</span>
                  </NavLink>
                )}
              </Fragment>
            ))}
          </div>
          <div className="zoom d-flex flex-column justify-content-center">
            {/* <NavLink
              activeClassName="active"

              to={`${process.env.PUBLIC_URL}/configuracao`}
              className="my-3 my-xxl-4 pt-xxl-2"
            >
              <IoMdSettings size={24} color="#BFCBDA" />{' '}
              <span>{active && 'Configuração'}</span>
            </NavLink> */}
            {/* <NavLink
              to={`${process.env.PUBLIC_URL}/ajuda`}
              className="mb-3 mb-xxl-4 pt-xxl-2"
              activeClassName="active"
            >
              <KrDoubt size={24} color="#BFCBDA" secondaryColor="#002f6c" />{' '}
              <span>{active && 'Ajuda'}</span>
            </NavLink> */}

            <button
              type="button"
              className="mb-4 pt-xxl-2 border-0 bg-transparent d-flex align-items-center justify-content-start"
              onClick={() => signOut()}
            >
              <KrSignout size={24} color="#BFCBDA" />{' '}
              <span>{active && 'Sair'} </span>
            </button>
          </div>
        </Menu>
      </Container>
    </>
  );
};

export default Header;
