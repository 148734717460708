import React from 'react';
import { IProps } from './interfaces';

const KrTennisRacket: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2646)">
        <path
          d="M28.32 4.28003C27.95 3.88003 24.69 0.450026 20.3 0.250026C17.77 0.130026 15.39 1.10003 13.22 3.13003C7.14 8.82003 7.58 13.45 7.79 15.67C7.82 15.98 7.85 16.25 7.85 16.48C7.85 16.55 7.85 16.62 7.85 16.68V16.93C7.83 17.46 7.79 18.82 7.6 20.01C7.46 20.82 7.08 21.56 6.48 22.14L0.579999 27.92C-0.0500008 28.54 -0.0700008 29.56 0.549999 30.2L1.59 31.27C1.9 31.58 2.31 31.76 2.75 31.77H2.77C3.2 31.77 3.61 31.6 3.92 31.3L10.01 25.33C10.29 25.06 10.64 24.87 11.03 24.8C11.93 24.62 13.25 24.43 14.42 24.49C22.12 24.9 27.82 22.44 30.48 17.6C34.45 10.37 28.89 4.84003 28.32 4.30003V4.28003ZM4.17 28.52L3.33 27.73L3.9 27.17L4.46 28.23L4.16 28.52H4.17ZM4.8 26.3L5.29 25.82L5.85 26.88L5.36 27.36L4.8 26.3ZM6.18 24.95L6.51 24.63L7.07 25.69L6.74 26.01L6.18 24.95ZM7.41 23.75L7.73 23.43C7.73 23.43 7.79 23.35 7.83 23.32L8.66 24.13L7.97 24.8L7.41 23.74V23.75ZM1.97 29.07L2.48 28.57L3.32 29.36L2.78 29.89L1.98 29.07H1.97ZM28.9 16.71C26.56 20.98 21.58 23.04 14.51 22.67C13.17 22.6 11.69 22.82 10.69 23.01C10.31 23.08 9.96 23.21 9.62 23.39L8.56 22.35C8.95 21.72 9.23 21.04 9.35 20.3C9.57 18.99 9.61 17.52 9.62 16.95V16.77C9.63 16.67 9.64 16.58 9.64 16.48C9.64 16.21 9.61 15.88 9.57 15.5C9.37 13.41 9 9.53003 14.44 4.44003C16.24 2.76003 18.17 1.95003 20.2 2.04003C24.01 2.21003 26.97 5.47003 27 5.51003L27.07 5.58003C27.29 5.77003 32.35 10.41 28.89 16.71H28.9Z"
          fill={color}
        />
        <path
          d="M20.33 7.75998C21.26 7.75998 22.02 7.00998 22.02 6.08998C22.02 5.16998 21.26 4.41998 20.33 4.41998C19.4 4.41998 18.64 5.16998 18.64 6.08998C18.64 7.00998 19.4 7.75998 20.33 7.75998ZM20.33 5.62998C20.6 5.62998 20.82 5.83998 20.82 6.09998C20.82 6.35998 20.6 6.56998 20.33 6.56998C20.06 6.56998 19.84 6.35998 19.84 6.09998C19.84 5.83998 20.06 5.62998 20.33 5.62998Z"
          fill={color}
        />
        <path
          d="M24.94 9.26999C25.87 9.26999 26.63 8.51999 26.63 7.59999C26.63 6.67999 25.87 5.92999 24.94 5.92999C24.01 5.92999 23.25 6.67999 23.25 7.59999C23.25 8.51999 24.01 9.26999 24.94 9.26999ZM24.94 7.13999C25.21 7.13999 25.43 7.34999 25.43 7.60999C25.43 7.86999 25.21 8.07999 24.94 8.07999C24.67 8.07999 24.45 7.86999 24.45 7.60999C24.45 7.34999 24.67 7.13999 24.94 7.13999Z"
          fill={color}
        />
        <path
          d="M20.36 9.85002C20.15 10.24 20.12 10.69 20.25 11.12C20.48 11.83 21.14 12.28 21.86 12.28C22.03 12.28 22.19 12.26 22.36 12.2C22.79 12.07 23.15 11.77 23.36 11.37C23.57 10.98 23.6 10.53 23.47 10.1C23.19 9.23002 22.24 8.74002 21.36 9.01002C20.93 9.14002 20.57 9.44002 20.36 9.84002V9.85002ZM21.43 10.41C21.49 10.3 21.59 10.21 21.72 10.17C21.77 10.15 21.82 10.15 21.87 10.15C22.08 10.15 22.27 10.28 22.33 10.48C22.37 10.59 22.36 10.72 22.3 10.82C22.24 10.93 22.14 11.02 22.01 11.06C21.75 11.14 21.48 11 21.4 10.75C21.36 10.64 21.37 10.51 21.43 10.41Z"
          fill={color}
        />
        <path
          d="M25.97 10.53C25.54 10.66 25.18 10.96 24.97 11.36C24.76 11.75 24.73 12.2 24.86 12.63C25.09 13.34 25.75 13.79 26.47 13.79C26.64 13.79 26.8 13.77 26.97 13.71C27.4 13.58 27.76 13.28 27.97 12.88C28.18 12.49 28.21 12.04 28.08 11.61C27.8 10.74 26.85 10.25 25.97 10.52V10.53ZM26.94 11.99C26.98 12.1 26.97 12.23 26.91 12.33C26.85 12.44 26.75 12.53 26.62 12.57C26.36 12.65 26.08 12.51 26.01 12.26C25.97 12.15 25.98 12.02 26.04 11.92C26.1 11.81 26.2 11.72 26.33 11.68C26.38 11.66 26.43 11.66 26.48 11.66C26.69 11.66 26.88 11.79 26.94 11.99Z"
          fill={color}
        />
        <path
          d="M17.25 10.77C17.42 10.77 17.58 10.75 17.75 10.69C18.18 10.56 18.54 10.26 18.75 9.86001C18.96 9.47001 18.99 9.02001 18.86 8.59001C18.58 7.72001 17.63 7.23001 16.75 7.50001C16.32 7.63001 15.96 7.93001 15.75 8.33001C15.54 8.72001 15.51 9.17001 15.64 9.59001C15.87 10.3 16.53 10.75 17.25 10.75V10.77ZM16.81 8.90001C16.87 8.79001 16.97 8.70001 17.1 8.66001C17.15 8.64001 17.2 8.64001 17.25 8.64001C17.46 8.64001 17.65 8.77001 17.71 8.97001C17.75 9.08001 17.74 9.21001 17.68 9.31001C17.62 9.42001 17.52 9.51001 17.39 9.55001C17.13 9.63001 16.86 9.49001 16.78 9.24001C16.74 9.13001 16.75 9.00001 16.81 8.90001Z"
          fill={color}
        />
        <path
          d="M19.29 15.22C19.72 15.09 20.08 14.79 20.29 14.39C20.5 14 20.53 13.55 20.4 13.12C20.12 12.25 19.17 11.76 18.29 12.03C17.86 12.16 17.5 12.46 17.29 12.86C17.08 13.25 17.05 13.7 17.18 14.12C17.41 14.83 18.07 15.28 18.79 15.28C18.96 15.28 19.12 15.26 19.29 15.2V15.22ZM18.32 13.77C18.28 13.66 18.29 13.53 18.35 13.43C18.41 13.32 18.51 13.23 18.64 13.19C18.69 13.17 18.74 13.17 18.79 13.17C19 13.17 19.19 13.3 19.25 13.5C19.29 13.61 19.28 13.74 19.22 13.84C19.16 13.95 19.06 14.04 18.93 14.08C18.67 14.16 18.4 14.02 18.32 13.77Z"
          fill={color}
        />
        <path
          d="M22.84 13.63C22.41 13.76 22.05 14.06 21.84 14.46C21.63 14.85 21.6 15.3 21.73 15.73C21.96 16.44 22.62 16.89 23.34 16.89C23.51 16.89 23.67 16.87 23.84 16.81C24.27 16.68 24.63 16.38 24.84 15.98C25.05 15.59 25.08 15.14 24.95 14.71C24.67 13.84 23.72 13.35 22.84 13.62V13.63ZM23.78 15.43C23.72 15.54 23.62 15.63 23.49 15.67C23.23 15.75 22.95 15.61 22.87 15.36C22.83 15.24 22.84 15.12 22.9 15.02C22.96 14.91 23.06 14.82 23.19 14.78C23.24 14.76 23.29 14.76 23.34 14.76C23.55 14.76 23.74 14.89 23.8 15.09C23.84 15.2 23.83 15.33 23.77 15.43H23.78Z"
          fill={color}
        />
        <path
          d="M19.82 16.56C19.39 16.69 19.03 16.99 18.82 17.39C18.61 17.78 18.58 18.23 18.71 18.65C18.94 19.36 19.6 19.81 20.32 19.81C20.49 19.81 20.65 19.79 20.82 19.73C21.25 19.6 21.61 19.3 21.82 18.9C22.03 18.51 22.06 18.06 21.93 17.63C21.65 16.76 20.7 16.27 19.82 16.54V16.56ZM20.76 18.36C20.7 18.47 20.6 18.56 20.47 18.6C20.21 18.68 19.94 18.54 19.86 18.29C19.82 18.18 19.83 18.05 19.89 17.95C19.95 17.84 20.05 17.75 20.18 17.71C20.23 17.69 20.28 17.69 20.33 17.69C20.54 17.69 20.73 17.82 20.79 18.02C20.83 18.13 20.82 18.26 20.76 18.36Z"
          fill={color}
        />
        <path
          d="M15.1 15.05C14.67 15.18 14.31 15.48 14.1 15.88C13.89 16.27 13.86 16.72 13.99 17.15C14.22 17.86 14.89 18.31 15.6 18.31C15.77 18.31 15.93 18.29 16.1 18.23C16.53 18.1 16.89 17.8 17.1 17.4C17.31 17.01 17.34 16.56 17.21 16.13C16.93 15.26 15.98 14.77 15.1 15.04V15.05ZM16.04 16.85C15.98 16.96 15.88 17.05 15.75 17.09C15.49 17.17 15.21 17.03 15.13 16.78C15.09 16.67 15.1 16.54 15.16 16.44C15.22 16.33 15.32 16.24 15.45 16.2C15.5 16.18 15.55 16.18 15.6 16.18C15.81 16.18 16 16.31 16.06 16.51C16.1 16.62 16.09 16.75 16.03 16.85H16.04Z"
          fill={color}
        />
        <path
          d="M14.15 13.79C14.32 13.79 14.48 13.77 14.65 13.71C15.08 13.58 15.44 13.28 15.65 12.88C15.86 12.49 15.89 12.04 15.76 11.61C15.48 10.74 14.53 10.25 13.65 10.52C13.22 10.65 12.86 10.95 12.65 11.35C12.44 11.74 12.41 12.19 12.54 12.62C12.77 13.33 13.44 13.78 14.15 13.78V13.79ZM13.72 11.92C13.78 11.81 13.88 11.72 14.01 11.68C14.06 11.66 14.11 11.66 14.16 11.66C14.37 11.66 14.56 11.79 14.62 11.99C14.66 12.11 14.65 12.23 14.59 12.33C14.53 12.44 14.43 12.53 14.3 12.57C14.04 12.65 13.77 12.51 13.69 12.26C13.65 12.15 13.66 12.02 13.72 11.92Z"
          fill={color}
        />
        <path
          d="M12.33 17.49C12.08 17.24 11.71 17.17 11.38 17.31C11.06 17.45 10.85 17.76 10.85 18.11V20.56C10.85 21.04 11.24 21.43 11.72 21.43H14.22C14.58 21.43 14.89 21.22 15.03 20.89C15.16 20.56 15.09 20.19 14.83 19.95L12.33 17.5V17.49ZM12.05 20.22V18.89L13.41 20.22H12.05Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default KrTennisRacket;
