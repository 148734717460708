import styled from 'styled-components';

export const Container = styled.div`
  p {
    color: #15447a;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
  }
  span {
    color: #5a5a5a;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
  }

  .w-12 {
    min-width: 12%;
  }
  .w-22 {
    min-width: 22%;
  }
  .bg-1 {
    min-height: 4px;
    background-color: #00a3e0;
    border-radius: 2px 0 0 2px;
  }
  .bg-2 {
    min-height: 4px;
    background-color: #068cc7;
  }
  .bg-3 {
    min-height: 4px;
    background-color: #0b74ad;
  }
  .bg-4 {
    min-height: 4px;
    background-color: #105c94;
  }
  .bg-5 {
    min-height: 4px;
    background-color: #15447a;
    border-radius: 0 2px 2px 0;
  }
`;
