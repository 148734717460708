import React, { useCallback, useEffect, useMemo, useState } from 'react';

import axios from 'axios';

import { Container } from './styles';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';
import InputMask from '~/components/InputMask';
import InputDate from '~/components/InputDate';
import Textarea from '~/components/Textarea';
import api from '~/services/api';

interface IType {
  id: number;
  name: string;
}

interface IBasicData {
  onChangeEventName(value: string): void;
  onChangeType(option: IOption): void;
  className: string;
}

const BasicData: React.FC<IBasicData> = ({
  onChangeEventName,
  onChangeType,
  className,
}) => {
  const [types, setTypes] = useState<IOption[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [salesEndDate, setSalesEndDate] = useState<Date>(new Date());
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');

  const handleLoadTypes = useCallback(async (search = '') => {
    const response = await api.get<IType[]>('events-types', {
      params: { search, isOrganizer: true },
    });
    const data = response.data.map<IOption>((type) => ({
      id: type.id,
      value: type.name,
      selected: false,
    }));
    setTypes(data);
  }, []);

  useEffect(() => {
    handleLoadTypes();
  }, [handleLoadTypes]);

  const handleChangeType = useCallback(
    (option) => {
      onChangeType(option);
    },
    [onChangeType]
  );

  const handleChangeDate = useCallback((value) => {
    setDate(value);
  }, []);

  const handleChangeEndDate = useCallback((value) => {
    setEndDate(value);
  }, []);

  const handleChangeSalesEndDate = useCallback((value) => {
    setSalesEndDate(value);
  }, []);

  const handleChangeZipCode = useCallback(async (e) => {
    const { value } = e.target;
    if (value.length === 9) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      setZipCode(response.data.cep);
      setState(response.data.uf);
      setCity(response.data.localidade);
      setAddress(response.data.logradouro);
      setNeighborhood(response.data.bairro);
    }
  }, []);

  const handleState = useCallback((e) => {
    setState(e.target.value);
  }, []);

  const handleCity = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const handleAddress = useCallback((e) => {
    setAddress(e.target.value);
  }, []);

  const handleNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.value);
  }, []);

  const handleNumber = useCallback((e) => {
    setNumber(e.target.value);
  }, []);

  const addressMap = useMemo(() => {
    return address
      ? `${address} n˚ ${number}, ${neighborhood} - ${city}/${state}, CEP: ${zipCode}`
      : 'Brazil';
  }, [address, city, neighborhood, number, state, zipCode]);

  return (
    <Container className={className}>
      <div className="col-12">
        <h2 className="h4 fw-semibold text-center mt-5">1- Dados básicos</h2>
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Nome do evento</h3>
        <Input
          name="name"
          type="text"
          onChange={(e) => onChangeEventName(e.target.value)}
          className="input"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Esporte</h3>
        <Select
          name="type"
          className="input"
          options={types}
          onChange={handleChangeType}
          placeholder="Selecione"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Data Inicio</h3>
        <InputDate
          className="py-1"
          name="date"
          selected={date}
          onChange={handleChangeDate}
          colorSvg="#5D5D5D"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Data Fim</h3>
        <InputDate
          className="py-1"
          name="end_date"
          selected={endDate}
          onChange={handleChangeEndDate}
          colorSvg="#5D5D5D"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Fim das Vendas</h3>
        <InputDate
          className="py-1"
          name="sales_end_date"
          selected={salesEndDate}
          onChange={handleChangeSalesEndDate}
          colorSvg="#5D5D5D"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Status</h3>
        <Select
          name="status"
          className="input"
          options={[
            {
              id: 1,
              value: 'Rascunho',
              selected: true,
            },
          ]}
          placeholder="Selecione"
          disabled
        />
      </div>
      <div className="col-lg-4 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">País</h3>
        <Input
          value="Brasil"
          name="country"
          type="text"
          className="pe-none input"
        />
      </div>
      <div className="col-lg-4 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">CEP</h3>
        <InputMask
          kind="custom"
          options={{ mask: '99999-999' }}
          name="zipcode"
          value={zipCode}
          onChange={handleChangeZipCode}
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Estado</h3>
        <Input
          name="state"
          onChange={handleState}
          value={state}
          type="text"
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Cidade</h3>
        <Input
          name="city"
          value={city}
          onChange={handleCity}
          type="text"
          className="input"
        />
      </div>
      <div className="col-lg-12 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Endereço</h3>
        <Input
          name="address"
          value={address}
          onChange={handleAddress}
          type="text"
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Bairro</h3>
        <Input
          name="neighborhood"
          type="text"
          value={neighborhood}
          onChange={handleNeighborhood}
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Número</h3>
        <Input
          name="number"
          value={number}
          onChange={handleNumber}
          className="input"
        />
      </div>
      <div className="col-12 px-lg-2 mt-3">
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=${
            process.env.REACT_APP_MAPS_KEY
          }&q=${addressMap}&zoom=${addressMap === 'Brazil' ? 2 : 15}`}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="map"
        />
      </div>
      <div className="col-12 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Descrição</h3>
        <Textarea name="description" rows={5} className="rounded" />
      </div>
    </Container>
  );
};

export default BasicData;
