import React from 'react';
import { IProps } from './interfaces';

const KrVolleyBall: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1907)">
        <path
          d="M16 0.0199966C7.19 0.0199966 0.0200005 7.19 0.0200005 16C0.0200005 24.81 7.19 31.98 16 31.98C24.81 31.98 31.98 24.81 31.98 16C31.98 7.19 24.81 0.0199966 16 0.0199966ZM30.23 16C30.23 18.54 29.56 20.91 28.39 22.98C27.21 24.14 24.58 25.93 19.74 25.82C19.64 25.82 19.55 25.84 19.46 25.89C18.48 24.96 17.56 23.72 16.99 22.09C25.24 21.96 28.73 17.8 30.17 14.85C30.2 15.23 30.23 15.62 30.23 16.01V16ZM17.51 30.15C7.5 22.94 10.7 15.21 11.61 13.47C12.85 13.98 14.04 14.8 15.18 15.94C15.24 16 15.31 16.05 15.39 16.08C14.2 24.05 19.19 27.63 21.88 28.95C20.52 29.57 19.06 29.99 17.51 30.15ZM8.41 28.02C3.75 22.82 5.61 14.72 6 13.21C6.77 12.98 7.61 12.82 8.5 12.82C9.16 12.82 9.8 12.91 10.44 13.07C9.4 15.25 6.68 23 15.58 30.2C12.95 30.12 10.5 29.33 8.41 28.01V28.02ZM3.68 8.9C6.15 7.89 15.65 4.82 21.92 14.4C20.38 15.12 18.5 15.45 16.26 15.39H16.24C16.24 15.39 16.2 15.39 16.18 15.4C16.16 15.28 16.11 15.17 16.02 15.08C13.73 12.78 11.19 11.61 8.5 11.61H8.49C5.69 11.61 3.36 12.87 1.94 13.87C2.21 12.08 2.81 10.4 3.68 8.9ZM27.77 8C28.69 9.35 29.37 10.88 29.79 12.51C29.24 14.66 26.76 20.85 16.66 20.87C16.4 19.65 16.33 18.23 16.54 16.61C16.62 16.61 16.7 16.61 16.78 16.61C20.36 16.61 23.15 15.61 25.09 13.64C26.91 11.79 27.55 9.51 27.77 8ZM26.66 6.6C26.66 7.02 26.59 8.12 26.19 9.4C23.07 4.15 18.03 2.4 14.28 1.87C14.84 1.8 15.42 1.76 16 1.76C20.25 1.76 24.05 3.64 26.66 6.6ZM10.43 2.9C13.14 2.76 21.54 3.02 25.58 10.81C25.58 10.82 25.6 10.83 25.61 10.84C25.27 11.52 24.81 12.19 24.21 12.8C23.84 13.18 23.42 13.5 22.98 13.8C17.25 4.98 8.8 6.08 4.8 7.25C6.27 5.37 8.2 3.86 10.43 2.91V2.9ZM1.77 16C1.77 15.84 1.79 15.69 1.79 15.53C2.33 15.08 3.33 14.33 4.62 13.74C4.13 15.92 3.38 20.87 5.28 25.34C3.1 22.84 1.76 19.58 1.76 16H1.77ZM23.23 28.24C22.68 28.03 21.82 27.65 20.88 27.02C23.13 26.93 24.91 26.45 26.27 25.83C25.37 26.77 24.35 27.58 23.23 28.24Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1907">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrVolleyBall;
