import React from 'react';
import { IProps } from './interfaces';

const KrTennisBall: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2659)">
        <path
          d="M16 0C7.17 0 0 7.17 0 16C0 24.83 7.18 32.01 16.01 32.01C24.84 32.01 32.02 24.83 32.02 16C32.02 7.17 24.83 0 16 0ZM30.21 16C30.21 16.2 30.19 16.4 30.18 16.6C25.48 14.98 22.34 10.61 22.34 5.6C22.34 4.86 22.43 4.13 22.56 3.41C27.1 5.78 30.2 10.53 30.2 16H30.21ZM29.77 19.41C23.66 17.87 19.4 12.4 19.4 6.09C19.4 4.82 19.58 3.57 19.92 2.36C20.44 2.51 20.94 2.68 21.43 2.88C21.24 3.77 21.13 4.68 21.13 5.6C21.13 11.23 24.72 16.13 30.07 17.83C30 18.37 29.9 18.89 29.77 19.41ZM11.24 29.37C10.72 29.19 10.22 28.97 9.73 28.73C9.89 27.92 9.99 27.09 9.99 26.26C9.99 20.93 6.78 16.26 1.9 14.33C1.97 13.77 2.07 13.23 2.2 12.7C7.85 14.51 11.73 19.76 11.73 25.78C11.73 27.01 11.56 28.21 11.25 29.38L11.24 29.37ZM1.79 16C1.79 15.86 1.81 15.73 1.81 15.6C6.03 17.43 8.79 21.57 8.79 26.26C8.79 26.89 8.72 27.51 8.62 28.12C4.53 25.62 1.79 21.13 1.79 16ZM16 30.21C14.75 30.21 13.54 30.03 12.39 29.73C12.74 28.45 12.93 27.12 12.93 25.78C12.93 19.22 8.69 13.51 2.52 11.55C4.4 5.89 9.73 1.79 16 1.79C16.95 1.79 17.87 1.89 18.76 2.06C18.4 3.36 18.2 4.71 18.2 6.08C18.2 12.93 22.82 18.87 29.44 20.56C27.53 26.16 22.24 30.2 16 30.2V30.21Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2659">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrTennisBall;
