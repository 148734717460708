import React from 'react';
import { IProps } from './interfaces';

const KrTargetRevenue: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2699)">
        <path
          d="M15.16 16.45H11.92C11.59 16.45 11.32 16.72 11.32 17.05C11.32 17.38 11.59 17.65 11.92 17.65H13.6V18.51C13.6 18.84 13.87 19.11 14.2 19.11C14.53 19.11 14.8 18.84 14.8 18.51V17.65H15.16C15.96 17.65 16.6 17 16.6 16.21V14.61C16.6 13.81 15.95 13.17 15.16 13.17H12.77C12.64 13.17 12.53 13.06 12.53 12.93V11.69C12.53 11.56 12.64 11.45 12.77 11.45H16.01C16.34 11.45 16.61 11.18 16.61 10.85C16.61 10.52 16.34 10.25 16.01 10.25H14.6V9.38998C14.6 9.05998 14.33 8.78998 14 8.78998C13.67 8.78998 13.4 9.05998 13.4 9.38998V10.25H12.77C11.97 10.25 11.33 10.9 11.33 11.69V12.93C11.33 13.73 11.98 14.37 12.77 14.37H15.16C15.29 14.37 15.4 14.48 15.4 14.61V16.21C15.4 16.34 15.29 16.45 15.16 16.45Z"
          fill={color}
        />
        <path
          d="M27.18 12.75L14.69 27.01L6.57 20.68L0.220002 28.08C-0.0999985 28.46 -0.0599985 29.03 0.320001 29.35C0.490001 29.5 0.700002 29.57 0.910002 29.57C1.16 29.57 1.42 29.46 1.59 29.26L6.81 23.17L14.92 29.49L28.53 13.95C28.86 13.58 28.82 13.01 28.45 12.68C28.08 12.35 27.51 12.39 27.18 12.76V12.75Z"
          fill={color}
        />
        <path
          d="M24.18 9.21002C23.68 9.21002 23.28 9.61002 23.28 10.11C23.28 10.61 23.68 11.01 24.18 11.01H30.22V17.05C30.22 17.55 30.62 17.95 31.12 17.95C31.62 17.95 32.02 17.55 32.02 17.05V9.21002H24.18Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2699">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrTargetRevenue;
