import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container } from './styles';

interface IInfiniteScroll {
  direction?: 'horizontal' | 'vertical';
  reverse?: boolean;
  className?: string;
  onInfiniteLoad?(e?: React.UIEventHandler<HTMLDivElement>): Promise<void>;
  scrollLoadThreshold?: number;
  returnScrollable?: boolean;
  id?: string;
}

const InfiniteScroll: React.FC<IInfiniteScroll> = ({
  direction = 'vertical',
  reverse,
  className,
  children,
  onInfiniteLoad,
  scrollLoadThreshold = 100,
  id,
}) => {
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    (e) => {
      let loadMore = false;
      if (direction === 'vertical') {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const limit = scrollHeight - clientHeight;
        if (scrollTop >= limit - scrollLoadThreshold) {
          loadMore = true;
        }
      }

      if (direction === 'horizontal') {
        const { scrollLeft, scrollWidth, clientWidth } = e.target;
        const limit = scrollWidth - clientWidth;
        const scroll = reverse ? scrollLeft * -1 : scrollLeft;
        if (scroll >= limit - scrollLoadThreshold) {
          loadMore = true;
        }
      }

      if (loadMore && onInfiniteLoad && !isInfiniteLoading) {
        setIsInfiniteLoading(true);
        onInfiniteLoad(e).finally(() => {
          setIsInfiniteLoading(false);
        });
      }
    },
    [direction, isInfiniteLoading, onInfiniteLoad, reverse, scrollLoadThreshold]
  );

  const handleScrollWheel = useCallback((deltaY) => {
    if (divRef.current) {
      divRef.current.scrollTop += deltaY * -1;
    }
  }, []);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener('wheel', (e) => {
        if (reverse && direction === 'vertical') {
          e.preventDefault();
          e.stopPropagation();
          handleScrollWheel(e.deltaY);
          return false;
        }

        return true;
      });
    }
  }, [direction, handleScrollWheel, reverse]);

  return (
    <Container
      ref={divRef}
      className={className}
      onScroll={handleScroll}
      reverse={reverse}
      direction={direction}
      id={id}
    >
      {children}
    </Container>
  );
};

export default InfiniteScroll;
