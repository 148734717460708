/* eslint-disable no-console */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { HiPlus } from 'react-icons/hi';
import { FiArrowUpRight } from 'react-icons/fi';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { MdOutlineOpenInFull } from 'react-icons/md';

import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { format, parseISO, startOfDay, subDays } from 'date-fns';
import {
  Bar,
  Container,
  Entries,
  Lots,
  Progress,
  Score,
  Transfer,
} from './styles';

import dollar from '~/assets/icons/dollar-home.svg';
import smille from '~/assets/default/smille.svg';
import pointer from '~/assets/default/pointer-new.svg';
import scale from '~/assets/default/scale-new.svg';
import Select, { IOption } from '~/components/Select';
import BtnNewEvent from '~/components/BtnNewEvent';
import { useResize } from '~/hooks/Resize';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { KrExpand, KrEye, KrEyeSlash } from '~/components/KoroIcons';

interface IItem {
  id: number;
}

interface IOrder {
  id: number;
  total: string;
  status: string;
  created_at: string;
  items: IItem[];
  event: {
    name: string;
    date: string;
    user: {
      name: string;
    };
  };
  payment: {
    payment_type: string;
    installment: number;
  };
}

interface ISaleDay {
  day: string;
  value: number;
}

interface IEvent {
  id: number;
  code: string;
  name: string;
  description: string;
  date: string;
  slug: string;
  batches: IBatchResponse[];
  orders: IOrder[];
}

interface IBatchResponse {
  id: number;
  name: string;
  quantity: number;
  due_date: string;
  event: IEvent;
}

interface IBatch {
  id: number;
  title: string;
  event: string;
  lot: number;
  lotEnd: string;
  ticketsReleased: number;
  ticketsSold: number;
}

const Home: React.FC = () => {
  const { width } = useResize();
  const [showValues, setShowValues] = useState(true);
  const [donutChart, setDonutChart] = useState(300);
  const [lineChart, setLineChart] = useState(450);
  const [available, setAvailable] = useState(0);
  const [total, setTotal] = useState(0);
  const [retained, setRetained] = useState(0);
  const formRef = useRef<FormHandles>(null);
  const [salesDay, setSalesDay] = useState<ISaleDay[]>([]);
  const [batches, setBatches] = useState<IBatch[]>([]);
  const [events, setEvents] = useState<IOption[]>([]);
  const [eventId, setEventId] = useState<number | undefined>(undefined);

  useEffect(() => {
    api.get('wallets/organizers').then((response) => {
      setAvailable(parseFloat(response.data.available));
      setTotal(parseFloat(response.data.total));
      setRetained(parseFloat(response.data.retained));
    });
  }, []);

  useEffect(() => {
    api
      .get<IOrder[]>('orders/organizers', {
        params: {
          event_id: eventId,
        },
      })
      .then((response) => {
        const data = response.data.reduce((previous, item) => {
          const oldSaleDay = previous;
          const dayData = startOfDay(parseISO(item.created_at));
          const day = format(dayData, 'dd/MM');
          const saleDayIndex = previous.findIndex(
            (saleDay) => saleDay.day === day
          );

          if (saleDayIndex >= 0) {
            oldSaleDay[saleDayIndex].value += item.items.length;
          } else {
            oldSaleDay.push({
              day,
              value: item.items.length,
            });
          }

          return oldSaleDay;
        }, [] as ISaleDay[]);

        const lastWeek = [];

        for (let i = 0; i < 7; i += 1) {
          const dayData = subDays(new Date(), i);
          const day = format(dayData, 'dd/MM');

          const daySelected = data.find((saleDay) => saleDay.day === day);

          lastWeek.push({ day, value: daySelected?.value || 0 });
        }

        setSalesDay(lastWeek.reverse());
      });
  }, [eventId]);

  useEffect(() => {
    api.get<IEvent[]>('events/organizer').then((response) => {
      const data = response.data.map<IOption>((event) => ({
        id: event.id,
        value: event.name,
        selected: false,
      }));

      data.unshift({
        id: 'all',
        value: 'Todos',
        selected: true,
      });

      setEvents(data);
    });
  }, []);

  const handleLoadBatches = useCallback(async () => {
    const response = await api.get<IBatchResponse[]>('batches/organizer');

    const data = response.data.map<IBatch>((batch) => {
      const sold = batch.event.orders.reduce(
        (previous, current) => previous + current.items.length,
        0
      );
      return {
        id: batch.id,
        title: batch.event.name,
        event: batch.event.code,
        lot: parseInt(batch.name.replace(/\D/g, ''), 10),
        lotEnd: format(parseISO(batch.due_date), 'dd/MM/yyyy'),
        ticketsReleased: batch.quantity,
        ticketsSold: sold,
      };
    });

    setBatches(data);
  }, []);

  useEffect(() => {
    handleLoadBatches();
  }, [handleLoadBatches]);

  const seriesTransfer = useMemo(
    () =>
      total > 0
        ? [available, available - total > 0 ? total - available : 0, retained]
        : [100],
    [available, retained, total]
  );

  const optionsTransfer = useMemo(() => {
    const data: ApexOptions = {
      colors: total > 0 ? ['#ff6900', '#ff842f', '#ffb580'] : ['#d3d3d3'],
      chart: {
        type: 'donut',
      },

      plotOptions: {
        pie: {
          expandOnClick: false,

          startAngle: 0,
          endAngle: 360,
          donut: {
            size: '65%',
            labels: {
              show: false,
            },
          },
        },
      },

      legend: {
        customLegendItems: ['item1', 'item2', 'item3'],
        show: false,
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        show: true,
        width: total > 0 ? 8 : 0,
        lineCap: 'round',
      },

      tooltip: {
        y: {
          formatter: (value) => {
            return formatPrice(total > 0 ? value : 0);
          },
        },
      },

      labels: ['Disponível para saque', 'Sacado', 'Retido'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    return data;
  }, [total]);

  const seriesEntries = useMemo(
    () => [
      {
        name: 'Vendas',
        data: salesDay.map((saleDay) => saleDay.value),
      },
    ],
    [salesDay]
  );

  const optionsEntries = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        colors: ['#00A3E0'],
      },

      xaxis: {
        categories: salesDay.map((saleDay) => saleDay.day),
      },
    };
    return data;
  }, [salesDay]);

  useEffect(() => {
    if (width > 1200) {
      setDonutChart(240);
      setLineChart(340);
    }
    if (width > 1400) {
      setDonutChart(270);
      setLineChart(390);
    }
    if (width > 1600) {
      setDonutChart(300);
    }
    if (width > 1700) {
      setLineChart(440);
    }
  }, [width]);

  const handleChangeEvent = useCallback((option) => {
    if (option.id !== 'all') {
      setEventId(option.id);
    } else {
      setEventId(undefined);
    }

    setEvents((oldState) => {
      const newEvents = oldState.map((event) => ({
        ...event,
        selected: event.id === option.id,
      }));
      return newEvents;
    });
  }, []);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <div className="zoom bg-white d-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Home</h1>
              <BtnNewEvent />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <Transfer className="col-12 bg-white mt-3">
                <div className="row">
                  <div className="zoom col-12 d-flex align-items-center justify-content-between pt-3 px-3">
                    <h2 className="h3 fw-semibold mb-0">
                      {/* <img src={dollar} alt="Dollar" className="me-2" /> */}
                      Repasse
                    </h2>
                    <Link to={`${process.env.PUBLIC_URL}/financeiro`}>
                      <FiArrowUpRight size={24} color="#8c8c8c" />
                    </Link>
                  </div>
                  <div className="col-12 px-3">
                    <hr />
                  </div>
                  <div className="col-lg-6 px-3 d-flex align-items-center justify-content-center">
                    <ReactApexChart
                      options={optionsTransfer}
                      series={seriesTransfer}
                      type="donut"
                      width={donutChart}
                      height={donutChart}
                    />{' '}
                  </div>
                  <div className="zoom col-lg-6 px-3 values">
                    <p className="h6 fw-normal">Disponível para saque</p>
                    <p className="h2 mb-4 d-flex align-items-center">
                      <span>
                        {showValues ? formatPrice(available) : 'R$ -'}{' '}
                      </span>
                      <button
                        type="button"
                        onClick={() => setShowValues(!showValues)}
                        className="border-0 bg-transparent ms-2"
                      >
                        {showValues ? (
                          <KrEye size={24} color="#FFB580" />
                        ) : (
                          <KrEyeSlash size={24} color="#FFB580" />
                        )}
                      </button>
                    </p>
                    <p className="h6 fw-normal text-white">
                      <span className="px-3 py-1">Total arrecado</span>
                    </p>
                    <p className="h4 mb-4">
                      {showValues ? formatPrice(total) : 'R$ -'}
                    </p>
                    <p className="h6 fw-normal text-white">
                      <span className="px-3 py-1">Sacado</span>
                    </p>
                    <p className="h4 mb-4">
                      {showValues
                        ? formatPrice(
                            available - total > 0 ? total - available : 0
                          )
                        : 'R$ -'}{' '}
                    </p>
                    <p className="h6 fw-normal text-white">
                      <span className="px-3 py-1">Retido</span>
                    </p>
                    <p className="h4 mb-4">
                      {showValues ? formatPrice(retained) : 'R$ -'}
                    </p>
                  </div>
                </div>
              </Transfer>
              <Entries className="col-12 bg-white mt-3">
                <Form ref={formRef} onSubmit={() => console.log('submit')}>
                  <div className="zoom px-4 py-3 d-flex align-items-center justify-content-between">
                    <h2 className="h3 fw-semibold">
                      Inscrições nos ultimos dias
                    </h2>{' '}
                    <div className="d-flex align-items-center ">
                      <span className="fw-normal">Evento</span>
                      <Select
                        name="event"
                        className="ms-3"
                        options={events}
                        onChange={handleChangeEvent}
                      />
                      {/* <button type="button" className="btn-full bg-transparent">
                        <KrExpand size={18} color="#B0B0B0" />
                      </button> */}
                    </div>
                  </div>
                  <div>
                    <ReactApexChart
                      options={optionsEntries}
                      series={seriesEntries}
                      type="line"
                      height={lineChart}
                    />
                  </div>
                </Form>
              </Entries>
            </div>
          </div>
          <div className="col-lg-4 mt-3 ps-3 pe-0">
            <Lots className="zoom bg-white p-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="h3 fw-semibold">Lotes atuais</h2>{' '}
                <Link
                  className="h6 fw-normal"
                  to={`${process.env.PUBLIC_URL}/eventos`}
                >
                  Ver todos
                </Link>
              </div>
              {batches.slice(0, 3).map((batch) => (
                <div className="bd-top py-4">
                  <h3 className="h6 fw-normal mb-0">{batch.title}</h3>
                  <p className="mb-0">Evento #{batch.event}</p>
                  <p>Lote {batch.lot}</p>
                  <p>
                    <span className="fw-regular">vencimento</span>:{' '}
                    {batch.lotEnd}
                  </p>
                  <p className="d-flex justify-content-between">
                    <span>
                      <span className="fw-regular">Ingressos liberados:</span>{' '}
                      {batch.ticketsReleased}
                    </span>
                    <span>
                      <span className="fw-regular">Ingressos vendidos:</span>{' '}
                      {batch.ticketsSold}
                    </span>
                  </p>
                  <Bar className="ms-auto">
                    <Progress
                      width={(batch.ticketsSold / batch.ticketsReleased) * 100}
                    />
                  </Bar>
                </div>
              ))}
            </Lots>
            <Score rotate={0} className="zoom bg-white p-3 mt-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="h3 fw-semibold">Score</h2>{' '}
                <Link className="h6 fw-normal" to="/">
                  Ver completo
                </Link>
              </div>
              <hr />
              <div className="text-center mb-3">
                <img src={smille} alt="Sorriso" className="emogi" />
              </div>
              <div className="text-center position-relative">
                <img src={scale} alt="escala" className="scale" />
                <div className="divPointer">
                  <img src={pointer} alt="Ponteiro" className="pointer" />
                </div>
              </div>
              <p className="h6 fw-normal text-center mb-0 mt-3">
                O score médio dos seus clientes é ótimo!
              </p>
            </Score>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Home;
