import React from 'react';
import { IProps } from './interfaces';

const KrInvoice: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.01 6.47002H17.74C18.07 6.47002 18.34 6.20002 18.34 5.87002C18.34 5.54002 18.07 5.27002 17.74 5.27002H7.01C6.68 5.27002 6.41 5.54002 6.41 5.87002C6.41 6.20002 6.68 6.47002 7.01 6.47002Z"
        fill={color}
      />
      <path
        d="M24.19 9.91998H7.01C6.68 9.91998 6.41 10.19 6.41 10.52C6.41 10.85 6.68 11.12 7.01 11.12H24.19C24.52 11.12 24.79 10.85 24.79 10.52C24.79 10.19 24.52 9.91998 24.19 9.91998Z"
        fill={color}
      />
      <path
        d="M24.19 14.56H7.01C6.68 14.56 6.41 14.83 6.41 15.16C6.41 15.49 6.68 15.76 7.01 15.76H24.19C24.52 15.76 24.79 15.49 24.79 15.16C24.79 14.83 24.52 14.56 24.19 14.56Z"
        fill={color}
      />
      <path
        d="M24.19 19.2H7.01C6.68 19.2 6.41 19.47 6.41 19.8C6.41 20.13 6.68 20.4 7.01 20.4H24.19C24.52 20.4 24.79 20.13 24.79 19.8C24.79 19.47 24.52 19.2 24.19 19.2Z"
        fill={color}
      />
      <path
        d="M27.17 0H4.84C3.35 0 2.13 1.21 2.13 2.7V31.09C2.13 31.48 2.38 31.82 2.75 31.94C3.12 32.06 3.52 31.94 3.75 31.62L5.82 28.84L8.03 31.64C8.21 31.86 8.48 32 8.76 31.98C9.04 31.98 9.31 31.83 9.47 31.6L11.36 28.94L13.12 31.57C13.28 31.82 13.56 31.96 13.85 31.97C14.15 32 14.42 31.84 14.6 31.6L16.55 28.9L18.48 31.6C18.66 31.85 18.94 31.98 19.25 31.98C19.55 31.97 19.83 31.8 19.99 31.54L21.49 29.03L23.27 31.6C23.44 31.85 23.71 31.96 24.02 31.99C24.32 31.99 24.6 31.84 24.76 31.59L26.5 28.99L28.24 31.59C28.41 31.84 28.69 31.99 28.99 31.99C29.08 31.99 29.17 31.98 29.25 31.95C29.63 31.83 29.89 31.49 29.89 31.09V2.7C29.86 1.21 28.65 0 27.17 0ZM28.06 28.13L27.22 26.88C27.05 26.63 26.77 26.48 26.47 26.48C26.17 26.48 25.89 26.63 25.72 26.88L23.97 29.5L22.14 26.87C21.97 26.62 21.68 26.48 21.37 26.48C21.06 26.49 20.78 26.66 20.63 26.92L19.12 29.45L17.27 26.85C17.1 26.61 16.83 26.47 16.54 26.47C16.25 26.47 15.98 26.61 15.81 26.84L13.89 29.51L12.13 26.87C11.97 26.62 11.69 26.47 11.4 26.47C11.06 26.45 10.82 26.61 10.65 26.85L8.7 29.59L6.51 26.81C6.34 26.59 6.06 26.47 5.79 26.47C5.51 26.47 5.25 26.61 5.08 26.83L3.93 28.37V2.7C3.93 2.2 4.33 1.8 4.83 1.8H27.16C27.66 1.8 28.06 2.2 28.06 2.7V28.13Z"
        fill={color}
      />
    </svg>
  );
};

export default KrInvoice;
