/* eslint-disable no-console */
import React, { useRef } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { IoFilterSharp } from 'react-icons/io5';
import { MdClose } from 'react-icons/md';
import { IoIosEye } from 'react-icons/io';
import { Avatar, Container } from './styles';
import Select from '~/components/Select';

import userAvatar from '~/assets/icons/user_Icon.svg';

const Help: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const requests = [
    {
      avatar: userAvatar,
      name: 'Nome Sobrenome',
      date: '07/06/2023',
      hour: '18:47',
      status: 'Pendente',
    },
    {
      avatar: userAvatar,
      name: 'Nome Sobrenome',
      date: '07/06/2023',
      hour: '18:47',
      status: 'Visualizado',
    },
    {
      avatar: userAvatar,
      name: 'Nome Sobrenome',
      date: '07/06/2023',
      hour: '18:47',
      status: 'Resolvido',
    },
  ];
  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="zoom col-12 px-0">
            <div className="bg-white d-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Central de ajuda</h1>
            </div>
          </div>
          <div className="zoom col-lg-9 ps-0 pe-3">
            <div className="bg-white p-3 mt-3">
              <h2 className="h3">Problemas</h2>
              <hr className="mt-2 mb-4" />
              <p>
                Clientes que compraram algum ingresso para um de seus eventos
                podem abrir uma solicitação de problema sobre este evento e que
                necessite de solução. Esta solução deve ser elaborada pelo
                organizador do evento, passaremos os dados de contato da sua
                preferência para que o cliente entre em contato diretamente com
                o organizador, também notificaremos você quando isto acontecer.
              </p>
              <p>De que maneira gostaria de ser contactado?</p>
              <Form ref={formRef} onSubmit={() => console.log('submit')}>
                <div className="d-flex">
                  <Select
                    name="contact"
                    className="w-25 me-3"
                    options={[
                      {
                        id: 'email',
                        value: 'Email',
                        selected: true,
                      },
                      {
                        id: 'phone',
                        value: 'phone',
                        selected: false,
                      },
                    ]}
                  />
                  <div className="bg-contact w-75 mt-0">
                    organizador.contato@email.com.br
                  </div>
                </div>
              </Form>
              <div className="d-flex mt-4 justify-content-between align-items-center">
                <h3 className="mb-0 h4 fw-semibold">
                  Usuários que solicitaram ajuda
                </h3>{' '}
                <IoFilterSharp size={24} color="#D6D6D6" className="me-2" />
              </div>
              <hr className="mt-3 mb-0" />
              {requests.map((request) => (
                <div className="d-flex justify-content-between align-items-center border-bottom pb-2 pt-3">
                  <div className="d-flex align-items-center">
                    <Avatar src={request.avatar} />
                    <div className="ms-2">
                      <span className="h6 fw-normal d-block">
                        {request.name}
                      </span>
                      <span className="h6 fw-normal d-block date">
                        {request.date} às {request.hour}
                      </span>
                    </div>
                    <div className="ms-2">
                      <span className="h6 fw-normal d-block">Status</span>
                      <span
                        className={`${
                          request.status === 'Pendente' && 'pending'
                        } ${request.status === 'Visualizado' && 'visualized'} ${
                          request.status === 'Resolvido' && 'solved'
                        } status d-block`}
                      >
                        {request.status}
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="me-3 border-0 rounded-circle bg-orange"
                    >
                      <MdClose size={16} color="#fff" />
                    </button>
                    <button
                      type="button"
                      className=" border-0 rounded-circle bg-orange"
                    >
                      <IoIosEye size={16} color="#fff" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="zoom col-lg-3 px-0">
            <div className="bg-white p-3 mt-3">
              <h2 className="h3">Pedir Ajuda</h2>
              <hr className="mt-2 mb-4" />
              <p>
                Caso tenha tido algum problema com nossa plataforma e necessite
                de ajuda, entre em contato com a gente através do Movidesk.
              </p>
              <p>
                É fácil! Basta clicar no botão abaixo, abrir seu ticket e em
                breve retornaremos para você!{' '}
              </p>
              <button
                type="button"
                className="w-100 py-2 btn-new h6 text-white fw-normal"
              >
                Abrir um Ticket
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Help;
