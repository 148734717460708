import React from 'react';
import { IProps } from './interfaces';

const KrTableTennis: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.58 12.21C26.58 5.53999 21.15 0.109985 14.48 0.109985C7.81 0.109985 2.37 5.53999 2.37 12.21C2.37 15.35 3.58 18.21 5.56 20.37L1.52 26.87C0.59 28.48 1.15 30.54 2.75 31.47C3.28 31.78 3.86 31.92 4.43 31.92C5.59 31.92 6.72 31.32 7.33 30.26L11.29 23.88C12.3 24.16 13.37 24.32 14.47 24.32C21.15 24.32 26.58 18.89 26.58 12.21ZM4.57 15.04L17 22.2C16.19 22.4 15.35 22.53 14.48 22.53C9.78 22.53 5.81 19.36 4.58 15.05L4.57 15.04ZM14.48 1.90999C20.16 1.90999 24.79 6.52999 24.79 12.22C24.79 16.47 22.21 20.12 18.53 21.7L4.25 13.47C4.2 13.06 4.17 12.64 4.17 12.21C4.17 6.52999 8.79 1.90999 14.48 1.90999ZM5.79 29.34C5.36 30.09 4.4 30.34 3.65 29.91C2.9 29.48 2.65 28.52 3.06 27.79L6.89 21.63C7.7 22.28 8.6 22.83 9.56 23.26L5.79 29.33V29.34Z"
        fill={color}
      />
      <path
        d="M24.23 23.64C21.94 23.64 20.08 25.5 20.08 27.79C20.08 30.08 21.94 31.94 24.23 31.94C26.52 31.94 28.38 30.08 28.38 27.79C28.38 25.5 26.52 23.64 24.23 23.64ZM24.23 30.74C22.6 30.74 21.28 29.41 21.28 27.79C21.28 26.17 22.6 24.84 24.23 24.84C25.86 24.84 27.18 26.17 27.18 27.79C27.18 29.41 25.86 30.74 24.23 30.74Z"
        fill={color}
      />
    </svg>
  );
};

export default KrTableTennis;
