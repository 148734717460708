import React from 'react';
import { IProps } from './interfaces';

const KrRunningShoes: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.27 20.82L27.02 20.79C26.31 20.77 25.67 20.34 25.4 19.68L22.16 11.84C21.88 11.17 21.23 10.72 20.52 10.72C20.46 10.72 20.4 10.72 20.34 10.72C20.05 10.75 19.73 10.77 19.38 10.77C17.59 10.77 15.22 10.21 14.23 7.49C13.94 6.7 13.22 6.24 12.48 6.24C12.04 6.24 11.6 6.41 11.24 6.76L6.82001 11.18C6.12001 11.88 6.12001 13.03 6.82001 13.73L21.07 27.92C21.41 28.26 21.86 28.44 22.34 28.44H28.17C30.28 28.44 31.99 26.73 31.99 24.62C31.99 22.51 30.33 20.85 28.26 20.8L28.27 20.82ZM12.51 8.05C12.51 8.05 12.54 8.08 12.55 8.11C13.6 10.99 16.03 12.57 19.39 12.57C19.76 12.57 20.15 12.55 20.5 12.51L20.86 13.37L19.93 13.83C19.63 13.98 19.51 14.34 19.66 14.63C19.76 14.84 19.98 14.96 20.2 14.96C20.29 14.96 20.38 14.94 20.47 14.9L21.33 14.47L21.89 15.82L21.12 16.2C20.82 16.35 20.7 16.71 20.85 17C20.95 17.21 21.17 17.33 21.39 17.33C21.48 17.33 21.57 17.31 21.66 17.27L22.35 16.93L23 18.51L22.41 18.8C22.11 18.95 21.99 19.31 22.14 19.6C22.24 19.81 22.46 19.93 22.68 19.93C22.77 19.93 22.86 19.91 22.95 19.87L23.46 19.62L23.75 20.33C24.29 21.65 25.57 22.52 27 22.56L28.25 22.59C29.14 22.61 29.89 23.23 30.13 24.05H23.25C23.11 24.05 22.98 23.99 22.87 23.87L13.04 14.1C13.3 13.5 13.43 12.8 13.43 12C13.43 10.44 13.16 9.36 11.99 8.55L12.52 8.02L12.51 8.05ZM11.11 9.45C11.97 9.94 12.21 10.57 12.21 12.04C12.21 12.48 12.17 12.86 12.08 13.21L9.71001 10.86L11.11 9.46V9.45ZM8.86001 11.7L11.51 14.33C11.28 14.6 10.99 14.82 10.63 15L8.09001 12.47L8.86001 11.7ZM28.18 26.65H22.35L11.52 15.87C11.84 15.67 12.12 15.43 12.36 15.16L21.99 24.73C22.31 25.07 22.77 25.27 23.23 25.27H30.08C29.81 26.07 29.06 26.65 28.17 26.65H28.18Z"
        fill={color}
      />
      <path
        d="M3.39999 7.49H7.77999C8.10999 7.49 8.37999 7.22 8.37999 6.89C8.37999 6.56 8.10999 6.29 7.77999 6.29H3.39999C3.06999 6.29 2.79999 6.56 2.79999 6.89C2.79999 7.22 3.06999 7.49 3.39999 7.49Z"
        fill={color}
      />
      <path
        d="M0.85 18.65H6.29C6.62 18.65 6.89 18.38 6.89 18.05C6.89 17.72 6.62 17.45 6.29 17.45H0.85C0.52 17.45 0.25 17.72 0.25 18.05C0.25 18.38 0.52 18.65 0.85 18.65Z"
        fill={color}
      />
      <path
        d="M5.70001 23.74C5.70001 24.07 5.97001 24.34 6.30001 24.34H13.14C13.47 24.34 13.74 24.07 13.74 23.74C13.74 23.41 13.47 23.14 13.14 23.14H6.30001C5.97001 23.14 5.70001 23.41 5.70001 23.74Z"
        fill={color}
      />
      <path
        d="M4.05001 23.74C4.05001 23.41 3.78001 23.14 3.45001 23.14H0.770013C0.440013 23.14 0.170013 23.41 0.170013 23.74C0.170013 24.07 0.440013 24.34 0.770013 24.34H3.45001C3.78001 24.34 4.05001 24.07 4.05001 23.74Z"
        fill={color}
      />
      <path
        d="M17.59 27.51H4.27999C3.94999 27.51 3.67999 27.78 3.67999 28.11C3.67999 28.44 3.94999 28.71 4.27999 28.71H17.6C17.93 28.71 18.2 28.44 18.2 28.11C18.2 27.78 17.93 27.51 17.6 27.51H17.59Z"
        fill={color}
      />
    </svg>
  );
};

export default KrRunningShoes;
