import styled from 'styled-components';

export const Container = styled.div`
  min-width: calc(100% - 88px);

  h1 {
    font-size: 26px;
    font-weight: 500;
  }

  hr {
    background: #e1e1e1;
    opacity: 1;
  }

  a {
    :hover {
      color: unset;
    }
  }

  .container-fluid {
    max-width: 1460px;
  }

  .btn-new {
    border-radius: 4px;
    background: #ff6900;
    border: none;
  }

  .bg-white {
    border-radius: 8px;
  }
`;

export const Ticket = styled.div`
  font-family: 'Roboto';

  p {
    font-weight: 900 !important;
  }

  img {
    width: 32px;
    height: 32px;
  }

  .bg-icon {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    margin: 20px;
  }

  .bg-color-orange {
    background-color: #ff842f;
    border-radius: 8px;
  }

  .bg-color-blue {
    background-color: #00a3e0;
    border-radius: 8px;
  }
`;

export const Entries = styled.div`
  font-family: 'Roboto';

  .btn-full {
    padding: 0px 3px;
    border-radius: 2px;
    border: 1px solid #838383;
  }

  .sold-input {
    width: 50%;
    max-width: 200px;
  }
`;

export const NextEvent = styled.div`
  font-family: 'Roboto';
  img {
    width: 64px;
    height: 64px;
    border-radius: 64px;
    border: 2px solid #002f6c;
    object-fit: cover;
    object-position: center;
  }
  p {
    color: #8c8c8c;
  }
`;

export const MyLots = styled.div`
  .see-all {
    color: #949494;
  }

  .bg-title {
    background-color: #002f6c;
    border-radius: 8px 8px 0px 0px;
  }

  .bg-title,
  .table-rows {
    span:nth-child(1) {
      width: 9%;
    }
    span:nth-child(2) {
      width: 20%;
    }
    span:nth-child(3) {
      width: 17%;
    }
    span:nth-child(4) {
      width: 17%;
    }
    span:nth-child(5) {
      width: 37%;
    }
  }

  .table-rows span {
    color: #7e7e7e;
  }

  .btn-arrow {
    background-color: #ff842f;
  }

  .table {
    > div:first-child {
      padding: 0px !important;
    }
    div {
      border: none;
    }
  }

  .content {
    border-radius: 8px 8px 0px 0px;
    ::-webkit-scrollbar {
      background: transparent;
      width: 5px;
      height: 8px;
      border: none;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #002f6c;
      border-radius: 4px;
    }
  }

  .tb-header {
    background-color: #002f6c;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 16px;
    width: 100%;
  }

  .tb-header .tb-row button {
    color: #fff;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    justify-content: center;

    svg {
      color: #fff !important;
    }
  }

  .tb-header .tb-row button {
    svg {
      display: none;
    }
  }

  .tb-body {
    width: 100%;
  }

  .tb-body .tb-row {
    padding: 1rem 0.5rem;
    text-align: center;

    :not(:last-child) {
      border-bottom: 0.5px solid #e1e1e1;
    }
    div {
      color: #7e7e7e;

      font-size: 16px;
      font-weight: 400;

      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (min-width: 992px) {
    /* .number-id {
      min-width: 55px;
      width: auto;
    } */

    .tb-header .tb-row {
      width: 100%;

      button:nth-child(1) {
        width: 31%;
      }
      button:nth-child(2) {
        width: 12%;
      }
      button:nth-child(3) {
        width: 12%;
      }
      button:nth-child(4) {
        width: 18%;
      }

      /* button:nth-child(5) {
        width: 17%;
      } */
      button:nth-child(5) {
        width: 15%;
      }
      button:nth-child(6) {
        width: 12%;
      }
    }

    .tb-body .tb-row {
      padding: 0.5rem;
      div:nth-child(1) {
        width: 31%;
      }
      div:nth-child(2) {
        width: 12%;
      }
      div:nth-child(3) {
        width: 12%;
      }
      div:nth-child(4) {
        width: 18%;
      }
      /* div:nth-child(5) {
        width: 17%;
      } */
      div:nth-child(5) {
        width: 15%;
      }
      div:nth-child(6) {
        width: 12%;
      }
    }
  }
`;

export const Filter = styled.div`
  h2 {
    color: #000;
    font-family: 'Roboto';
  }
`;
