import React from 'react';
import { IProps } from './interfaces';

const KrVolleyFuteball: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2661)">
        <path
          d="M26.24 3.69C25.71 3.25 25.19 2.87 24.64 2.51L24.41 2.37C21.88 0.81 18.99 0 16 0C13.01 0 10.23 0.79 7.74 2.29C7.06 2.71 6.46 3.13 5.93 3.57C2.15 6.61 -0.0100021 11.15 -0.0100021 16C-0.0100021 17.29 0.139998 18.56 0.439998 19.76L0.469998 19.87C0.599998 20.4 0.739998 20.88 0.909998 21.3C3.07 27.48 8.69 31.68 15.23 31.99C15.49 32 15.74 32.01 16.01 32.01C16.25 32.01 16.5 32.01 16.75 31.99C23.13 31.71 28.72 27.66 30.98 21.67C31.19 21.15 31.34 20.68 31.44 20.26C31.83 18.88 32.02 17.45 32.02 16C32.02 11.24 29.92 6.75 26.25 3.69H26.24ZM30.21 16C30.21 17.04 30.09 18.06 29.86 19.08L25.28 12.85L25.61 5.56C28.53 8.26 30.21 12.01 30.21 16ZM21.77 24.05L10.15 23.84L6.63 12.58L16.05 5.49L25.26 12.9L21.77 24.05ZM22.92 3.6L16.1 5.42L9.41 3.41C11.43 2.35 13.69 1.79 16 1.79C18.44 1.79 20.82 2.41 22.92 3.59V3.6ZM6.51 5.46L6.61 12.51L2.07 18.66C1.9 17.8 1.79 16.91 1.79 16C1.79 11.94 3.51 8.15 6.51 5.46ZM2.87 21.38L10.16 23.88V23.86V23.85V23.88L14.59 30.13C9.35 29.61 4.88 26.28 2.88 21.38H2.87ZM17.43 30.13L21.99 24.14H22L28.99 21.74C26.93 26.43 22.52 29.63 17.43 30.14V30.13Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2661">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrVolleyFuteball;
