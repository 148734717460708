import styled from 'styled-components';

interface IPointer {
  rotate: number;
}

export const Container = styled.div`
  min-width: calc(100% - 88px);

  h1,
  h2 {
    font-size: 26px;
    font-weight: 500;
  }

  hr {
    background: #e1e1e1;
    opacity: 1;
  }

  a {
    :hover {
      color: unset;
    }
  }

  .container-fluid {
    max-width: 1460px;
  }

  .btn-new {
    border-radius: 4px;
    background: #ff6900;
    border: none;
  }

  .bg-white {
    border-radius: 8px;
  }

  .btn-orange {
    border-radius: 4px;
    background: #ff6900;
  }
`;

export const Filter = styled.div`
  h2 {
    color: #000;
    font-family: 'Roboto';
  }
`;

export const Gender = styled.div`
  p {
    color: #9c9c9c;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    span {
      color: #fff;
      border-radius: 4px;
    }
  }

  path {
    filter: unset;
  }

  .bg-men {
    background: #ff842f;
  }

  .bg-woman {
    background: #002f6c;
  }
`;
export const Age = styled.div``;

export const Score = styled.div<IPointer>`
  p {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
  }

  span {
    color: #31bffd;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
  }

  .scale {
    width: 91px;
    height: 45px;
  }

  .divPointer {
    position: absolute;
    width: 26px;
    top: 27px;
    left: calc(50% - 13px);
    transform: rotate(${(props) => props.rotate}deg);
  }

  .pointer {
    //width: 26px;
    height: 16px;
  }
`;

export const Client = styled.div`
  .position {
    p:nth-child(1) {
      color: #ff6900;
      font-family: 'Roboto';
      font-size: 24px;
      font-weight: 600;
    }
    p:nth-child(2) {
      color: #ff7a00;
      font-family: 'Roboto';
      font-size: 20px;
      font-weight: 500;
    }
    p:nth-child(3) {
      color: #ff7a00;
      font-family: 'Roboto';
      font-size: 20px;
      font-weight: 400;
    }
    p:nth-child(4),
    p:nth-child(5) {
      color: #8c8c8c;
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

export const QrCode = styled.div`
  p {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
  }

  a {
    width: 100%;
    display: block;
    text-align: center;
  }
`;
