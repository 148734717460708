/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { MdEdit, MdClose } from 'react-icons/md';
import { FaList } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { addYears, endOfDay, format, parseISO, startOfDay } from 'date-fns';

import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';

import { Cards, Container, Filter, Modal } from './styles';

import imgDefault from '~/assets/default/qrcode.png';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';
import InputDate from '~/components/InputDate';
import api from '~/services/api';
import InputMask from '~/components/InputMask';
import FiltersLists from '~/components/FiltersLists';
import Toast from '~/utils/toast';
import { KrClose, KrEdit } from '~/components/KoroIcons';

interface IVoucher {
  id: number;
  code: string;
  event: string;
  start: string;
  dueDate: string;
  status: string;
  maxUse: number;
  used: number;
  discountType: string;
  discountValue: number;
}

interface IVoucherDate {
  id: number;
  code: string;
  due_date: string;
  quantity: number;
  price?: number;
  percent?: number;
  status: string;
  created_at: string;
  event: {
    name: string;
  };
}

interface IVoucherResponse {
  data: IVoucherDate[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface IFormData {
  code: string;
  event: string;
  dueDate: string;
  maxUse: string;
  discountType: string;
  discountValue: string;
}

interface IEventData {
  id: number;
  name: string;
}

interface IStatus {
  id: number;
  name: string;
}

interface IEventType {
  id: number;
  name: string;
}

const Vouchers: React.FC = () => {
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [vouchers, setVouchers] = useState<IVoucher[]>([]);
  const formRef = useRef<FormHandles>(null);
  const [events, setEvents] = useState<IOption[]>([]);
  const [discountType, setDiscountType] = useState('percent');
  const [voucherSelected, setVoucherSelected] = useState({} as IVoucher);
  const [endDate, setEndDate] = useState(endOfDay(addYears(new Date(), 1)));
  const [categories, setCategories] = useState<IOption[]>([]);
  const [status, setStatus] = useState<IOption[]>([]);

  useEffect(() => {
    api.get<IStatus[]>('events-status').then((response) => {
      const data = response.data.map<IOption>((statusData) => ({
        id: statusData.id,
        value: statusData.name,
        selected: false,
      }));
      setStatus(data);
    });
  }, []);

  useEffect(() => {
    api
      .get<IEventType[]>('events-types', { params: { isOrganizer: true } })
      .then((response) => {
        const data = response.data.map<IOption>((modality) => ({
          id: modality.id,
          value: modality.name,
          selected: false,
        }));

        setCategories(data);
      });
  }, []);

  const handleLoadEvents = useCallback(async () => {
    const response = await api.get<IEventData[]>('events/organizer');
    const data = response.data.map<IOption>((event) => ({
      id: event.id,
      value: event.name,
      selected: false,
    }));

    setEvents(data);
  }, []);

  const handleLoadVouchers = useCallback(
    async (
      pageData,
      statusData?: string,
      dateStart?: string,
      dateEnd?: string
    ) => {
      const response = await api.get<IVoucherResponse>('vouchers/organizers', {
        params: {
          page: pageData,
          status: statusData,
          dateStart: dateStart ? new Date(dateStart) : undefined,
          dateEnd: dateEnd ? new Date(dateEnd) : undefined,
        },
      });
      const data = response.data.data.map((voucher) => {
        return {
          id: voucher.id,
          code: voucher.code,
          event: voucher.event.name,
          start: format(parseISO(voucher.created_at), 'dd/MM/yyyy'),
          dueDate: format(parseISO(voucher.due_date), 'dd/MM/yyyy'),
          status: voucher.status,
          maxUse: voucher.quantity,
          used: 940,
          discountType: voucher.price ? 'value' : 'percent',
          discountValue: voucher.price || voucher.percent || 0,
        };
      });

      if (pageData === 1) {
        setVouchers(data);
      } else {
        setVouchers((oldState) => [...oldState, ...data]);
      }
    },
    []
  );

  useEffect(() => {
    handleLoadEvents();
    handleLoadVouchers(1);
  }, [handleLoadEvents, handleLoadVouchers]);

  const handleEndDate = useCallback((e) => {
    setEndDate(e);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          code: Yup.string().required('O code é obrigatório'),
          event: Yup.string().required('O evento é obrigatório'),
          dueDate: Yup.string().required('A validade é obrigatória'),
          maxUse: Yup.string().required('O máximo de uso é obrigatório'),
          discountType: Yup.string().required('O tipo de desconto obrigatório'),
          discountValue: Yup.string().required('A cor é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          event_id: data.event,
          code: data.code,
          due_date: data.dueDate,
          quantity: data.maxUse,
          price:
            data.discountType === 'value'
              ? parseFloat(
                  data.discountValue
                    .replace('R$', '')
                    .replaceAll('.', '')
                    .replace(',', '.')
                )
              : undefined,
          percent:
            data.discountType === 'percent' ? data.discountValue : undefined,
        };

        await api.post('vouchers/organizers', formData);

        handleLoadVouchers(1);

        setShow(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [handleLoadVouchers]
  );

  const handleChangeDiscountType = useCallback((option) => {
    setDiscountType(option.id);
  }, []);

  const handleClickEdit = useCallback(
    (voucher) => {
      const newEvents = events.map((event) => ({
        ...event,
        selected: event.value === voucher.event,
      }));
      const [day, month, year] = voucher.dueDate.split('/');
      setDiscountType(voucher.discountType);
      setEvents(newEvents);
      setEndDate(new Date(year, month - 1, day));
      setVoucherSelected(voucher);
      setShowUpdate(true);
    },
    [events]
  );

  const handleClose = useCallback(() => {
    const newEvents = events.map((event) => ({
      ...event,
      selected: false,
    }));
    setEvents(newEvents);
    setEndDate(new Date());
    setVoucherSelected({} as IVoucher);
    setShowUpdate(false);
  }, [events]);

  const handleSubmitUpdate = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          code: Yup.string().required('O code é obrigatório'),
          event: Yup.string().required('O evento é obrigatório'),
          dueDate: Yup.string().required('A validade é obrigatória'),
          maxUse: Yup.string().required('O máximo de uso é obrigatório'),
          discountType: Yup.string().required('O tipo de desconto obrigatório'),
          discountValue: Yup.string().required('A cor é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          event_id: data.event,
          code: data.code,
          due_date: data.dueDate,
          quantity: data.maxUse,
          price:
            data.discountType === 'value'
              ? parseFloat(
                  data.discountValue
                    .replace('R$', '')
                    .replaceAll('.', '')
                    .replace(',', '.')
                )
              : undefined,
          percent:
            data.discountType === 'percent' ? data.discountValue : undefined,
        };

        const response = await api.put(
          `vouchers/organizers/${voucherSelected.id}`,
          formData
        );

        const newVouchers = vouchers.slice();

        const voucherIndex = newVouchers.findIndex(
          (voucher) => voucher.id === voucherSelected.id
        );

        if (voucherIndex >= 0) {
          const eventSelected = events.find((event) => event.id === data.event);

          newVouchers[voucherIndex].code = data.code;
          newVouchers[voucherIndex].event = eventSelected
            ? eventSelected.value
            : '-';
          newVouchers[voucherIndex].start = format(
            parseISO(response.data.created_at),
            'dd/MM/yyyy'
          );
          newVouchers[voucherIndex].dueDate = format(
            new Date(data.dueDate),
            'dd/MM/yyyy'
          );
          newVouchers[voucherIndex].status = 'Ativo';
          newVouchers[voucherIndex].maxUse = parseInt(data.maxUse, 10);
          newVouchers[voucherIndex].used = 940;
          newVouchers[voucherIndex].discountType = data.discountType;
          newVouchers[voucherIndex].discountValue =
            formData.price || parseFloat(formData.percent || '0');
        }

        setVouchers(newVouchers);

        setShow(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [events, voucherSelected.id, vouchers]
  );

  const handleSubmitFilters = useCallback(
    (data) => {
      handleLoadVouchers(1, data.status, data.dateStart, data.dateEnd);
    },
    [handleLoadVouchers]
  );

  const handleRemoveVoucher = useCallback((voucherData: IVoucher) => {
    if (voucherData.status === 'Ativo') {
      Swal.fire({
        icon: 'warning',
        iconColor: '#ff6900',
        title: 'Inativar cupom?',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        confirmButtonColor: '#ff6900',
        cancelButtonColor: '#B34A00',
        reverseButtons: true,
      }).then(async (e) => {
        if (e.isConfirmed) {
          await api.delete(`vouchers/organizers/${voucherData.id}`);
          setVouchers((prevVouchers) =>
            prevVouchers.map((voucher) => {
              if (voucher.id === voucherData.id) {
                return {
                  ...voucher,
                  status: 'Inativo',
                };
              }
              return voucher;
            })
          );
          Toast.fire({
            icon: 'success',
            iconColor: '#ff6900',
            title: 'Cupom inativado com sucesso!',
          });
        }
      });
    } else {
      const [day, month, year] = voucherData.dueDate.split('/');
      console.log(+year, +month - 1, +day);
      const dueDate = new Date(+year, +month - 1, +day);
      if (dueDate > new Date()) {
        Swal.fire({
          icon: 'warning',
          iconColor: '#ff6900',
          title: 'Ativar cupom?',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim',
          confirmButtonColor: '#ff6900',
          cancelButtonColor: '#B34A00',
          reverseButtons: true,
        }).then(async (e) => {
          if (e.isConfirmed) {
            await api.patch(`vouchers/organizers/${voucherData.id}`);
            setVouchers((prevVouchers) =>
              prevVouchers.map((voucher) => {
                if (voucher.id === voucherData.id) {
                  return {
                    ...voucher,
                    status: 'Ativo',
                  };
                }
                return voucher;
              })
            );
            Toast.fire({
              icon: 'success',
              iconColor: '#ff6900',
              title: 'Cupom ativado com sucesso!',
            });
          }
        });
      }
    }
  }, []);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="zoom col-12 px-0">
            <div className="bg-white d-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Cupons</h1>
              <button
                type="button"
                onClick={() => setShow(true)}
                className="mb-0 h6 fw-normal text-white btn-new px-5 py-2"
              >
                <HiPlus size={16} color="#fff" className="me-2" />
                Novo Cupom
              </button>
            </div>
          </div>
          <Filter className="col-12 px-0 mt-3">
            <FiltersLists
              onSubmitFilters={handleSubmitFilters}
              status
              date
              categories={categories}
              statusOptions={status}
            />
          </Filter>

          <div className="zoom col-12 px-lg-0">
            {vouchers.map((voucher) => (
              <Cards className="d-flex bg-white justify-content-between align-items-center p-3 mt-3">
                <div>
                  <img src={imgDefault} alt="Corrida" className="default" />
                </div>
                <div className="w-10">
                  <h3 className="mb-1">Nome</h3>
                  <p className="mb-0">{voucher.code}</p>
                </div>
                <div className="w-15">
                  <h3 className="mb-1">Evento</h3>
                  <p className="mb-0">{voucher.event}</p>
                </div>
                <div>
                  <h3 className="mb-1">Início</h3>
                  <p className="mb-0">{voucher.start}</p>
                </div>
                <div>
                  <h3 className="mb-1">Validade</h3>
                  <p className="mb-0">{voucher.dueDate}</p>
                </div>
                <div>
                  <h3 className="mb-1">Status</h3>
                  <p className="mb-0">{voucher.status}</p>
                </div>
                <div>
                  <h3 className="mb-1">Uso máximo</h3>
                  <p className="mb-0">{voucher.maxUse}</p>
                </div>
                <div>
                  <h3 className="mb-1">Usados</h3>
                  <p className="mb-0">{voucher.used}</p>
                </div>
                <div>
                  {false && (
                    <>
                      <button
                        type="button"
                        className="me-3 border-0 rounded-circle bg-orange"
                      >
                        <FaList size={16} color="#fff" />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleClickEdit(voucher)}
                        className="me-3 border-0 rounded-circle bg-orange"
                      >
                        <KrEdit size={16} color="#fff" />
                      </button>
                    </>
                  )}

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={voucher.status === 'Ativo'}
                      onChange={() => handleRemoveVoucher(voucher)}
                    />
                  </div>
                </div>
              </Cards>
            ))}
          </div>
        </div>
      </div>
      <Modal
        className="zoom modal-filter"
        size="lg"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="justify-content-between align-items-center border-0 px-3 px-sm-5 pb-0 pt-0">
            <Modal.Title className="mb-0 mt-4">
              <h2 className="title mb-0">Adicionar Cupom</h2>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setShow(false)}
              className="border-0 bg-transparent"
            >
              <KrClose size={20} color="#989898" />
            </button>
          </Modal.Header>
          <Modal.Body className="container pt-0 px-3 px-sm-5">
            <div className="row">
              <div className="col-12">
                <hr className="mb-5" />

                <h4 className="h6 fw-normal">Nome (Código)</h4>
                <Input
                  placeholder="CUPOM20"
                  name="code"
                  type="text"
                  className="input height"
                />
              </div>

              <div className="col-12 mt-3">
                <h4 className="h6 fw-normal">Evento</h4>
                <Select
                  name="event"
                  className="input height"
                  options={events}
                />
              </div>

              <div className="col-lg-6 mt-3">
                <h4 className="h6 fw-normal">Validade</h4>
                <InputDate
                  className="height-date"
                  name="dueDate"
                  selected={endDate}
                  onChange={handleEndDate}
                  colorSvg="#5D5D5D"
                />
              </div>

              <div className="col-lg-6 mt-3">
                <h4 className="h6 fw-normal">Máximo de uso</h4>
                <Input
                  placeholder="0"
                  name="maxUse"
                  type="number"
                  className="input height"
                />
              </div>

              <div className="col-lg-6 mt-3">
                <h4 className="h6 fw-normal">Tipo de desconto</h4>
                <Select
                  name="discountType"
                  className="input height"
                  options={[
                    {
                      id: 'percent',
                      value: 'Percentual',
                      selected: discountType === 'percent',
                    },
                    {
                      id: 'value',
                      value: 'Valor',
                      selected: discountType === 'value',
                    },
                  ]}
                  onChange={handleChangeDiscountType}
                />
              </div>

              <div className="col-lg-6 mt-3">
                <h4 className="h6 fw-normal">Valor de desconto</h4>
                {discountType === 'percent' ? (
                  <Input
                    placeholder="0"
                    name="discountValue"
                    type="number"
                    className="input height"
                  />
                ) : (
                  <InputMask
                    kind="money"
                    placeholder="0"
                    name="discountValue"
                    className="input height"
                  />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 mt-5 py-4 px-5 d-flex justify-content-end">
            <button
              type="submit"
              className="ms-0 btn-filter d-flex align-items-center justify-content-center btn h6 fw-normal py-2 px-4 mt-2 mb-0"
            >
              Adicionar Cupom
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        className="zoom modal-filter"
        size="lg"
        centered
        show={showUpdate}
        onHide={handleClose}
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmitUpdate}
          initialData={voucherSelected}
        >
          <Modal.Header className="justify-content-between align-items-center border-0 px-3 px-sm-5 pb-0 pt-0">
            <Modal.Title className="mb-0 mt-4">
              <h2 className="title mb-0">Editar Cupom</h2>
            </Modal.Title>
            <button
              type="button"
              onClick={handleClose}
              className="border-0 bg-transparent"
            >
              <KrClose size={20} color="#989898" />
            </button>
          </Modal.Header>
          <Modal.Body className="container pt-0 px-3 px-sm-5">
            <div className="row">
              <div className="col-12">
                <hr className="mb-5" />

                <h4 className="h6 fw-normal">Nome (Código)</h4>
                <Input
                  placeholder="CUPOM20"
                  name="code"
                  type="text"
                  className="input height"
                />
              </div>

              <div className="col-12 mt-3">
                <h4 className="h6 fw-normal">Evento</h4>
                <Select
                  name="event"
                  className="input height"
                  options={events}
                />
              </div>

              <div className="col-lg-6 mt-3">
                <h4 className="h6 fw-normal">Validade</h4>
                <InputDate
                  className="height-date"
                  name="dueDate"
                  selected={endDate}
                  onChange={handleEndDate}
                />
              </div>

              <div className="col-lg-6 mt-3">
                <h4 className="h6 fw-normal">Máximo de uso</h4>
                <Input
                  placeholder="0"
                  name="maxUse"
                  type="number"
                  className="input height"
                />
              </div>

              <div className="col-lg-6 mt-3">
                <h4 className="h6 fw-normal">Tipo de desconto</h4>
                <Select
                  name="discountType"
                  className="input height"
                  options={[
                    {
                      id: 'percent',
                      value: 'Percentual',
                      selected: discountType === 'percent',
                    },
                    {
                      id: 'value',
                      value: 'Valor',
                      selected: discountType === 'value',
                    },
                  ]}
                  onChange={handleChangeDiscountType}
                />
              </div>

              <div className="col-lg-6 mt-3">
                <h4 className="h6 fw-normal">Valor de desconto</h4>
                {discountType === 'percent' ? (
                  <Input
                    placeholder="0"
                    name="discountValue"
                    type="number"
                    className="input height"
                  />
                ) : (
                  <InputMask
                    kind="money"
                    placeholder="0"
                    name="discountValue"
                    className="input height"
                    value={voucherSelected.discountValue?.toString(2)}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 mt-5 py-4 px-5 d-flex justify-content-end">
            <button
              type="submit"
              className="ms-0 btn-filter d-flex align-items-center justify-content-center btn h6 fw-normal py-2 px-4 mt-2 mb-0"
            >
              Adicionar Cupom
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Vouchers;
