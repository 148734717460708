import styled from 'styled-components';

interface IBar {
  width: number;
}

interface IPointer {
  rotate: number;
}

export const Container = styled.div`
  min-width: calc(100% - 88px);

  h1 {
    font-size: 26px;
    font-weight: 500;
  }

  hr {
    background: #e1e1e1;
    opacity: 1;
  }

  a {
    :hover {
      color: unset;
    }
  }

  .container-fluid {
    max-width: 1460px;
  }

  .bg-white {
    border-radius: 8px;
  }
`;

export const Transfer = styled.div`
  font-family: 'Roboto';

  path {
    filter: unset;
  }

  .values {
    p:nth-child(1) {
      color: #8c8c8c;
    }
    p:nth-child(2) {
      color: #292623;
      span {
        min-width: 208px;
      }
    }
    p:nth-child(3) {
      span {
        border-radius: 4px;
        background: #ff6900;
      }
    }
    p:nth-child(4) {
      color: #5a5a5a;
    }
    p:nth-child(5) {
      span {
        border-radius: 4px;
        background: #ff842f;
      }
    }
    p:nth-child(6) {
      color: #5a5a5a;
    }
    p:nth-child(7) {
      span {
        border-radius: 4px;
        background: #ffb580;
      }
    }
    p:nth-child(8) {
      color: #5a5a5a;
    }
  }
`;

export const Entries = styled.div`
  font-family: 'Roboto';

  .btn-full {
    padding: 0px 3px;
    border-radius: 2px;
    border: 1px solid #838383;
  }
`;

export const Lots = styled.div`
  font-family: 'Roboto';

  .bd-top {
    border-top: 1px solid #e1e1e1;
  }
  p {
    color: #878787;
    font-size: 14px;
    font-weight: 400;
  }

  .fw-regular {
    font-weight: 500;
  }
`;

export const Bar = styled.div`
  background-color: #ececf4;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 8px;
`;

export const Progress = styled.div<IBar>`
  height: 4px;
  border-radius: 4px;
  background-color: #00a3e0;
  width: ${(props) => props.width}%;
  max-width: 100%;
`;

export const Score = styled.div<IPointer>`
  font-family: 'Roboto';

  p {
    color: #525252;
  }

  .emogi {
    width: 103px;
    height: 103px;
  }

  .scale {
    width: 91px;
    height: 45px;
  }

  .divPointer {
    position: absolute;
    width: 26px;
    top: 27px;
    left: calc(50% - 13px);
    transform: rotate(${(props) => props.rotate}deg);
  }

  .pointer {
    //width: 26px;
    height: 16px;
  }
`;
