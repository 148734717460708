import React, { FC, ComponentType, useMemo } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouterProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/Auth';

import Auth from '~/pages/_Layouts/Auth';
import Default from '~/pages/_Layouts/Default';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  both?: boolean;
  component: ComponentType;
}

const Route: FC<RouteProps> = ({
  isPrivate = false,
  both = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  const Layout = useMemo(() => {
    if (both) {
      return user ? Default : Auth;
    }

    return isPrivate ? Default : Auth;
  }, [both, isPrivate, user]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return both || isPrivate === !!user ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? `/` : `/inicio`,
              state: location,
            }}
          />
        );
      }}
    />
  );
};

export default Route;
