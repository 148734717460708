/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
} from 'react';
import { BsFilter } from 'react-icons/bs';
import { HiOutlineSearch } from 'react-icons/hi';
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';
import { BiPlus } from 'react-icons/bi';
import { FaFileExcel } from 'react-icons/fa';

import { useLocation } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import {
  Container,
  FilterGroup,
  FilterBox,
  Input,
  TableContent,
  Header,
  Button,
  Body,
  Title,
  Row,
  Pagination,
  NoData,
  Loading,
} from './styles';
import { useResize } from '~/hooks/Resize';
import { KrInfo } from '../KoroIcons';

interface IData {
  [key: string]: any;
}

export interface IColumn<T = any> {
  id?: string | number;
  name: string | number | React.ReactNode;
  selector?: string | ((row: T, rowIndex: number) => React.ReactNode);
  sortable?: boolean;
  order?: boolean;
  cell?: (row: T, rowIndex: number) => React.ReactNode;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  right?: boolean;
  center?: boolean;
  ignoreRowClick?: boolean;
  toolTip?: string;
}

interface ITableProps {
  title?: string;
  addButton?: boolean;
  columns: IColumn[];
  data: IData[];
  searchable?: boolean;
  pagination?: boolean;
  selectedPage?: number;
  totalData?: number;
  className?: string;
  showFilter?: boolean;
  filters?: React.ReactNode | React.ReactNode[];
  loading?: boolean;
  exportToExcel?: boolean;
  onChangeOrder?(column: string, order: 'ASC' | 'DESC'): void;
  onFilterClick?(): void;
  onClickAdd?(): void;
  onRowClicked?(e: IData): void;
  onRowDoubleClick?(e: IData): void;
  onSearch?(e: string): void;
  onChangePage?(e: number): void;
}

const Table: React.FC<ITableProps> = ({
  title,
  addButton,
  columns,
  data,
  searchable,
  pagination,
  selectedPage,
  totalData,
  className,
  showFilter,
  filters,
  loading,
  exportToExcel,
  onChangeOrder,
  onFilterClick,
  onClickAdd,
  onRowClicked,
  onRowDoubleClick,
  onSearch,
  onChangePage,
}) => {
  const location = useLocation();
  const { width } = useResize();
  const contentRef = useRef<HTMLDivElement>(null);
  const [columnsData, setColumnsData] = useState<IColumn[]>([]);
  const [noDataWidth, setNoDataWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useLayoutEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener('scroll', (e) => {
        const element = e.target as HTMLDivElement;
        setScrollLeft(element.scrollLeft);
      });
      setNoDataWidth(contentRef.current.clientWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current]);

  useEffect(() => {
    setColumnsData(columns);
  }, [columns]);

  const totalPages = useMemo(() => {
    if (totalData) {
      const pagesData = Math.ceil(totalData / 10);
      return pagesData;
    }

    return 1;
  }, [totalData]);

  useEffect(() => {
    const pagesArr: number[] = [];
    for (let i = 1; i <= totalPages; i += 1) {
      pagesArr.push(i);
    }
  }, [totalPages]);

  const pages = useMemo(() => {
    const pageSelected = selectedPage || 1;
    const qtd = width > 992 ? 7 : 5;
    const pageArray = Array.from(
      { length: totalPages < qtd ? totalPages : qtd },
      (e, index) => (totalPages < qtd ? `${index + 1}`.padStart(2, '0') : e)
    );

    const pageData = pageArray.map((page, index) => {
      if (page) {
        return page;
      }

      if (width > 992) {
        if (index === 0) {
          return '01';
        }
        if (index === 1) {
          if (pageSelected > 3 && pageSelected - 3 !== 1) {
            return '...';
          }
          return (2).toString().padStart(2, '0');
        }
        if (index === 2) {
          if (pageSelected > 3) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 4).toString().padStart(2, '0');
            }
            return (pageSelected - 1).toString().padStart(2, '0');
          }
          return (3).toString().padStart(2, '0');
        }
        if (index === 3) {
          if (pageSelected > 4) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 3).toString().padStart(2, '0');
            }
            return pageSelected.toString().padStart(2, '0');
          }
          return (4).toString().padStart(2, '0');
        }
        if (index === 4) {
          if (pageSelected >= 5) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 2).toString().padStart(2, '0');
            }
            return (pageSelected + 1).toString().padStart(2, '0');
          }
          return (5).toString().padStart(2, '0');
        }
        if (index === 5) {
          if (pageSelected + 3 >= totalPages) {
            return (totalPages - 1).toString().padStart(2, '0');
          }
          return '...';
        }
        if (index === 6) {
          return totalPages.toString().padStart(2, '0');
        }
      } else {
        if (index === 0) {
          return (1).toString().padStart(2, '0');
        }
        if (index === 1) {
          if (pageSelected > 3) {
            return '...';
          }
          return (2).toString().padStart(2, '0');
        }
        if (index === 2) {
          if (pageSelected > 3 && pageSelected + 2 < totalPages) {
            return pageSelected.toString().padStart(2, '0');
          }
          if (pageSelected + 2 >= totalPages) {
            return (totalPages - 2).toString().padStart(2, '0');
          }
          return (3).toString().padStart(2, '0');
        }
        if (index === 3) {
          if (pageSelected + 2 >= totalPages) {
            return (totalPages - 1).toString().padStart(2, '0');
          }
          return '...';
        }
        if (index === 4) {
          return totalPages.toString().padStart(2, '0');
        }
      }

      return '';
    });

    return pageData as string[];
  }, [selectedPage, totalPages, width]);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      if (onSearch) {
        onSearch(value);
      }
    },
    [onSearch]
  );

  const handleClickRow = useCallback(
    (row) => {
      if (onRowClicked) {
        onRowClicked(row);
      }
    },
    [onRowClicked]
  );

  const handleDoubleClickRow = useCallback(
    (row) => {
      if (onRowDoubleClick) {
        onRowDoubleClick(row);
      }
    },
    [onRowDoubleClick]
  );

  const orderColumn = useCallback(
    (column, order) => {
      data.sort((a, b) => {
        if (!order) {
          if (a[column] > b[column]) {
            return -1;
          }
          if (a[column] < b[column]) {
            return 1;
          }
          return 0;
        }

        if (a[column] < b[column]) {
          return -1;
        }
        if (a[column] > b[column]) {
          return 1;
        }
        return 0;
      });
    },
    [data]
  );

  const handleSort = useCallback(
    (column) => {
      if (column.sortable) {
        const newColumnsData = columnsData.map((columnData) => {
          const newColumnData = columnData;
          if (columnData.selector === column.selector) {
            newColumnData.order = !column.order;
          } else {
            newColumnData.order = false;
          }

          return newColumnData;
        });
        if (onChangeOrder) {
          onChangeOrder(column.selector, !column.order ? 'ASC' : 'DESC');
        } else {
          orderColumn(column.selector, !column.order);
        }
        setColumnsData(newColumnsData);
      }
    },
    [columnsData, onChangeOrder, orderColumn]
  );

  const handleClickAdd = useCallback(() => {
    if (onClickAdd) {
      onClickAdd();
    }
  }, [onClickAdd]);

  const handleClickFilter = useCallback(() => {
    if (onFilterClick) {
      onFilterClick();
    }
  }, [onFilterClick]);

  const handleClickPrev = useCallback(() => {
    if (onChangePage && selectedPage) {
      onChangePage(selectedPage - 1 <= 1 ? 1 : selectedPage - 1);
    }
  }, [onChangePage, selectedPage]);

  const handleClickPage = useCallback(
    (page) => {
      if (onChangePage) {
        onChangePage(page);
      }
    },
    [onChangePage]
  );

  const handleClickNext = useCallback(() => {
    if (onChangePage && selectedPage) {
      onChangePage(
        selectedPage + 1 >= totalPages ? totalPages : selectedPage + 1
      );
    }
  }, [onChangePage, selectedPage, totalPages]);

  return (
    <>
      <Container className={className}>
        {(title || addButton || searchable) && (
          <div
            className={`d-flex flex-wrap ${
              addButton ? 'justify-content-between' : 'justify-content-end'
            } align-items-center mb-5`}
          >
            {title && <h1 className="w-100 w-sm-auto mb-3 mb-sm-0">{title}</h1>}
            {addButton && (
              <button
                type="button"
                className="add-button py-2 px-4 d-flex justify-content-center align-items-center"
                onClick={handleClickAdd}
              >
                <BiPlus size={20} color="#fff" /> Adicionar
              </button>
            )}
            <div className="w-100 w-sm-auto d-flex align-items-center">
              {searchable && (
                <Input className="d-flex me-3" onChange={handleChange}>
                  <input type="text" placeholder="Buscar" />
                  <HiOutlineSearch size={24} color="#2A8BFD" />
                </Input>
              )}
              {onFilterClick && (
                <FilterGroup>
                  <button
                    type="button"
                    className="btn-action border-0"
                    onClick={handleClickFilter}
                  >
                    <BsFilter size={24} color="#414141" />
                  </button>
                  <FilterBox show={showFilter}>{filters}</FilterBox>
                </FilterGroup>
              )}
              {exportToExcel && (
                <button type="button" className="btn-action border-0">
                  <FaFileExcel size={24} color="#6EB968" />
                </button>
              )}
            </div>
          </div>
        )}
        <TableContent>
          <div ref={contentRef} className="content">
            <Header className="d-none d-lg-block tb-header">
              <div className="tb-row">
                {columnsData.map((column, index) => (
                  <Button
                    key={column.selector as React.Key}
                    type="button"
                    sorting={column.sortable}
                    onClick={() => handleSort(column)}
                    order={column.order}
                    disabled={!column.sortable}
                    className={`${
                      location.pathname === '/lista-usuarios' && index > 0
                        ? 'w-100'
                        : 'number-id'
                    }`}
                  >
                    {column.name}{' '}
                    {column.sortable && (
                      <IoIosArrowDown
                        size={15}
                        color="#7C7C7C"
                        className="ms-2"
                      />
                    )}
                    {column.toolTip && (
                      <span
                        className="tooltip-total me-auto"
                        data-tooltip={column.toolTip}
                      >
                        <KrInfo color="#7C7C7C" size={15} className="ms-2" />
                      </span>
                    )}
                  </Button>
                ))}
              </div>
            </Header>
            <Body className="tb-header-mobile tb-body">
              {data.length > 0 ? (
                <>
                  {data.map((rowData, index) => (
                    <Row
                      key={index.toString()}
                      className={`tb-row w-100 ${
                        onRowClicked || onRowDoubleClick ? 'tb-row-hover' : ''
                      }`}
                      onClick={() => handleClickRow(rowData)}
                      onDoubleClick={() => handleDoubleClickRow(rowData)}
                    >
                      {columnsData.map((column, colIndex) => (
                        <div
                          key={column.selector as React.Key}
                          className={`${
                            location.pathname === '/lista-usuarios' &&
                            colIndex > 0
                              ? 'w-100'
                              : 'number-id'
                          }`}
                        >
                          <Title className="d-block d-lg-none">
                            {column.name}
                          </Title>
                          {column.cell ? (
                            <>{column.cell(rowData, index)}</>
                          ) : (
                            <>{rowData[column.selector as string]}</>
                          )}
                        </div>
                      ))}
                    </Row>
                  ))}
                </>
              ) : (
                <NoData width={noDataWidth} scrollLeft={scrollLeft}>
                  <p className="h4 text-center d-block mb-0">Nenhum registro</p>
                </NoData>
              )}
            </Body>
          </div>
          <Loading active={loading}>
            <div className="loading-box d-flex flex-column align-items-center justify-content-center">
              <div className="spinner-border text-light" role="status" />
              <span className="d-block fw-bold">Carregando...</span>
            </div>
          </Loading>
        </TableContent>
        {pagination && (
          <Pagination className="justify-content-center pt-2 mb-4 mt-3">
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <div className="w-100 w-sm-auto d-flex justify-content-center mt-3 mt-sm-0">
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-pagination arrow"
                    onClick={handleClickPrev}
                  >
                    <MdOutlineArrowBackIos size={24} color="#a0a0a0" />
                  </button>
                  {pages.map((page, index) => (
                    <button
                      key={index.toString()}
                      type="button"
                      className={`btn btn-pagination ${
                        page === selectedPage?.toString().padStart(2, '0')
                          ? 'selected'
                          : ''
                      } ${page === '...' ? 'ellipsis' : ''}`}
                      onClick={() => handleClickPage(parseInt(page, 10))}
                      disabled={page === '...'}
                    >
                      {page}
                    </button>
                  ))}
                  <button
                    type="button"
                    className="btn btn-pagination arrow"
                    onClick={handleClickNext}
                  >
                    <MdOutlineArrowForwardIos size={24} color="#a0a0a0" />
                  </button>
                </div>
              </div>
            </div>
          </Pagination>
        )}
      </Container>
    </>
  );
};

export default Table;
