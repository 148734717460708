/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { addMonths, parseISO } from 'date-fns';

import { FaRegTrashCan } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { Container } from './styles';
import Input from '~/components/Input';
import InputDate from '~/components/InputDate';
import { IBatchCategory } from '../PricesPlaces';
import Toast from '~/utils/toast';
import { KrTrash } from '~/components/KoroIcons';

export interface IBatch {
  input_id: number;
  qtdPlaces?: number;
  expiration: Date;
  restPlaces?: number;
  batchesCategories?: IBatchCategory[];
}

interface IBatches {
  className: string;
  onChange?(data: IBatch[]): void;
  initialData?: IBatch[];
}

const Batches: React.FC<IBatches> = ({ className, onChange, initialData }) => {
  const [loaded, setLoaded] = useState(false);
  const [batches, setBatches] = useState<IBatch[]>([
    {
      input_id: new Date().getTime(),
      expiration: addMonths(new Date(), 1),
    },
  ]);

  useEffect(() => {
    if (initialData && initialData.length > 0 && !loaded) {
      const data = JSON.parse(JSON.stringify(initialData)).map(
        (batch: any) => ({
          ...batch,
          expiration: parseISO(batch.expiration),
        })
      );
      setBatches(data);
      setLoaded(true);
    }
  }, [initialData, loaded]);

  useEffect(() => {
    if (onChange) {
      onChange(batches);
    }
  }, [batches, onChange]);

  const handleAddBatch = useCallback(() => {
    setBatches((oldState) => [
      ...oldState,
      {
        input_id: new Date().getTime(),
        expiration: addMonths(new Date(), oldState.length + 1),
      },
    ]);
  }, []);

  const handleRemoveBatch = useCallback((input_id) => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#ff6900',
      title: 'Gostaria de excluir o lote?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#ff6900',
      cancelButtonColor: '#B34A00',
      reverseButtons: true,
    }).then((e) => {
      if (e.isConfirmed) {
        Toast.fire({
          icon: 'success',
          iconColor: '#ff6900',
          title: 'Lote excluído com sucesso!',
        });
        setBatches((oldState) => {
          if (oldState.length === 1) {
            return [
              {
                input_id: new Date().getTime(),
                expiration: addMonths(new Date(), 1),
              },
            ];
          }
          return oldState.filter((batch) => batch.input_id !== input_id);
        });
      }
    });
  }, []);

  const handleChangeQtdPlaces = useCallback(
    (e, batchIndex) => {
      const newBatchesData = batches.slice();
      newBatchesData[batchIndex].qtdPlaces = e.target.value;
      newBatchesData[batchIndex].restPlaces = e.target.value;
      setBatches(newBatchesData);
    },
    [batches]
  );

  const handleChangeExpiration = useCallback(
    (value, batchIndex) => {
      const newBatchesData = batches.slice();
      newBatchesData[batchIndex].expiration = value;
      setBatches(newBatchesData);
    },
    [batches]
  );

  return (
    <Container className={className}>
      <div className="col-12">
        <h2 className="h4 fw-semibold text-center mt-5 mb-4">6 - Lotes</h2>
        <p className="mb-4">
          Preencha a quantidade de vagas por lote e a data de vencimento, para
          seguir para o próximo passo.
        </p>
      </div>

      <div className="col-12">
        {batches.map((batch, index) => (
          <div className="bd-lots mb-3 p-3">
            <div className="d-flex">
              <button
                type="button"
                onClick={() => handleRemoveBatch(batch.input_id)}
                className={`${
                  batches.length === 1 ? 'opacity-0 pe-none' : 'opacity-1'
                } border-0 ms-auto ps-3 bg-transparent`}
              >
                <KrTrash size={14} color="#B41717" className="svg-card" />
              </button>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="w-h3 h5 mb-0 fw-semibold roboto d-flex align-items-center">
                Lote {index + 1}
              </h3>
              <div>
                <span className="mx-auto w-75 roboto h6 fw-normal mb-2 d-block">
                  Qtd. de vagas
                </span>

                <Input
                  type="number"
                  name="qtd-places"
                  placeholder="0"
                  value={batch.qtdPlaces}
                  onChange={(e) => handleChangeQtdPlaces(e, index)}
                  className="mx-auto w-75 input"
                  onWheel={(e: any) => e.target.blur()}
                />
              </div>
              <div className="h6 fw-semibold roboto or">e</div>
              <div>
                <span className="roboto h6 fw-normal mb-2 d-block">
                  Vencimento
                </span>
                <InputDate
                  className="py-1"
                  name="date-lot"
                  selected={batch.expiration}
                  onChange={(e) => handleChangeExpiration(e, index)}
                  colorSvg="#5D5D5D"
                />
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          className="d-flex mt-4 btn-add-lot w-100 bg-transparent align-items-center p-2"
          onClick={handleAddBatch}
        >
          <HiPlus size={16} color="#D4D4D4" className="ms-2 " />
          Adicionar novo lote
        </button>
      </div>
    </Container>
  );
};

export default Batches;
