import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { MdEdit, MdUpload } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Modal, ColorBox } from './styles';
import Input from '~/components/Input';
import InputCheckbox from '../InputCheckbox';
import { KrClose, KrEdit } from '../KoroIcons';

export interface IColor {
  id: number;
  hex: string;
  title: string;
  files?: File[];
  checkVariationSize: boolean;
  qtyPp?: string;
  qtyP?: string;
  qtyM?: string;
  qtyG?: string;
  qtyGg?: string;
  qtySizeError?: string;
}

interface IProductColors {
  color: IColor;
  onChangeColor(color: IColor): void;
}

interface IFormData {
  name: string;
  color: string;
}

const ProductColors: React.FC<IProductColors> = ({ color, onChangeColor }) => {
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const filesData = event.target.files;
      if (filesData) {
        onChangeColor({
          ...color,
          files: color.files
            ? [...color.files, ...Array.from(filesData)]
            : Array.from(filesData),
        });

        const inputElement = document.getElementById(
          `files-${color.id}`
        ) as HTMLInputElement;

        if (inputElement) {
          inputElement.value = '';
        }
      }
    },
    [color, onChangeColor]
  );

  const handleDropColor = useCallback(
    (event: React.DragEvent<HTMLLabelElement>) => {
      event.preventDefault();
      const filesData = event.dataTransfer.files;
      if (filesData) {
        onChangeColor({
          ...color,
          files: color.files
            ? [...color.files, ...Array.from(filesData)]
            : Array.from(filesData),
        });
      }
    },
    [color, onChangeColor]
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLLabelElement>) => {
      event.preventDefault();
    },
    []
  );

  const handleDeleteFile = useCallback(
    (indexToDelete) => {
      onChangeColor({
        ...color,
        files: color.files?.filter((_, index) => index !== indexToDelete),
      });
    },
    [color, onChangeColor]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome da cor é obrigatório'),
          color: Yup.string().required('A cor é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        onChangeColor({
          ...color,
          hex: data.color,
          title: data.name,
        });

        setShow(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [color, onChangeColor]
  );

  const handleChangeSizeVariation = useCallback(() => {
    onChangeColor({
      ...color,
      checkVariationSize: !color.checkVariationSize,
    });
  }, [color, onChangeColor]);

  const handleChangeQtySizePp = useCallback(
    (e) => {
      onChangeColor({
        ...color,
        qtyPp: e.target.value,
      });
    },
    [color, onChangeColor]
  );

  const handleChangeQtySizeP = useCallback(
    (e) => {
      onChangeColor({
        ...color,
        qtyP: e.target.value,
      });
    },
    [color, onChangeColor]
  );

  const handleChangeQtySizeM = useCallback(
    (e) => {
      onChangeColor({
        ...color,
        qtyM: e.target.value,
      });
    },
    [color, onChangeColor]
  );

  const handleChangeQtySizeG = useCallback(
    (e) => {
      onChangeColor({
        ...color,
        qtyG: e.target.value,
      });
    },
    [color, onChangeColor]
  );

  const handleChangeQtySizeGg = useCallback(
    (e) => {
      onChangeColor({
        ...color,
        qtyGg: e.target.value,
      });
    },
    [color, onChangeColor]
  );

  return (
    <Container>
      <div className="d-flex bd-color p-3 align-items-center justify-content-between">
        <div className="d-flex">
          <ColorBox color={color.hex} className="me-2" />
          {color.title}
        </div>
        <button
          type="button"
          className="border-0 bg-transparent"
          onClick={() => setShow(true)}
        >
          <KrEdit size={16} color="#8c8c8c" />
        </button>
      </div>
      <div>
        <label
          htmlFor={`files-${color.id}`}
          className="w-100 label-modal text-center mt-3"
          onDrop={handleDropColor}
          onDragOver={handleDragOver}
        >
          <MdUpload size={48} color="#FF6900" />
          <span className="d-block">
            Arraste e solte o arquivo aqui para
            <br />
            adicionar uma imagem
          </span>
          <span className="d-inline-flex my-2">Selecionar Imagem</span>
          <span className="d-block">Extensões aceitas: jpg, png, jpeg.</span>
        </label>
        <Input
          type="file"
          name={`files_${color.id}`}
          className="d-none"
          onChange={handleFileChange}
          id={`files-${color.id}`}
        />
        {color.files?.map((file, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="d-flex justify-content-between archives p-3 mt-3"
          >
            <div className="d-flex align-items-center">
              <img src={URL.createObjectURL(file)} alt={file.name} />
              <p className="ms-3 mb-0">{file.name}</p>
            </div>
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={() => handleDeleteFile(index)}
            >
              <IoMdClose size={24} color="#8C8C8C" />
            </button>
          </div>
        ))}
      </div>
      <div className="col-12">
        <InputCheckbox
          type="checkbox"
          name={`size_variation_${color.id}`}
          className="mt-3 d-block"
          options={[
            {
              label: 'Possui variação de tamanho?',
              value: 'Possui variação de tamanho?',
              selected: color.checkVariationSize,
            },
          ]}
          onChange={handleChangeSizeVariation}
        />
      </div>
      {color.checkVariationSize ? (
        <div className="col-12">
          <div className="variations text-center my-3">
            <div className="d-flex align-items-center variation-row">
              <div>
                <p className="mb-0 h6">Tam.</p>
              </div>
              <div>
                <p className="mb-0 h6 text-start">Qtd. Disponível</p>
              </div>
            </div>
            <div className="d-flex align-items-center variation-row">
              <div>
                <p className="mb-0 size">PP</p>
              </div>
              <div>
                <Input
                  name={`qty_pp_${color.id}`}
                  type="number"
                  className="input"
                  onChange={handleChangeQtySizePp}
                />
              </div>
            </div>
            <div className="d-flex align-items-center variation-row">
              <div>
                <p className="mb-0 size">P</p>
              </div>
              <div>
                <Input
                  name={`qty_p_${color.id}`}
                  type="number"
                  className="input"
                  onChange={handleChangeQtySizeP}
                />
              </div>
            </div>
            <div className="d-flex align-items-center variation-row">
              <div>
                <p className="mb-0 size">M</p>
              </div>
              <div>
                <Input
                  name={`qty_m_${color.id}`}
                  type="number"
                  className="input"
                  onChange={handleChangeQtySizeM}
                />
              </div>
            </div>
            <div className="d-flex align-items-center variation-row">
              <div>
                <p className="mb-0 size">G</p>
              </div>
              <div>
                <Input
                  name={`qty_g_${color.id}`}
                  type="number"
                  className="input"
                  onChange={handleChangeQtySizeG}
                />
              </div>
            </div>
            <div className="d-flex align-items-center variation-row">
              <div>
                <p className="mb-0 size">GG</p>
              </div>
              <div>
                <Input
                  name={`qty_gg_${color.id}`}
                  type="number"
                  className="input"
                  onChange={handleChangeQtySizeGg}
                />
              </div>
            </div>
          </div>
          {color.qtySizeError && (
            <small className="error text-danger">{color.qtySizeError}</small>
          )}
        </div>
      ) : (
        <div className="col-12">
          <label className="d-block mt-3">
            <span className="h6 fw-normal">Qtd. Disponível</span>
            <Input
              name={`qty_${color.id}`}
              type="number"
              className="input mt-2"
            />
          </label>
        </div>
      )}
      <Modal
        className="modal-filter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="justify-content-between align-items-center border-0 px-3 px-sm-5 pb-0 pt-0">
            <Modal.Title className="mb-0 mt-4">
              <h2 className="title mb-0">Editar cor</h2>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setShow(false)}
              className="border-0 bg-transparent"
            >
              <KrClose size={20} color="#989898" />
            </button>
          </Modal.Header>
          <Modal.Body className="pt-0 px-3 px-sm-5">
            <div className="d-flex">
              <div className="w-lg-75 px-lg-2 mt-3">
                <h4 className="h6 fw-normal">Nome da cor</h4>
                <Input
                  name="name"
                  type="text"
                  className="input"
                  defaultValue={color.title}
                />
              </div>
              <div className="w-lg-25 px-lg-2 mt-3">
                <h4 className="h6 fw-normal">Cor</h4>
                <Input
                  type="color"
                  name="color"
                  className="input"
                  defaultValue={color.hex}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4 px-5">
            <button
              type="button"
              className="me-auto btn-filter d-flex align-items-center justify-content-center btn h6 fw-normal py-2 px-4 mt-2 mb-0"
              onClick={() => formRef.current?.submitForm()}
            >
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default ProductColors;
