import React from 'react';
import { IProps } from './interfaces';

const KrFinancial: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2692)">
        <path
          d="M16.05 4.35999C9.65001 4.35999 4.45001 9.55999 4.45001 15.96C4.45001 22.36 9.65001 27.56 16.05 27.56C22.45 27.56 27.65 22.36 27.65 15.96C27.65 9.55999 22.45 4.35999 16.05 4.35999ZM16.05 26.36C10.31 26.36 5.65001 21.69 5.65001 15.96C5.65001 10.23 10.32 5.55999 16.05 5.55999C21.78 5.55999 26.45 10.23 26.45 15.96C26.45 21.69 21.78 26.36 16.05 26.36Z"
          fill={color}
        />
        <path
          d="M14.53 12.94H18.54C18.87 12.94 19.14 12.67 19.14 12.34C19.14 12.01 18.87 11.74 18.54 11.74H16.65V10.53C16.65 10.2 16.38 9.92999 16.05 9.92999C15.72 9.92999 15.45 10.2 15.45 10.53V11.74H14.53C13.62 11.74 12.89 12.48 12.89 13.38V14.91C12.89 15.82 13.63 16.55 14.53 16.55H17.49C17.74 16.55 17.93 16.75 17.93 16.99V18.97C17.93 19.22 17.73 19.42 17.49 19.42H13.48C13.15 19.42 12.88 19.69 12.88 20.02C12.88 20.35 13.15 20.62 13.48 20.62H15.7V21.83C15.7 22.16 15.97 22.43 16.3 22.43C16.63 22.43 16.9 22.16 16.9 21.83V20.62H17.49C18.4 20.62 19.13 19.88 19.13 18.98V17C19.13 16.09 18.39 15.36 17.49 15.36H14.53C14.28 15.36 14.09 15.16 14.09 14.92V13.39C14.09 13.15 14.29 12.95 14.53 12.95V12.94Z"
          fill={color}
        />
        <path
          d="M16.05 0.039978C7.27 0.039978 0.130005 7.17998 0.130005 15.96C0.130005 24.74 7.27 31.89 16.05 31.89C24.83 31.89 31.97 24.75 31.97 15.96C31.97 7.16998 24.83 0.039978 16.05 0.039978ZM16.05 30.08C8.26 30.08 1.93 23.74 1.93 15.96C1.93 8.17998 8.26 1.83998 16.05 1.83998C23.84 1.83998 30.17 8.17998 30.17 15.96C30.17 23.74 23.83 30.08 16.05 30.08Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2692">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrFinancial;
