import React from 'react';
import { IProps } from './interfaces';

const KrEvent: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2689)">
        <path
          d="M29.21 3.33001H26.89V2.13001C26.89 1.21001 26.14 0.450012 25.21 0.450012C24.28 0.450012 23.53 1.20001 23.53 2.13001V3.33001H8.45V2.13001C8.45 1.21001 7.7 0.450012 6.77 0.450012C5.84 0.450012 5.09 1.20001 5.09 2.13001V3.33001H2.77C1.3 3.33001 0.0899963 4.55001 0.0899963 6.03001V29.14C0.0899963 30.63 1.3 31.84 2.79 31.84H29.21C30.7 31.84 31.91 30.63 31.91 29.14V6.03001C31.91 4.54001 30.7 3.33001 29.21 3.33001ZM24.74 2.13001C24.74 1.87001 24.95 1.65001 25.22 1.65001C25.49 1.65001 25.7 1.87001 25.7 2.13001V3.33001H24.74V2.13001ZM6.3 2.13001C6.3 1.87001 6.52 1.65001 6.78 1.65001C7.04 1.65001 7.26 1.86001 7.26 2.13001V3.33001H6.3V2.13001ZM2.79 5.13001H29.21C29.71 5.13001 30.11 5.53001 30.11 6.03001V9.12001H1.89V6.03001C1.89 5.53001 2.29 5.13001 2.79 5.13001ZM29.21 30.04H2.79C2.29 30.04 1.89 29.64 1.89 29.14V10.33H30.11V29.15C30.11 29.65 29.71 30.05 29.21 30.05V30.04Z"
          fill={color}
        />
        <path
          d="M22.31 17.96H17.92L16.56 13.79C16.4 13.3 15.58 13.3 15.42 13.79L14.06 17.96H9.67C9.41 17.96 9.18 18.13 9.1 18.37C9.02 18.62 9.1 18.89 9.32 19.04L12.87 21.62L11.52 25.79C11.44 26.04 11.52 26.31 11.74 26.46C11.95 26.61 12.23 26.61 12.45 26.46L16 23.88L19.55 26.46C19.66 26.54 19.78 26.57 19.9 26.57C20.02 26.57 20.15 26.53 20.25 26.46C20.46 26.31 20.55 26.04 20.47 25.79L19.12 21.62L22.67 19.04C22.88 18.89 22.97 18.62 22.89 18.37C22.81 18.12 22.58 17.96 22.32 17.96H22.31ZM18.06 20.91C17.85 21.06 17.76 21.33 17.84 21.58L18.76 24.41L16.35 22.66C16.24 22.58 16.12 22.55 16 22.55C15.88 22.55 15.75 22.59 15.65 22.66L13.24 24.41L14.16 21.58C14.24 21.33 14.16 21.06 13.94 20.91L11.53 19.16H14.5C14.76 19.16 14.99 18.99 15.07 18.75L15.99 15.92L16.91 18.75C16.99 19 17.22 19.16 17.48 19.16H20.45L18.04 20.91H18.06Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2689">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrEvent;
