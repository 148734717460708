import React from 'react';
import { IProps } from './interfaces';

const KrSwimming: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2663)">
        <path
          d="M31.94 24.16C31.8 23.86 31.45 23.72 31.15 23.86L29.18 24.74C28.33 23.15 26.13 22.05 23.48 22.05C20.37 22.05 17.89 23.57 17.47 25.6H14.62C14.2 23.57 11.72 22.05 8.61001 22.05C6.03001 22.05 3.87001 23.1 2.98001 24.61L0.810006 23.84C0.500006 23.73 0.150006 23.89 0.0400057 24.21C-0.0699943 24.53 0.0900057 24.87 0.410006 24.98L2.58001 25.75C2.56001 25.9 2.54001 26.05 2.54001 26.21C2.54001 28.54 5.21001 30.36 8.62001 30.36C11.73 30.36 14.21 28.84 14.63 26.81H17.48C17.9 28.84 20.38 30.36 23.49 30.36C26.9 30.36 29.57 28.54 29.57 26.21C29.57 26.11 29.56 26.01 29.55 25.91L31.65 24.97C31.95 24.83 32.09 24.48 31.95 24.18L31.94 24.16ZM8.62001 28.54C6.10001 28.54 4.34001 27.3 4.34001 26.19C4.34001 25.08 6.10001 23.84 8.62001 23.84C11.14 23.84 12.89 25.08 12.89 26.19C12.89 27.3 11.14 28.54 8.62001 28.54ZM23.48 28.54C20.96 28.54 19.2 27.3 19.2 26.19C19.2 25.08 20.96 23.84 23.48 23.84C26 23.84 27.76 25.08 27.76 26.19C27.76 27.3 26 28.54 23.48 28.54Z"
          fill={color}
        />
        <path
          d="M3.41 20.49C6.29 19.86 11.61 18.84 15.73 18.93V18.95C15.82 18.95 15.9 18.95 15.99 18.94C16.08 18.94 16.16 18.94 16.25 18.95V18.93C20.36 18.84 25.68 19.85 28.57 20.49C28.76 20.53 28.96 20.55 29.15 20.55C29.85 20.55 30.53 20.27 31.06 19.75C31.8 19.03 32.12 17.99 31.91 16.96C29.23 3.14 19.18 1.75 16.36 1.64C16.36 1.64 16.21 1.63 15.99 1.64C15.77 1.64 15.63 1.64 15.62 1.64C12.8 1.75 2.76 3.14 0.0700046 16.96C-0.129995 17.99 0.190005 19.04 0.920005 19.75C1.59 20.4 2.52 20.68 3.41 20.48V20.49ZM29.77 18.44C29.64 18.57 29.35 18.77 28.97 18.69C26.96 18.25 23.8 17.64 20.63 17.31C21.27 14.95 24.49 10.98 26.49 8.75C28.04 10.75 29.36 13.51 30.1 17.32C30.18 17.74 30.06 18.16 29.77 18.44ZM15.69 3.48H16.3C17.85 3.51 22.27 3.97 25.82 7.93C24.1 9.83 20.28 14.29 19.58 17.21C18.62 17.13 17.67 17.08 16.76 17.08C16.5 17.08 16.24 17.08 15.98 17.09C14.86 17.06 13.65 17.1 12.42 17.2C11.53 14.18 7.65 9.72 6.08 8.01C9.66 3.98 14.13 3.51 15.69 3.48ZM1.88 17.32C2.61 13.57 3.9 10.83 5.42 8.84C7.32 10.94 10.55 14.85 11.38 17.31C8.2 17.63 5.02 18.25 3.01 18.69C2.62 18.77 2.34 18.57 2.21 18.44C1.92 18.16 1.8 17.74 1.88 17.32Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2663">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrSwimming;
