import React from 'react';
import { IProps } from './interfaces';

const KrAverageTicket: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2713)">
        <path
          d="M9.15 31.22C8.93 31.22 8.7 31.19 8.48 31.13C7.83 30.95 7.29 30.54 6.96 29.96L5.76 27.85C5.24 26.93 5.34 25.81 6.03 24.98C6.61 24.29 6.69 23.29 6.24 22.5C5.79 21.71 4.88 21.28 4 21.42C2.93 21.59 1.91 21.11 1.38 20.2L0.18 18.09C-0.51 16.89 -0.0899998 15.36 1.11 14.67L11.22 8.90002C11.22 8.90002 11.26 8.88003 11.28 8.87003C11.3 8.86002 11.32 8.84003 11.34 8.83003L21.45 3.06003C22.03 2.73002 22.7 2.64003 23.35 2.82003C24 3.00003 24.54 3.41003 24.87 3.99003L26.07 6.10002C26.59 7.02002 26.48 8.14003 25.8 8.97003C25.22 9.66003 25.14 10.66 25.59 11.45C26.04 12.24 26.95 12.67 27.83 12.53C28.9 12.35 29.92 12.84 30.44 13.75L31.64 15.86C32.33 17.06 31.91 18.59 30.71 19.28L10.38 30.89C10 31.11 9.57 31.22 9.14 31.22H9.15ZM4.35 19.6C5.76 19.6 7.1 20.36 7.81 21.61C8.63 23.05 8.47 24.86 7.42 26.13C7.21 26.38 7.18 26.7 7.32 26.96L8.52 29.07C8.61 29.23 8.76 29.35 8.95 29.4C9.13 29.45 9.32 29.43 9.49 29.33L29.82 17.72C30.16 17.53 30.28 17.1 30.08 16.76L28.88 14.65C28.73 14.39 28.43 14.26 28.12 14.31C26.5 14.57 24.85 13.79 24.03 12.35C23.21 10.91 23.37 9.09002 24.42 7.83003C24.63 7.58003 24.66 7.26003 24.51 7.00003L23.31 4.89002C23.12 4.55002 22.68 4.43003 22.35 4.63003L12.24 10.4C12.24 10.4 12.2 10.42 12.18 10.43C12.16 10.44 12.14 10.46 12.12 10.47L2.01 16.24C1.67 16.43 1.55 16.86 1.75 17.2L2.95 19.31C3.1 19.57 3.4 19.7 3.71 19.65C3.92 19.62 4.13 19.6 4.34 19.6H4.35Z"
          fill={color}
        />
        <path
          d="M7.13 14.93C6.92 14.93 6.72 14.82 6.61 14.63L5.95 13.47C5.78 13.18 5.89 12.82 6.17 12.65C6.46 12.48 6.83 12.59 6.99 12.87L7.65 14.03C7.82 14.32 7.71 14.68 7.43 14.85C7.34 14.9 7.23 14.93 7.13 14.93Z"
          fill={color}
        />
        <path
          d="M12.34 24.05C12.13 24.05 11.93 23.94 11.82 23.75L10.52 21.47C10.36 21.18 10.46 20.82 10.74 20.65C11.03 20.49 11.39 20.59 11.56 20.87L12.86 23.15C13.02 23.44 12.92 23.8 12.64 23.97C12.55 24.02 12.44 24.05 12.34 24.05ZM9.74 19.49C9.53 19.49 9.33 19.38 9.22 19.19L7.92 16.91C7.76 16.62 7.86 16.26 8.14 16.09C8.43 15.93 8.79 16.03 8.96 16.31L10.26 18.59C10.42 18.88 10.32 19.24 10.04 19.41C9.95 19.46 9.84 19.49 9.74 19.49Z"
          fill={color}
        />
        <path
          d="M14.31 27.49C14.1 27.49 13.9 27.38 13.79 27.19L13.13 26.03C12.97 25.74 13.07 25.38 13.35 25.21C13.64 25.05 14 25.15 14.17 25.43L14.83 26.59C14.99 26.88 14.89 27.24 14.61 27.41C14.52 27.46 14.41 27.49 14.31 27.49Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2713">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrAverageTicket;
