import React from 'react';
import { IProps } from './interfaces';

const KrExpand: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2742)">
        <path
          d="M18.943 1.34454C18.943 2.0915 19.5406 2.68908 20.2876 2.68908H27.4136L2.68908 27.4136V20.2876C2.68908 19.5406 2.0915 18.943 1.34454 18.943C0.597573 18.943 0 19.5406 0 20.2876V32H11.7124C12.4594 32 13.057 31.4024 13.057 30.6555C13.057 29.9085 12.4594 29.3109 11.7124 29.3109H4.58637L29.3109 4.58637V11.7124C29.3109 12.4594 29.9085 13.057 30.6555 13.057C31.4024 13.057 32 12.4594 32 11.7124V0H20.2876C19.5406 0 18.943 0.597573 18.943 1.34454Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2742">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrExpand;
