import React from 'react';
import { IPropsSecondary } from './interfaces';

const KrKoro: React.FC<IPropsSecondary> = ({
  size = 32,
  color = '#000',
  secondaryColor = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1989)">
        <path
          d="M1.97 13.59V30.78C1.97 31.46 2.52 32 3.19 32H9.39C10.07 32 10.61 31.45 10.61 30.78V19.65C11.23 19.71 11.85 19.71 12.4 19.71C13.08 19.71 13.76 19.71 14.38 19.65L20.89 31.38C21.11 31.77 21.52 32.01 21.96 32.01H28.81C29.76 32.01 30.34 30.98 29.87 30.17L22.3 17.25C27.24 14.22 29.1 8.85 29.1 3.66C29.1 2.64 29.07 1.89 29.02 1.16C28.96 0.5 28.43 0 27.79 0H21.93C21.23 0 20.68 0.59 20.71 1.28C20.75 2.18 20.75 2.82 20.75 3.52C20.75 5.84 20.13 7.75 19.04 9.2C17.49 11.28 15.01 12.41 12.1 12.41C11.72 12.41 5.82 12.37 3.2 12.36C2.52 12.36 1.97 12.9 1.97 13.58V13.59Z"
          fill={color}
        />
        <path
          d="M10.75 4.93C10.75 7.67 8.94 9.67 6.36 9.67C3.78 9.67 1.97 7.67 1.97 4.93C1.97 2.19 3.8 0.190002 6.36 0.190002C8.92 0.190002 10.75 2.16 10.75 4.93Z"
          fill={secondaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1989">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrKoro;
