import styled from 'styled-components';
import retanglesBottom from '~/assets/default/retangles-login.svg';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  //background-image: url(${retanglesBottom});
  background: linear-gradient(180deg, #ff9034 0%, #ff6900 100%);

  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  overflow-x: hidden;

  h2 {
    font-family: 'Roboto';
  }

  input {
    color: #fff;
  }

  .size {
    font-size: 12px;
  }

  .logo {
    width: 260px;
    height: 60px;
    margin: 75px 0;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #fff;
    color: #ff6900;
  }

  .ret-up {
    position: absolute;
    border-radius: 23px;
    border: 4px solid #fff;
    width: 291px;
    height: 146px;
  }

  .ret-left {
    left: -22px;
    bottom: 50px;
    width: 30%;
    height: 82%;
  }

  .ret-right {
    width: 81%;
    height: 200px;
    right: -28px;
    top: -24px;
  }

  svg:not(.g-icon) path {
    fill: #fff;
  }

  @media (min-width: 992px) and (min-height: 650px) {
    background: url(${retanglesBottom}),
      linear-gradient(180deg, #ff9034 0%, #ff6900 100%);
    background-position: bottom right;
    background-repeat: no-repeat;
  }
`;
