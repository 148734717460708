import React, { useEffect, useMemo } from 'react';
import { CSVLink } from 'react-csv';

interface IData {
  [key: string]: any;
}

interface IAllData {
  data: IData[];
  columns: IData[];
  fileName?: string;
}

const ExportCsv: React.FC<IAllData> = ({ data, columns, fileName }) => {
  useEffect(() => {
    console.log(data);
  }, [data]);

  const updatedData = useMemo(() => {
    const aux = data.map((item) => {
      const newItem: { [key: string]: string } = {};
      Object.entries(item).forEach(([key, value]) => {
        const columnSelected = columns.find(
          (column) => column.selector === key
        );
        if (columnSelected) {
          newItem[columnSelected.name] = value;
        }
      });
      return newItem;
    });

    return aux;
  }, [columns, data]);

  return (
    <CSVLink
      // type="button"
      className="mt-3 mt-sm-0 mb-0 me-lg-4 h6 fw-normal text-white btn-new px-5 py-2"
      data={updatedData}
      filename={fileName}
    >
      Exportar .CSV
    </CSVLink>
  );
};

export default ExportCsv;
