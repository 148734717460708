/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { MdUpload } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import { HiPlus } from 'react-icons/hi';

import { format, parseISO } from 'date-fns';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import getValidationErros from '~/utils/getValidationsErrors';

import { ColorBox, Container, Img, Modal } from './styles';
import Input from '~/components/Input';
import InputDate from '~/components/InputDate';
import InputMask from '~/components/InputMask';
import Textarea from '~/components/Textarea';
import InputCheckbox, {
  IOption as IOptionCheckbox,
} from '~/components/InputCheckbox';

import ProductColors, { IColor } from '~/components/ProductColors';
import Loading from '~/components/Loading';
import Modalities, { IModalityData } from './Modalities';
import Categories from './Categories';
import Batches, { IBatch } from './Batches';
import PricesPlaces, { IBatchCategory } from './PricesPlaces';
import BasicData from './BasicData';
import api from '~/services/api';
import Toast from '~/utils/toast';
import Select, { IOption } from '~/components/Select';
import { formatPrice } from '~/utils/format';
import { KrClose } from '~/components/KoroIcons';

interface IFormData {
  type: string;
  name: string;
  description: string;
  date: string;
  zip_code: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
}

interface ISize {
  id: number;
  size: string;
  quantity?: string;
  active: boolean;
}

interface IArchive {
  id: number;
  name: string;
  archive_url: string;
}

interface IColorData {
  id: number;
  title: string;
  hex: string;
  files?: File[];
  archives?: IArchive[];
  sizes?: ISize[];
  quantity?: string;
  selected: boolean;
}

interface IProduct {
  id: number;
  name: string;
  archive_url?: string;
  sizes: ISize[];
  colors: IColorData[];
  files?: File[];
  quantity?: string;
}

interface IBasicData {
  name: string;
  type: string;
  date: Date;
  end_date: Date;
  sales_end_date: Date;
  status: number;
  country: string;
  zipcode: string;
  state: string;
  city: string;
  address: string;
  neighborhood: string;
  number: string;
  description: string;
}

interface IParams {
  slug: string;
}

interface IArchiveEvent {
  id: number;
  archive: {
    id: number;
    name: string;
    archive_url: string;
  };
}

interface IArchiveProduct {
  archive: IArchive;
}

interface ISizeResponse {
  id: number;
  size: string;
}

interface IColorResponse {
  id: number;
  color: string;
}

interface IQuantityResponse {
  id: number;
  color_id?: number;
  quantity: number;
  color?: IColorResponse;
  size?: ISizeResponse;
  archivesProducts: IArchiveProduct[];
}

interface IProductResponse {
  id: number;
  name: string;
  quantities: IQuantityResponse[];
}

interface IKit {
  id: number;
  products: IProductResponse[];
}

interface ICategoryModality {
  id: number;
  category: {
    id: number;
    name: string;
  };
}

interface IModalityEvent {
  id: number;
  modality: {
    id: number;
    name: string;
  };
  categoriesModalities: ICategoryModality[];
}

interface IBatchCategories {
  id: number;
  category_id: number;
  modality_id: number;
  quantity: number;
  price: string;
}

interface IBatchResponse {
  id: number;
  name: string;
  quantity: number;
  due_date: string;
  batchCategories: IBatchCategories[];
}

interface IEventPaymentMethod {
  id: number;
  payment_method_id: number;
  installments?: number;
}

interface IEvent {
  id: number;
  status_id: number;
  name: string;
  description: string;
  date: string;
  end_date: string;
  sales_end_date: string;
  target_avarage_tickets: string;
  target_registration: number;
  target_revenue: string;
  slug: string;
  type: {
    id: number;
  };
  status: {
    name: string;
  };
  address: {
    id: number;
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    complement?: string;
    zip_code: string;
  };
  archivesEvents: IArchiveEvent[];
  kits: IKit[];
  modalitiesEvents: IModalityEvent[];
  code: string;
  batches: IBatchResponse[];
  paymentsMethods: IEventPaymentMethod[];
}

interface IFile {
  id: number;
  archiveEvent_id: number;
  name: string;
  url: string;
}

interface IContract {
  id: number;
  code: string;
  tax_type: string;
  tax: string;
  service_type: string;
  service: string;
}

interface IPaymentMethod {
  id: number;
  name: string;
}

const UpdateEvent: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const location = useLocation();
  const [progressNumber, setProgressNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [checkVariationSize, setCheckVariationSize] = useState(false);
  const [checkVariationColor, setCheckVariationColor] = useState(false);
  const [name, setName] = useState('');
  const [typeSelected, setTypeSelected] = useState<number | undefined>(
    undefined
  );
  const [statusName, setStatusName] = useState('');
  const [typeName, setTypeName] = useState('');
  const [btnPhotoProd, setBtnPhotoProd] = useState(true);
  const [basicData, setBasicData] = useState({} as IBasicData);
  const [addressId, setAddressId] = useState<number | undefined>(undefined);
  const [eventId, setEventId] = useState<number | undefined>(undefined);

  const [filesInfo, setFilesInfo] = useState<File[]>([]);
  const [oldFilesInfo, setOldFilesInfo] = useState<IFile[]>([]);
  const [oldFilesInfoDeleted, setOldFilesInfoDeleted] = useState<IFile[]>([]);
  const [filesColor, setFilesColor] = useState<File[]>([]);
  const [oldBatches, setOldBatches] = useState<IBatch[]>([]);
  const [batches, setBatches] = useState<IBatch[]>([]);
  const [kitId, setKitId] = useState<number | undefined>(undefined);
  const [colors, setColors] = useState<IColor[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [oldProducts, setOldProducts] = useState<IProduct[]>([]);
  const [deletedProducts, setDeletedProducts] = useState<IProduct[]>([]);
  const [qtySizeError, setQtySizeError] = useState('');
  const [modalities, setModalities] = useState<IModalityData[]>([]);
  const [oldModalities, setOldModalities] = useState<IModalityData[]>([]);
  const [modalitiesError, setModalitiesError] = useState('');
  const [categoriesError, setCategoriesError] = useState('');
  const [batchesError, setBatchesError] = useState('');
  const [pricesPlacesError, setPricesPlacesError] = useState('');
  const [loading, setLoading] = useState(false);
  const [pixPayment, setPixPayment] = useState(false);
  const [billetPayment, setBilletPayment] = useState(false);
  const [debitPayment, setDebitPayment] = useState(false);
  const [creditPayment, setCreditPayment] = useState(false);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [contractsOptions, setContractsOptions] = useState<IOption[]>([]);
  const [contractSelected, setContractSelected] = useState({} as IContract);
  const [maxInstallments, setMaxInstallments] = useState(0);
  const [paymentsMethods, setPaymentsMethods] = useState<IPaymentMethod[]>([]);
  const [oldPaymentsMethods, setOldPaymentsMethods] = useState<
    IEventPaymentMethod[]
  >([]);
  const [selectedPaymentsMethods, setSelectedPaymentsMethods] = useState<
    IEventPaymentMethod[]
  >([]);
  const [descriptionKits, setDescriptionKits] = useState('');

  const [targets, setTargets] = useState({
    registration: 0,
    revenue: 0,
    avarageTicket: 0,
  });

  const basicDataFormRef = useRef<FormHandles>(null);
  const galleryFormRef = useRef<FormHandles>(null);
  const kitsFormRef = useRef<FormHandles>(null);
  const modalitiesFormRef = useRef<FormHandles>(null);
  const categoriesFormRef = useRef<FormHandles>(null);
  const batchesFormRef = useRef<FormHandles>(null);
  const pricesPlacesFormRef = useRef<FormHandles>(null);
  const financeFormRef = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const productFormRef = useRef<FormHandles>(null);

  const progress = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const textProgress = [
    { name: 'Dados' },
    { name: 'Galeria' },
    { name: 'Kits' },
    { name: 'Modalidades' },
    { name: 'Categorias' },
    { name: 'Lotes' },
    { name: 'Preços' },
    { name: 'Financeiro' },
    { name: 'Resumo' },
  ];

  useEffect(() => {
    if (location.pathname.includes('resumo')) {
      setProgressNumber(9);
    }
  }, [location.pathname]);

  useEffect(() => {
    api
      .get<IContract[]>('contracts/organizers', {
        params: { status: 'Ativo' },
      })
      .then((response) => {
        const data = response.data.map((contract, index) => ({
          id: contract.id,
          value: `Contrato #${contract.code}`,
          selected: index === 0,
        }));
        setContracts(response.data);
        if (response.data[0]) {
          setContractSelected(response.data[0]);
        }
        setContractsOptions(data);
      });
  }, []);

  const installments = useMemo(
    () => [
      { id: 1, value: '1', selected: maxInstallments === 1 },
      { id: 2, value: '2', selected: maxInstallments === 2 },
      { id: 3, value: '3', selected: maxInstallments === 3 },
    ],
    [maxInstallments]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [progressNumber]);

  useEffect(() => {
    api.get<IEvent>(`events/${params.slug}`).then((response) => {
      setBasicData({
        name: response.data.name,
        description: response.data.description,
        type: response.data.type.id.toString(),
        date: parseISO(response.data.date),
        end_date: parseISO(response.data.end_date || response.data.date),
        sales_end_date: parseISO(
          response.data.sales_end_date || response.data.date
        ),
        status: response.data.status_id,
        country: response.data.address.country,
        zipcode: response.data.address.zip_code,
        state: response.data.address.state,
        city: response.data.address.city,
        address: response.data.address.street,
        neighborhood: response.data.address.neighborhood,
        number: response.data.address.number,
      });
      setAddressId(response.data.address.id);
      setEventId(response.data.id);
      setName(response.data.name);
      setKitId(
        response.data.kits.length > 0 ? response.data.kits[0].id : undefined
      );
      setStatusName(response.data.status.name);
      setOldPaymentsMethods(response.data.paymentsMethods);
      setSelectedPaymentsMethods(response.data.paymentsMethods);
      setMaxInstallments(
        response.data.paymentsMethods.find(
          (paymentMethod) => !!paymentMethod.installments
        )?.installments || 0
      );

      setTargets({
        registration: response.data.target_registration,
        revenue: parseFloat(response.data.target_revenue),
        avarageTicket: parseFloat(response.data.target_avarage_tickets),
      });

      const files = response.data.archivesEvents.map<IFile>((archiveEvent) => ({
        id: archiveEvent.archive.id,
        archiveEvent_id: archiveEvent.id,
        name: archiveEvent.archive.name,
        url: archiveEvent.archive.archive_url,
      }));

      const productsData = response.data.kits[0].products.map<IProduct>(
        (product) => {
          const quantity =
            product.quantities.length === 1
              ? product.quantities[0].quantity.toString()
              : undefined;

          const allSizes = ['PP', 'P', 'M', 'G', 'GG'];

          let colorsData: IColorData[] = [];
          let sizes: ISize[] = [];

          const quantitiesColors = product.quantities.find(
            (quantityData) => !!quantityData.color_id
          );
          const quantitiesSizes = product.quantities.find(
            (quantityData) => !!quantityData.size
          );
          if (quantitiesColors) {
            colorsData = product.quantities.reduce((previous, current) => {
              const {
                color_id,
                quantity: quantityAux,
                color,
                size,
                archivesProducts,
              } = current;

              let colorData = previous.find(
                (quantityData) => quantityData.id === color_id
              );

              if (!colorData) {
                colorData = {
                  id: color_id,
                  title: 'Preto',
                  hex: color?.color || '',
                  archives: [],
                  sizes: size
                    ? allSizes.map((sizeAux) => ({
                        id: 0,
                        size: sizeAux,
                        active: false,
                        quantity: '0',
                      }))
                    : undefined,
                  selected: false,
                } as IColorData;
                previous.push(colorData);
              }

              const groupSize = colorData.sizes?.find(
                (s) => s.size === size?.size
              );

              if (groupSize) {
                groupSize.active = true;
                groupSize.quantity = quantityAux.toString();
              }

              colorData.archives?.push(
                ...archivesProducts.map((archiveProduct) => ({
                  id: archiveProduct.archive.id,
                  name: archiveProduct.archive.name,
                  archive_url: archiveProduct.archive.archive_url,
                }))
              );

              return previous;
            }, [] as IColorData[]);
            sizes = colorsData[0].sizes as ISize[];
          } else if (quantitiesSizes) {
            const sizesAux = product.quantities.map((quantityData) => ({
              id: quantityData.size?.id,
              size: quantityData.size?.size,
              quantity: quantityData.quantity.toString(),
              active: true,
            }));
            sizes = allSizes.map((size) => {
              const sizeData = sizesAux.find(
                (sizeAux) => sizeAux.size === size
              );
              return {
                id: sizeData?.id || 0,
                size,
                quantity: (sizeData?.quantity || 0).toString(),
                active: !!sizeData,
              };
            });
          }

          return {
            id: product.id,
            archive_url:
              product.quantities[0].archivesProducts[0].archive.archive_url,
            name: product.name,
            quantity,
            colors: colorsData,
            sizes,
          };
        }
      );

      const modalitiesData = response.data.modalitiesEvents.map<IModalityData>(
        (modalityEvent) => ({
          id: modalityEvent.modality.id,
          input_id: modalityEvent.id,
          name: modalityEvent.modality.name,
          categories: modalityEvent.categoriesModalities.map(
            (categoryModality) => ({
              id: categoryModality.category.id,
              input_id: categoryModality.id,
              name: categoryModality.category.name,
            })
          ),
        })
      );

      const batchesData = response.data.batches.map<IBatch>((batch) => {
        let totalQty = 0;
        const batchesCategories = batch.batchCategories.map<IBatchCategory>(
          (batchCategory) => {
            totalQty += batchCategory.quantity;
            return {
              id: batchCategory.id,
              batch_id: batch.id,
              category_id: batchCategory.category_id,
              modality_id: batchCategory.modality_id,
              quantity: batchCategory.quantity,
              price: parseFloat(batchCategory.price),
            };
          }
        );

        return {
          input_id: batch.id,
          expiration: parseISO(batch.due_date),
          qtdPlaces: batch.quantity,
          restPlaces: batch.quantity - totalQty,
          batchesCategories,
        };
      });

      setBatches(batchesData);
      setOldBatches(batchesData);
      setOldModalities(modalitiesData);
      setProducts(productsData);
      setOldProducts(productsData);
      setOldFilesInfo(files);
    });
  }, [params.slug]);

  const handleChangeName = useCallback((value) => {
    setName(value);
  }, []);

  const handleChangeType = useCallback((option) => {
    setTypeName(option.value);
    setTypeSelected(option.id);
  }, []);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files) {
        setFilesInfo((prevFiles) => [...prevFiles, ...Array.from(files)]);
      }
    },
    []
  );

  const handleFileColorChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files) {
        setFilesColor((oldState) => [...oldState, ...Array.from(files)]);
        const inputElement = document.getElementById(
          `photos`
        ) as HTMLInputElement;

        if (inputElement) {
          inputElement.value = '';
        }
      }
    },
    []
  );

  const handleDelete = useCallback((indexToDelete) => {
    setFilesInfo((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToDelete)
    );
  }, []);

  const handleDeleteOldFile = useCallback((oldFile: IFile) => {
    setOldFilesInfo((oldState) =>
      oldState.filter((file) => file.id !== oldFile.id)
    );
    setOldFilesInfoDeleted((oldState) => [...oldState, oldFile]);
  }, []);

  const handleDeleteColor = useCallback((indexToDelete) => {
    setFilesColor((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToDelete)
    );
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    if (files) {
      setFilesInfo((prevFiles) => [...prevFiles, ...Array.from(files)]);
    }
  }, []);

  const handleDropColor = useCallback(
    (event: React.DragEvent<HTMLLabelElement>) => {
      event.preventDefault();
      const { files } = event.dataTransfer;
      if (files) {
        setFilesColor((oldState) => [...oldState, ...Array.from(files)]);
      }
    },
    []
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLLabelElement>) => {
      event.preventDefault();
    },
    []
  );

  const handleChangeStatus = useCallback((status) => {
    setStatusName(status.value);
  }, []);

  const addressMap = useMemo(() => {
    return basicData.address
      ? `${basicData.address} n˚ ${basicData.number}, ${basicData.neighborhood} - ${basicData.city}/${basicData.state}, CEP: ${basicData.zipcode}`
      : 'Brazil';
  }, [basicData]);

  const handleClose = useCallback(() => {
    setShow(false);
    setCheckVariationColor(false);
    setCheckVariationSize(false);
    setFilesColor([]);
  }, []);

  const handleSubmitBasicData = useCallback(async (data: IBasicData) => {
    try {
      basicDataFormRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        type: Yup.string().required('O tipo é obrigatório'),
        status: Yup.string().required('O status é obrigatório'),
        date: Yup.string(),
        end_date: Yup.string(),
        sales_end_date: Yup.string(),
        country: Yup.string().required('O país é obrigatório'),
        zipcode: Yup.string().required('O CEP é obrigatório'),
        state: Yup.string().required('O estado é obrigatório'),
        city: Yup.string().required('A cidade é obrigatória'),
        address: Yup.string().required('A rua é obrigatória'),
        neighborhood: Yup.string().required('O bairro é obrigatório'),
        number: Yup.string().required('O número é obrigatório'),
        description: Yup.string().required('A descrição é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setBasicData((oldState) => ({
        name: data.name || oldState.name,
        description: data.description || oldState.description,
        type: data.type || oldState.type,
        date: new Date(data.date || oldState.date),
        end_date: new Date(data.end_date || oldState.end_date),
        sales_end_date: new Date(
          data.sales_end_date || oldState.sales_end_date
        ),
        status: data.status || oldState.status,
        country: data.country || oldState.country,
        zipcode: data.zipcode || oldState.zipcode,
        state: data.state || oldState.state,
        city: data.city || oldState.city,
        address: data.address || oldState.address,
        neighborhood: data.neighborhood || oldState.neighborhood,
        number: data.number || oldState.number,
      }));
      setProgressNumber(2);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        basicDataFormRef.current?.setErrors(errors);
      } else {
        Swal.fire('Oops...', 'Credenciais inválidas!');
      }
    }
  }, []);

  const handleSubmitGallery = useCallback(async () => {
    setProgressNumber(3);
  }, []);

  const handleSubmitKits = useCallback(async () => {
    setProgressNumber(4);
  }, []);

  const handleSubmitModalities = useCallback(
    async (data: IFormData) => {
      try {
        modalitiesFormRef.current?.setErrors({});
        setModalitiesError('');

        const schema = Yup.object().shape({
          modalities: Yup.string().when(`$checkModalities`, {
            is: (checkModalities: boolean) => checkModalities,
            then: Yup.string().required('As modalidades são obrigatórias'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkModalities: modalities.find((modality) => !modality.id),
          },
        });

        setProgressNumber(5);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          modalitiesFormRef.current?.setErrors(errors);
          if (errors.modalities) {
            setModalitiesError(errors.modalities);
          }
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [modalities]
  );

  const handleSubmitCategories = useCallback(
    async (data: IFormData) => {
      try {
        categoriesFormRef.current?.setErrors({});
        setCategoriesError('');

        const schema = Yup.object().shape({
          categories: Yup.string().when(`$checkCategories`, {
            is: (checkCategories: boolean) => checkCategories,
            then: Yup.string().required('As categorias são obrigatórias'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkCategories: !modalities.find(
              (modality) =>
                modality.categories &&
                !modality.categories.find((category) => !category.id)
            ),
          },
        });

        setProgressNumber(6);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          categoriesFormRef.current?.setErrors(errors);
          if (errors.categories) {
            setCategoriesError(errors.categories);
          }
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [modalities]
  );

  const handleSubmitBatches = useCallback(
    async (data: IFormData) => {
      try {
        batchesFormRef.current?.setErrors({});
        setBatchesError('');

        const schema = Yup.object().shape({
          batches: Yup.string().when(`$checkBatches`, {
            is: (checkBatches: boolean) => checkBatches,
            then: Yup.string()
              .required('Preencha todos os dados dos lotes')
              .test(
                'validDate',
                `A data de expiração não pode ser posterior a ${format(
                  new Date(basicData.sales_end_date),
                  'dd/MM/yyyy'
                )}`,
                () => {
                  const maxDate = new Date(basicData.sales_end_date);
                  return batches.some((batch) => batch.expiration <= maxDate);
                }
              ),
            otherwise: Yup.string().test(
              'validDate',
              `A data de expiração não pode ser posterior a ${format(
                new Date(basicData.sales_end_date),
                'dd/MM/yyyy'
              )}`,
              () => {
                const maxDate = new Date(basicData.sales_end_date);
                return batches.some((batch) => batch.expiration <= maxDate);
              }
            ),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkBatches: !batches.every(
              (batch) => batch.qtdPlaces && batch.expiration
            ),
          },
        });

        setProgressNumber(7);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          batchesFormRef.current?.setErrors(errors);
          if (errors.batches) {
            setBatchesError(errors.batches);
          }
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [basicData.sales_end_date, batches]
  );

  const handleSubmitPricesPlaces = useCallback(
    async (data: IFormData) => {
      try {
        pricesPlacesFormRef.current?.setErrors({});
        setPricesPlacesError('');

        const schema = Yup.object().shape({
          pricesPlaces: Yup.string().when(`$checkPricesPlaces`, {
            is: (checkPricesPlaces: boolean) => checkPricesPlaces,
            then: Yup.string().required('Preencha todos os dados dos lotes'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkPricesPlaces: !batches.every((batch) =>
              batch.batchesCategories?.find(
                (batchCategory) => batchCategory.quantity && batchCategory.price
              )
            ),
          },
        });

        setProgressNumber(8);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          pricesPlacesFormRef.current?.setErrors(errors);
          if (errors.pricesPlaces) {
            setPricesPlacesError(errors.pricesPlaces);
          }
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [batches]
  );

  const handleSubmitProduct = useCallback(
    async (data) => {
      try {
        setQtySizeError('');
        productFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          qty: Yup.string().when(`$checkQty`, {
            is: (checkQty: boolean) => checkQty,
            then: Yup.string().required('A quantidade é obrigatória'),
            otherwise: Yup.string(),
          }),
          qtySize: Yup.string().when(`$checkQtySize`, {
            is: (checkQtySize: boolean) => checkQtySize,
            then: Yup.string().required(
              'Ao menos uma quantidade deve ser preenchida'
            ),
            otherwise: Yup.string(),
          }),
          photos: Yup.string().when(`$checkPhotos`, {
            is: (checkPhotos: boolean) => checkPhotos,
            then: Yup.string().required('Selecione ao menos uma imagem'),
            otherwise: Yup.string(),
          }),
          ...colors.reduce((previous, color) => {
            previous[`files_${color.id}`] = Yup.string().when(
              `$checkFiles_${color.id}`,
              {
                is: (checkFiles: boolean) => checkFiles,
                then: Yup.string().required('Selecione ao menos uma imagem'),
                otherwise: Yup.string(),
              }
            );
            previous[`qty_${color.id}`] = Yup.string().when(
              `$checkQty_${color.id}`,
              {
                is: (checkQty: boolean) => checkQty,
                then: Yup.string().required('A quantidade é obrigatória'),
                otherwise: Yup.string(),
              }
            );
            previous[`qtySize_${color.id}`] = Yup.string().when(
              `$checkQtySize_${color.id}`,
              {
                is: (checkQtySize: boolean) => checkQtySize,
                then: Yup.string().required(
                  'Ao menos uma quantidade deve ser preenchida'
                ),
                otherwise: Yup.string(),
              }
            );

            return previous;
          }, {} as { [key: string]: Yup.StringSchema<string | undefined, Record<string, any>, string | undefined> }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkQty: !checkVariationColor && !checkVariationSize,
            checkQtySize:
              !checkVariationColor &&
              checkVariationSize &&
              !data.qtyPp &&
              !data.qtyP &&
              !data.qtyM &&
              !data.qtyG &&
              !data.qtyGg,
            checkPhotos: !checkVariationColor && filesColor.length === 0,
            ...colors.reduce((previous, color) => {
              previous[`checkFiles_${color.id}`] =
                checkVariationColor && !(color.files && color.files.length > 0);
              previous[`checkQty_${color.id}`] =
                checkVariationColor && !color.checkVariationSize;
              previous[`checkQtySize_${color.id}`] =
                checkVariationColor &&
                color.checkVariationSize &&
                !color.qtyPp &&
                !color.qtyP &&
                !color.qtyM &&
                !color.qtyG &&
                !color.qtyGg;
              return previous;
            }, {} as { [key: string]: boolean }),
          },
        });

        const sizes: ISize[] = [];
        const colorsData: IColorData[] = [];

        if (checkVariationColor) {
          const newColors = colors.map((color, index) => {
            const sizesColor: ISize[] = [];

            if (color.qtyPp) {
              sizesColor.push({
                id: 0,
                size: 'PP',
                active: true,
                quantity: color.qtyPp,
              });
            } else {
              sizesColor.push({
                id: 0,
                size: 'PP',
                active: false,
              });
            }

            if (color.qtyP) {
              sizesColor.push({
                id: 1,
                size: 'P',
                active: true,
                quantity: color.qtyP,
              });
            } else {
              sizesColor.push({
                id: 1,
                size: 'P',
                active: false,
              });
            }

            if (color.qtyM) {
              sizesColor.push({
                id: 2,
                size: 'M',
                active: true,
                quantity: color.qtyM,
              });
            } else {
              sizesColor.push({
                id: 2,
                size: 'M',
                active: false,
              });
            }

            if (color.qtyG) {
              sizesColor.push({
                id: 3,
                size: 'G',
                active: true,
                quantity: color.qtyG,
              });
            } else {
              sizesColor.push({
                id: 3,
                size: 'G',
                active: false,
              });
            }

            if (color.qtyGg) {
              sizesColor.push({
                id: 4,
                size: 'GG',
                active: true,
                quantity: color.qtyGg,
              });
            } else {
              sizesColor.push({
                id: 4,
                size: 'GG',
                active: false,
              });
            }

            return {
              id: color.id,
              title: color.title,
              hex: color.hex,
              files: color.files,
              sizes: sizesColor,
              selected: index === 0,
              quantity: data[`qty_${color.id}`],
            };
          });
          sizes.push(...newColors[0].sizes);
          colorsData.push(...newColors);
        } else if (checkVariationSize) {
          if (data.qtyPp) {
            sizes.push({
              id: 0,
              size: 'PP',
              active: true,
              quantity: data.qtyPp,
            });
          } else {
            sizes.push({
              id: 0,
              size: 'PP',
              active: false,
            });
          }

          if (data.qtyP) {
            sizes.push({
              id: 1,
              size: 'P',
              active: true,
              quantity: data.qtyP,
            });
          } else {
            sizes.push({
              id: 1,
              size: 'P',
              active: false,
            });
          }

          if (data.qtyM) {
            sizes.push({
              id: 2,
              size: 'M',
              active: true,
              quantity: data.qtyM,
            });
          } else {
            sizes.push({
              id: 2,
              size: 'M',
              active: false,
            });
          }

          if (data.qtyG) {
            sizes.push({
              id: 3,
              size: 'G',
              active: true,
              quantity: data.qtyG,
            });
          } else {
            sizes.push({
              id: 3,
              size: 'G',
              active: false,
            });
          }

          if (data.qtyGg) {
            sizes.push({
              id: 4,
              size: 'GG',
              active: true,
              quantity: data.qtyGg,
            });
          } else {
            sizes.push({
              id: 4,
              size: 'GG',
              active: false,
            });
          }
        }

        const formData: IProduct = {
          id: new Date().getTime(),
          name: data.name,
          archive_url: checkVariationColor
            ? URL.createObjectURL(
                (colors[0].files && colors[0].files[0]) as unknown as Blob
              )
            : URL.createObjectURL(filesColor[0]),
          sizes,
          colors: colorsData,
          files: filesColor,
          quantity: data.qty,
        };

        setProducts((oldState: any) => [...oldState, formData]);

        setShow(false);
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          productFormRef.current?.setErrors(errors);
          const newColors = colors.map((color) => ({
            ...color,
            qtySizeError: errors[`qtySize_${color.id}`],
          }));
          setColors(newColors);
          setQtySizeError(errors.qtySize);
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [checkVariationColor, checkVariationSize, colors, filesColor, handleClose]
  );

  const handleChangeCheckVariationColor = useCallback(() => {
    setColors([
      {
        id: new Date().getTime(),
        title: 'Preto',
        hex: '#000',
        checkVariationSize: false,
      },
    ]);
    setCheckVariationColor((oldState) => !oldState);
  }, []);

  const handleClickAddNewColor = useCallback(() => {
    setColors((oldState) => [
      ...oldState,
      {
        id: new Date().getTime(),
        title: 'Preto',
        hex: '#000',
        checkVariationSize: false,
      },
    ]);
  }, []);

  const handleChangeColor = useCallback((color, index) => {
    setColors((oldState) => {
      const newColors = oldState.slice();
      newColors[index] = color;

      return newColors;
    });
  }, []);

  const handleClickRemove = useCallback(
    (productId) => {
      const newProducts = products.filter(
        (product) => product.id !== productId
      );
      const deletedProduct = oldProducts.find(
        (product) => product.id === productId
      );
      if (deletedProduct) {
        setDeletedProducts((oldState) => [...oldState, deletedProduct]);
      }
      setProducts(newProducts);
    },
    [oldProducts, products]
  );

  const handleSelectColor = useCallback(
    (colorIndex, productIndex) => {
      const newProducts = products.slice();
      const { files, sizes, archives } =
        newProducts[productIndex].colors[colorIndex];

      if (files) {
        newProducts[productIndex].archive_url = URL.createObjectURL(files[0]);
      }

      if (archives) {
        newProducts[productIndex].archive_url = archives[0].archive_url;
      }

      if (sizes) {
        newProducts[productIndex].sizes = sizes;
      } else {
        const sizesData = newProducts[productIndex].colors.find(
          (color) => !!color.sizes
        )?.sizes;

        if (sizesData) {
          newProducts[productIndex].sizes = sizesData.map((size) => ({
            ...size,
            active: false,
          }));
        }
      }

      newProducts[productIndex].colors = newProducts[productIndex].colors.map(
        (colorData, index) => ({
          ...colorData,
          selected: index === colorIndex,
        })
      );
      setProducts(newProducts);
    },
    [products]
  );

  const handleClickNext = useCallback((currentProgress) => {
    switch (currentProgress) {
      case 1:
        basicDataFormRef.current?.submitForm();
        break;
      case 2:
        galleryFormRef.current?.submitForm();
        break;
      case 3:
        kitsFormRef.current?.submitForm();
        break;
      case 4:
        modalitiesFormRef.current?.submitForm();
        break;
      case 5:
        categoriesFormRef.current?.submitForm();
        break;
      case 6:
        batchesFormRef.current?.submitForm();
        break;
      case 7:
        pricesPlacesFormRef.current?.submitForm();
        break;
      case 8:
        financeFormRef.current?.submitForm();
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const avarageTicket = targets.revenue / targets.registration;

    setTargets((oldStats) => ({
      ...oldStats,
      avarageTicket: Number.isNaN(avarageTicket) ? 0 : avarageTicket,
    }));
  }, [targets.registration, targets.revenue]);

  useEffect(() => {
    api.get<IPaymentMethod[]>('payments-methods').then((response) => {
      setPaymentsMethods(response.data);
    });
  }, []);

  const paymentMethodsOptions = useMemo(() => {
    return paymentsMethods.map((paymentMethod) => {
      let selected = true;
      if (paymentMethod.name === 'Pix') {
        selected = pixPayment;
      }
      if (paymentMethod.name === 'Boleto') {
        selected = billetPayment;
      }
      if (paymentMethod.name === 'Débito') {
        selected = debitPayment;
      }
      if (paymentMethod.name === 'Crédito') {
        selected = creditPayment;
      }
      return {
        label: paymentMethod.name,
        value: paymentMethod.id,
        selected,
      };
    });
  }, [billetPayment, creditPayment, debitPayment, paymentsMethods, pixPayment]);

  useEffect(() => {
    for (const paymentMethod of paymentsMethods) {
      if (paymentMethod.name === 'Pix') {
        setPixPayment(
          selectedPaymentsMethods.some(
            (selectedPaymentMethod) =>
              selectedPaymentMethod.payment_method_id === paymentMethod.id
          )
        );
      }
      if (paymentMethod.name === 'Boleto') {
        setBilletPayment(
          selectedPaymentsMethods.some(
            (selectedPaymentMethod) =>
              selectedPaymentMethod.payment_method_id === paymentMethod.id
          )
        );
      }
      if (paymentMethod.name === 'Débito') {
        setDebitPayment(
          selectedPaymentsMethods.some(
            (selectedPaymentMethod) =>
              selectedPaymentMethod.payment_method_id === paymentMethod.id
          )
        );
      }
      if (paymentMethod.name === 'Crédito') {
        setCreditPayment(
          selectedPaymentsMethods.some(
            (selectedPaymentMethod) =>
              selectedPaymentMethod.payment_method_id === paymentMethod.id
          )
        );
      }
    }
  }, [paymentsMethods, selectedPaymentsMethods]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const addressFormData = {
        street: basicData.address,
        number: basicData.number.toString(),
        neighborhood: basicData.neighborhood,
        city: basicData.city,
        state: basicData.state,
        country: basicData.country,
        zip_code: basicData.zipcode,
      };

      let responseAddress: AxiosResponse<any>;
      if (addressId) {
        responseAddress = await api.put(
          `addresses/${addressId}`,
          addressFormData
        );
      } else {
        responseAddress = await api.post('addresses', addressFormData);
      }

      const eventFormData = {
        type_id: typeSelected,
        address_id: responseAddress.data.id,
        status_id: basicData.status,
        contract_id: contractSelected.id,
        name: basicData.name,
        description: basicData.description,
        date: format(new Date(basicData.date), 'yyyy-MM-dd HH:mm:ss'),
        end_date: format(new Date(basicData.end_date), 'yyyy-MM-dd HH:mm:ss'),
        sales_end_date: format(
          new Date(basicData.sales_end_date),
          'yyyy-MM-dd HH:mm:ss'
        ),
        target_registration: parseInt(targets.registration.toString(), 10),
        target_revenue: parseFloat(targets.revenue.toString()),
        target_avarage_ticket:
          parseFloat(targets.revenue.toString()) /
          parseInt(targets.registration.toString(), 10),
      };

      const responseEvent = await api.put(
        `events/organizer/${eventId}`,
        eventFormData
      );

      const paymentsMethodsSelected = paymentMethodsOptions.filter(
        (paymentMethod) => paymentMethod.selected
      );

      const deletedPaymentsMethods = oldPaymentsMethods.filter(
        (oldPaymentMethod) =>
          !paymentsMethodsSelected.some(
            (paymentMethodSelected) =>
              paymentMethodSelected.value === oldPaymentMethod.payment_method_id
          )
      );

      const newPaymentsMethods = paymentsMethodsSelected.filter(
        (paymentMethodSelected) =>
          !oldPaymentsMethods.some(
            (oldPaymentMethod) =>
              paymentMethodSelected.value === oldPaymentMethod.payment_method_id
          )
      );

      const updatedPaymentsMethods = paymentsMethodsSelected.filter(
        (paymentMethodSelected) =>
          oldPaymentsMethods.some(
            (oldPaymentMethod) =>
              paymentMethodSelected.value === oldPaymentMethod.payment_method_id
          )
      );

      if (deletedPaymentsMethods.length > 0) {
        for (const deletedPaymentMethod of deletedPaymentsMethods) {
          await api.delete(
            `events-payments-methods/${deletedPaymentMethod.id}`
          );
        }
      }

      if (newPaymentsMethods.length > 0) {
        for (const paymentMethod of newPaymentsMethods) {
          const eventPaymentMethodFormData = {
            event_id: responseEvent.data.id,
            payment_method_id: paymentMethod.value,
            installments:
              paymentMethod.label === 'Crédito' ? maxInstallments : undefined,
          };

          await api.post('events-payments-methods', eventPaymentMethodFormData);
        }
      }

      if (updatedPaymentsMethods.length > 0) {
        for (const paymentMethod of updatedPaymentsMethods) {
          const eventPaymentMethodFormData = {
            event_id: responseEvent.data.id,
            payment_method_id: paymentMethod.value,
            installments:
              paymentMethod.label === 'Crédito' ? maxInstallments : undefined,
          };

          const eventPaymentMethod = oldPaymentsMethods.find(
            (oldPaymentMethod) =>
              oldPaymentMethod.payment_method_id === paymentMethod.value
          );

          await api.put(
            `events-payments-methods/${eventPaymentMethod?.id}`,
            eventPaymentMethodFormData
          );
        }
      }

      if (filesInfo.length > 0) {
        for (const file of filesInfo) {
          const fileFormData = new FormData();
          fileFormData.append('file', file);
          fileFormData.append('folder', 'events');

          const responseFile = await api.post('archives', fileFormData);

          const fileEventFormData = {
            archive_id: responseFile.data.id,
            event_id: responseEvent.data.id,
          };

          await api.post('archives-events', fileEventFormData);
        }
      }

      if (oldFilesInfoDeleted.length > 0) {
        for (const file of oldFilesInfoDeleted) {
          await api.delete(`archives-events/${file.archiveEvent_id}`);
          await api.delete(`archives/${file.id}`);
        }
      }

      console.log('AQUI');
      console.log(descriptionKits);

      let responseKit: AxiosResponse;
      if (!kitId) {
        responseKit = await api.post('kits', {
          event_id: responseEvent.data.id,
          description: descriptionKits,
        });
      } else {
        responseKit = await api.put(`kits/${kitId}`, {
          event_id: responseEvent.data.id,
          description: descriptionKits,
        });
      }

      const newProducts = products.filter(
        (product) =>
          !oldProducts.some((oldProduct) => oldProduct.id === product.id)
      );

      if (newProducts.length > 0) {
        for (const product of newProducts) {
          const productFormData = {
            kit_id: responseKit.data.id,
            name: product.name,
          };

          const responseProduct = await api.post('products', productFormData);

          const productSizes = product.sizes.filter((size) => size.active);

          let archivesId: number[] = [];
          if (product.files && product.files.length > 0) {
            const productFiles = product.files;
            for (const productFile of productFiles) {
              const productFileFormData = new FormData();
              productFileFormData.append('file', productFile);
              productFileFormData.append('folder', 'products');

              const responseProductFile = await api.post(
                'archives',
                productFileFormData
              );

              archivesId.push(responseProductFile.data.id);
            }
          }

          if (productSizes.length > 0 && product.colors.length === 0) {
            for (const size of productSizes) {
              const sizeFormData = {
                product_id: responseProduct.data.id,
                size: size.size,
              };

              const responseSize = await api.post(
                'sizes-products',
                sizeFormData
              );

              const quantityFormData = {
                product_id: responseProduct.data.id,
                size_id: responseSize.data.id,
                quantity: size.quantity,
              };

              const responseQuantityProduct = await api.post(
                'quantities-products',
                quantityFormData
              );

              if (archivesId.length > 0) {
                for (const archive_id of archivesId) {
                  const archiveProductFileFormData = {
                    archive_id,
                    quantity_product_id: responseQuantityProduct.data.id,
                  };

                  await api.post(
                    'archives-products',
                    archiveProductFileFormData
                  );
                }
              }
            }
          }

          if (product.colors.length > 0) {
            for (const color of product.colors) {
              const colorFormData = {
                product_id: responseProduct.data.id,
                name: color.title,
                color: color.hex,
              };

              const responseColor = await api.post(
                'colors-products',
                colorFormData
              );

              archivesId = [];
              if (color.files && color.files.length > 0) {
                const productFiles = color.files;
                for (const productFile of productFiles) {
                  const productFileFormData = new FormData();
                  productFileFormData.append('file', productFile);
                  productFileFormData.append('folder', 'products');

                  const responseProductFile = await api.post(
                    'archives',
                    productFileFormData
                  );

                  archivesId.push(responseProductFile.data.id);
                }
              }

              const colorSizes = color.sizes?.filter((size) => size.active);
              if (colorSizes && colorSizes.length > 0) {
                for (const size of colorSizes) {
                  const sizeFormData = {
                    product_id: responseProduct.data.id,
                    size: size.size,
                  };

                  const responseSize = await api.post(
                    'sizes-products',
                    sizeFormData
                  );

                  const quantityFormData = {
                    product_id: responseProduct.data.id,
                    size_id: responseSize.data.id,
                    color_id: responseColor.data.id,
                    quantity: size.quantity,
                  };

                  const responseQuantityProduct = await api.post(
                    'quantities-products',
                    quantityFormData
                  );

                  if (archivesId.length > 0) {
                    for (const archive_id of archivesId) {
                      const archiveProductFileFormData = {
                        archive_id,
                        quantity_product_id: responseQuantityProduct.data.id,
                      };

                      await api.post(
                        'archives-products',
                        archiveProductFileFormData
                      );
                    }
                  }
                }
              } else {
                const quantityFormData = {
                  product_id: responseProduct.data.id,
                  color_id: responseColor.data.id,
                  quantity: color.quantity,
                };

                const responseQuantityProduct = await api.post(
                  'quantities-products',
                  quantityFormData
                );

                if (archivesId.length > 0) {
                  for (const archive_id of archivesId) {
                    const archiveProductFileFormData = {
                      archive_id,
                      quantity_product_id: responseQuantityProduct.data.id,
                    };

                    await api.post(
                      'archives-products',
                      archiveProductFileFormData
                    );
                  }
                }
              }
            }
          }

          if (product.quantity) {
            const quantityFormData = {
              product_id: responseProduct.data.id,
              quantity: product.quantity,
            };

            const responseQuantityProduct = await api.post(
              'quantities-products',
              quantityFormData
            );

            if (archivesId.length > 0) {
              await new Promise<void>((resolveArchivesProducts) => {
                archivesId.forEach(async (archive_id, indexArchives) => {
                  const archiveProductFileFormData = {
                    archive_id,
                    quantity_product_id: responseQuantityProduct.data.id,
                  };

                  await api.post(
                    'archives-products',
                    archiveProductFileFormData
                  );

                  if (archivesId.length === indexArchives + 1) {
                    resolveArchivesProducts();
                  }
                });
              });
            }
          }
        }
      }

      if (deletedProducts.length > 0) {
        await new Promise<void>((resolve) => {
          deletedProducts.forEach(async (deletedProduct, index) => {
            await api.delete(`products/${deletedProduct.id}`);

            if (deletedProducts.length === index + 1) {
              resolve();
            }
          });
        });
      }

      const deletedModalities = oldModalities.filter(
        (oldModality) =>
          !modalities.some((modality) => modality.id === oldModality.id)
      );

      if (modalities.length > 0) {
        let modalityIndex = 0;
        for (const modality of modalities) {
          const modalityEventFormData = {
            modality_id: modality.id,
            event_id: responseEvent.data.id,
          };

          let responseModalityEvent: AxiosResponse;

          const isOldModality = oldModalities.some(
            (oldModality) => oldModality.id === modality.id
          );

          if (isOldModality) {
            responseModalityEvent = await api.put(
              `modalities-events/${modality.input_id}`,
              modalityEventFormData
            );
          } else {
            responseModalityEvent = await api.post(
              'modalities-events',
              modalityEventFormData
            );
          }

          const deletedCategories = oldModalities[
            modalityIndex
          ].categories?.filter(
            (oldCategory) =>
              !modality.categories?.some(
                (category) => category.id === oldCategory.id
              )
          );

          if (modality.categories && modality.categories.length > 0) {
            for (const category of modality.categories) {
              const categoryModalityFormData = {
                category_id: category.id,
                modality_event_id: responseModalityEvent.data.id,
              };

              const isOldCategory = oldModalities[
                modalityIndex
              ].categories?.some(
                (oldCategory) => oldCategory.id === category.id
              );

              if (isOldCategory) {
                await api.put(
                  `categories-modalities/${category.input_id}`,
                  categoryModalityFormData
                );
              } else {
                await api.post(
                  'categories-modalities',
                  categoryModalityFormData
                );
              }
            }
          }

          if (deletedCategories && deletedCategories.length > 0) {
            for (const deletedCategory of deletedCategories) {
              await api.delete(
                `categories-modalities/${deletedCategory.input_id}`
              );
            }
          }

          modalityIndex += 1;
        }
      }

      if (deletedModalities.length > 0) {
        for (const deletedModality of deletedModalities) {
          if (
            deletedModality.categories &&
            deletedModality.categories.length > 0
          ) {
            for (const category of deletedModality.categories) {
              await api.delete(`categories-modalities/${category.input_id}`);
            }
          }

          await api.delete(`modalities-events/${deletedModality.input_id}`);
        }
      }

      const deletedBatches = oldBatches.filter(
        (oldBatch) =>
          !batches.some((modality) => modality.input_id === oldBatch.input_id)
      );

      if (batches.length > 0) {
        let batchIndex = 0;
        for (const batch of batches) {
          const batchFormData = {
            event_id: responseEvent.data.id,
            name: `Lote ${batchIndex + 1}`,
            quantity: batch.qtdPlaces,
            due_date: batch.expiration,
          };

          const isOldBatch = oldBatches.some(
            (oldBatch) => oldBatch.input_id === batch.input_id
          );

          let responseBatch: AxiosResponse;

          if (isOldBatch) {
            responseBatch = await api.put(
              `batches/${batch.input_id}`,
              batchFormData
            );
          } else {
            responseBatch = await api.post('batches', batchFormData);
          }

          if (batch.batchesCategories) {
            const { batchesCategories } = batch;

            const oldBatch = oldBatches[batchIndex];
            const deletedBatchesCategories =
              oldBatch?.batchesCategories?.filter(
                (oldBatchCategory) =>
                  !batchesCategories.some(
                    (batchCategory) => batchCategory.id === oldBatchCategory.id
                  )
              );

            if (batchesCategories.length > 0) {
              for (const batchCategory of batchesCategories) {
                const batchCategoryFormData = {
                  batch_id: responseBatch.data.id,
                  category_id: batchCategory.category_id,
                  modality_id: batchCategory.modality_id,
                  quantity: batchCategory.quantity,
                  price: batchCategory.price,
                };

                const isOldBatchCategory = oldBatch?.batchesCategories?.some(
                  (oldBatchCategory) => oldBatchCategory.id === batchCategory.id
                );

                if (isOldBatchCategory) {
                  await api.put(
                    `batches-categories/${batchCategory.id}`,
                    batchCategoryFormData
                  );
                } else {
                  await api.post(`batches-categories`, batchCategoryFormData);
                }
              }
            }

            if (
              deletedBatchesCategories &&
              deletedBatchesCategories.length > 0
            ) {
              for (const deletedBatchCategory of deletedBatchesCategories) {
                await api.delete(
                  `batches-categories/${deletedBatchCategory.id}`
                );
              }
            }
          }

          batchIndex += 1;
        }
      }

      if (deletedBatches.length > 0) {
        for (const deletedBatch of deletedBatches) {
          if (
            deletedBatch.batchesCategories &&
            deletedBatch.batchesCategories.length > 0
          ) {
            for (const batchCategory of deletedBatch.batchesCategories) {
              await api.delete(`batches-categories/${batchCategory.id}`);
            }
          }

          await api.delete(`batches/${deletedBatch.input_id}`);
        }
      }

      setLoading(false);

      Toast.fire({
        icon: 'success',
        iconColor: '#ff6900',
        title: 'Evento editado com sucesso!',
      });

      history.push(`${process.env.PUBLIC_URL}/eventos`);
    } catch (error) {
      setLoading(false);
      Swal.fire('Oops...', 'Ocorreu um erro tente novamente!', 'error');
    }
  }, [
    basicData.address,
    basicData.number,
    basicData.neighborhood,
    basicData.city,
    basicData.state,
    basicData.country,
    basicData.zipcode,
    basicData.status,
    basicData.name,
    basicData.description,
    basicData.date,
    basicData.end_date,
    basicData.sales_end_date,
    addressId,
    typeSelected,
    contractSelected.id,
    targets.registration,
    targets.revenue,
    eventId,
    paymentMethodsOptions,
    oldPaymentsMethods,
    filesInfo,
    oldFilesInfoDeleted,
    descriptionKits,
    kitId,
    products,
    deletedProducts,
    oldModalities,
    modalities,
    oldBatches,
    batches,
    history,
    maxInstallments,
    oldProducts,
  ]);

  const handleChangeContract = useCallback(
    (contractOption) => {
      const contractSelectedData = contracts.find(
        (contract) => contract.id === contractOption.id
      );
      if (contractSelectedData) {
        setContractSelected(contractSelectedData);
      }
    },
    [contracts]
  );

  const handleChangePaymentsMethods = useCallback(
    (paymentsMethodsData: IOptionCheckbox[]) => {
      const hasPix = paymentsMethodsData.some(
        (paymentMethod) =>
          paymentMethod.label === 'Pix' && paymentMethod.selected
      );
      const hasBillet = paymentsMethodsData.some(
        (paymentMethod) =>
          paymentMethod.label === 'Boleto' && paymentMethod.selected
      );
      const hasDebit = paymentsMethodsData.some(
        (paymentMethod) =>
          paymentMethod.label === 'Débito' && paymentMethod.selected
      );
      const hasCredit = paymentsMethodsData.some(
        (paymentMethod) =>
          paymentMethod.label === 'Crédito' && paymentMethod.selected
      );

      setPixPayment(hasPix);
      setBilletPayment(hasBillet);
      setDebitPayment(hasDebit);
      setCreditPayment(hasCredit);
    },
    []
  );

  const handleSubmitFinance = useCallback(
    async (data) => {
      try {
        financeFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          contract: Yup.string().required('O nome é obrigatório'),
          serviceTax: Yup.string().required('O tipo é obrigatório'),
          processingTax: Yup.string().required('O status é obrigatório'),
          targetRegistration: Yup.string().required('O país é obrigatório'),
          targetRevenue: Yup.string().required('O CEP é obrigatório'),
          targetAvarageTicket: Yup.string().required('O estado é obrigatório'),
          paymentsMethods: Yup.string().when(`$checkPaymentsMethods`, {
            is: (checkPaymentsMethods: boolean) => checkPaymentsMethods,
            then: Yup.string().required(
              'Escolha pelo menos uma forma de pagamento'
            ),
            otherwise: Yup.string(),
          }),
          maxInstallments: Yup.string().when(`$checkMaxInstallments`, {
            is: (checkMaxInstallments: boolean) => checkMaxInstallments,
            then: Yup.string().required(
              'A quantidade de parcelas é obrigatória'
            ),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkPaymentsMethods: !paymentMethodsOptions.some(
              (paymentMethod) => paymentMethod.selected
            ),
            checkMaxInstallments: paymentMethodsOptions.some(
              (paymentMethod) =>
                paymentMethod.label === 'Crédito' && paymentMethod.selected
            ),
          },
        });
        setProgressNumber(9);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          financeFormRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [paymentMethodsOptions]
  );

  const handleChangeMaxInstallments = useCallback((option) => {
    setMaxInstallments(parseInt(option.value, 10));
  }, []);

  const handleTargetRegistration = useCallback(
    (e) => {
      setTargets({
        ...targets,
        registration: parseInt(e.target.value, 10),
      });
    },
    [targets]
  );

  const handleTargetRevenue = useCallback(
    (e) => {
      setTargets({
        ...targets,
        revenue: parseFloat(
          e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
        ),
      });
    },
    [targets]
  );

  return (
    <Container>
      <div className="container-fluid">
        <div>
          <div className="row">
            <div className="col-12 px-0">
              <div className="bg-white d-flex justify-content-between align-items-center p-4">
                <h1 className="mb-0">Editar Evento / {name}</h1>
              </div>
            </div>
            <div className="col-12 px-0">
              <div className="bg-white p-3 mt-3">
                <div className="row justify-content-center">
                  <div className="col-xl-10 col-xxl-9">
                    <div className="d-flex justify-content-center align-items-center">
                      {progress.map((index) => (
                        <>
                          <div
                            className={
                              location.pathname.includes('resumo')
                                ? 'pe-none'
                                : ''
                            }
                          >
                            <button
                              type="button"
                              className="bg-transparent border-0 position-relative"
                              onClick={() => setProgressNumber(index)}
                            >
                              <span
                                className={`${
                                  index < progressNumber + 1
                                    ? 'circle-active'
                                    : 'circle'
                                }`}
                              >
                                {index}
                              </span>

                              <span
                                className={`text ${
                                  index < progressNumber + 1
                                    ? 'text-active'
                                    : ''
                                }`}
                              >
                                {textProgress[index - 1].name}
                              </span>
                            </button>
                          </div>
                          {index !== progress.length && (
                            <hr
                              className={`${
                                index < progressNumber + 1 && 'hr-active'
                              } w-11 my-0 mx-2`}
                            />
                          )}
                        </>
                      ))}
                    </div>
                  </div>

                  <div
                    className={`${
                      progressNumber === 7 || progressNumber === 9
                        ? 'col-lg-12'
                        : 'col-lg-9'
                    } mt-2`}
                  >
                    <Form
                      ref={basicDataFormRef}
                      onSubmit={handleSubmitBasicData}
                      className={`${progressNumber === 1 ? 'row' : 'd-none'}`}
                      initialData={
                        Object.keys(basicData).length > 0
                          ? basicData
                          : undefined
                      }
                    >
                      <BasicData
                        onChangeEventName={handleChangeName}
                        onChangeType={handleChangeType}
                        onChangeStatus={handleChangeStatus}
                        className={`${progressNumber === 1 ? 'row' : 'd-none'}`}
                        initialData={
                          Object.keys(basicData).length > 0
                            ? basicData
                            : undefined
                        }
                      />
                    </Form>

                    <Form
                      ref={galleryFormRef}
                      onSubmit={handleSubmitGallery}
                      className={`${progressNumber === 2 ? 'row' : 'd-none'}`}
                    >
                      <div className="col-12">
                        <h2 className="h4 fw-semibold text-center mt-5 mb-4">
                          2- Galeria de imagens
                        </h2>
                        <p className="mb-4">
                          Para uma melhor experiência para os nossos usuários,
                          padronizamos os tamanhos das imagens que podem ser
                          inseridas na plataforma. Saiba mais sobre o formato e
                          o tamanho de cada imagem aqui antes de enviar suas
                          imagens.
                        </p>
                      </div>
                      <div className="col-12">
                        <h3 className="h6 fw-normal">Galeria de fotos</h3>
                        <Input
                          id="fileInput"
                          type="file"
                          multiple
                          name="files"
                          className="d-none"
                          onChange={handleFileChange}
                        />
                        <label
                          htmlFor="fileInput"
                          className="w-100 text-center"
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                        >
                          <MdUpload size={48} color="#FF6900" />
                          <span className="d-block">
                            Arraste e solte os arquivos aqui para
                            <br />
                            adicionar uma imagem
                          </span>
                          <span className="d-inline-flex my-2">
                            Selecionar Imagens
                          </span>
                          <span className="d-block">
                            Extensões aceitas: jpg, png, jpeg.
                          </span>
                        </label>

                        {oldFilesInfo.map((file) => (
                          <div
                            key={file.id}
                            className="d-flex justify-content-between arquives p-3 mt-3"
                          >
                            <div className="d-flex align-items-center">
                              <img src={file.url} alt={file.name} />
                              <p className="ms-3 mb-0">{file.name}</p>
                            </div>
                            <button
                              type="button"
                              className="bg-transparent border-0"
                              onClick={() => handleDeleteOldFile(file)}
                            >
                              <IoMdClose size={24} color="#8C8C8C" />
                            </button>
                          </div>
                        ))}
                        {filesInfo.map((file, index) => (
                          <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className="d-flex justify-content-between arquives p-3 mt-3"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                              />
                              <p className="ms-3 mb-0">{file.name}</p>
                            </div>
                            <button
                              type="button"
                              className="bg-transparent border-0"
                              onClick={() => handleDelete(index)}
                            >
                              <IoMdClose size={24} color="#8C8C8C" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </Form>

                    <Form
                      ref={kitsFormRef}
                      onSubmit={handleSubmitKits}
                      className={`${progressNumber === 3 ? 'row' : 'd-none'}`}
                    >
                      <div className="col-12">
                        <h2 className="h4 fw-semibold text-center mt-5 mb-4">
                          3 - Kits
                        </h2>
                        <p className="mb-4">
                          Caso seu kit forneça kit na inscrição para o evento
                          você pode cadastrar os produtos que serão entregues ao
                          cliente ao adquirir o ingresso. Caso seu evento não
                          tenha kits, basta pular esta etapa clicando em
                          “Próximo” abaixo.
                        </p>
                      </div>
                      <div className="col-12 px-lg-2">
                        <h3 className="h6 fw-normal">Descrição do Kit:</h3>
                        <Textarea
                          name="description_kits"
                          rows={3}
                          className="rounded"
                          onChange={(e) => setDescriptionKits(e.target.value)}
                          value={descriptionKits}
                        />
                      </div>
                      <div className="col-lg-6 col-xl-4 col-xxl-3 px-2 mt-3">
                        <button
                          type="button"
                          onClick={() => setShow(true)}
                          className="w-100 h-100 btn-add py-5"
                        >
                          <HiPlus size={24} color="#ff6900" className="me-2" />
                          <span className="d-block">Adicionar Produto</span>
                        </button>
                      </div>
                      {products.map((product, index) => (
                        <div
                          key={product.id}
                          className="col-lg-6 col-xl-4 col-xxl-3 px-2 mt-3"
                        >
                          <div className="d-flex h-100">
                            <div className="kits w-100 py-4 px-3 d-flex position-relative">
                              <button
                                type="button"
                                className="bg-transparent border-0 position-absolute btn-close-card"
                                onClick={() => handleClickRemove(product.id)}
                              >
                                <IoMdClose size={24} color="#8C8C8C" />
                              </button>
                              {product.sizes?.length > 0 && (
                                <div className="d-flex flex-column w-tam">
                                  {product.sizes.map((size) => (
                                    <span
                                      key={size.id}
                                      className={`mb-2 ${
                                        size.active ? 'active' : ''
                                      }`}
                                    >
                                      {size.size}
                                    </span>
                                  ))}
                                </div>
                              )}
                              <div className="mx-auto">
                                <Img
                                  src={product.archive_url as string}
                                  className="product-photo"
                                />
                                {product.colors?.length > 0 && (
                                  <div className="d-flex justify-content-center align-items-center mt-2">
                                    {product.colors.map((color, colorIndex) => (
                                      <button
                                        key={color.id}
                                        type="button"
                                        className="border-0 bg-transparent btn-color"
                                        onClick={() =>
                                          handleSelectColor(colorIndex, index)
                                        }
                                      >
                                        <ColorBox
                                          color={color.hex}
                                          selected={color.selected}
                                        />
                                      </button>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Form>

                    <Form
                      ref={modalitiesFormRef}
                      onSubmit={handleSubmitModalities}
                      className={`${progressNumber === 4 ? 'row' : 'd-none'}`}
                    >
                      <Modalities
                        className={`${progressNumber === 4 ? 'row' : 'd-none'}`}
                        typeSelected={typeSelected}
                        onChange={(data) => setModalities(data)}
                        initialData={oldModalities}
                      />
                      {modalitiesError && (
                        <span className="small text-danger error">
                          {modalitiesError}
                        </span>
                      )}
                    </Form>

                    <Form
                      ref={categoriesFormRef}
                      onSubmit={handleSubmitCategories}
                      className={`${progressNumber === 5 ? 'row' : 'd-none'}`}
                    >
                      <Categories
                        modalities={modalities}
                        typeSelected={typeSelected}
                        className={`${progressNumber === 5 ? 'row' : 'd-none'}`}
                        onChange={(data) => setModalities(data)}
                      />
                      {categoriesError && (
                        <span className="small text-danger error">
                          {categoriesError}
                        </span>
                      )}
                    </Form>

                    <Form
                      ref={batchesFormRef}
                      onSubmit={handleSubmitBatches}
                      className={`${progressNumber === 6 ? 'row' : 'd-none'}`}
                    >
                      <Batches
                        className={`${progressNumber === 6 ? 'row' : 'd-none'}`}
                        onChange={setBatches}
                        initialData={oldBatches}
                      />
                      {batchesError && (
                        <span className="small text-danger error mt-2">
                          {batchesError}
                        </span>
                      )}
                    </Form>
                    <Form
                      ref={pricesPlacesFormRef}
                      onSubmit={handleSubmitPricesPlaces}
                      className={`${progressNumber === 7 ? 'row' : 'd-none'}`}
                    >
                      <PricesPlaces
                        batches={batches}
                        modalities={modalities}
                        className={`${progressNumber === 7 ? 'row' : 'd-none'}`}
                        onChange={setBatches}
                      />
                      {pricesPlacesError && (
                        <span className="small text-danger error">
                          {pricesPlacesError}
                        </span>
                      )}
                    </Form>

                    <Form
                      ref={financeFormRef}
                      onSubmit={handleSubmitFinance}
                      className={`${progressNumber === 8 ? 'row' : 'd-none'}`}
                    >
                      <div className="col-12">
                        <h2 className="h4 fw-semibold text-center mt-5 mb-4">
                          8 - Financeiro
                        </h2>
                      </div>
                      <div className="col-12 px-lg-2">
                        <h3 className="h6 fw-normal">Contrato</h3>
                        <Select
                          name="contract"
                          className="input"
                          options={contractsOptions}
                          onChange={handleChangeContract}
                          placeholder="Selecione"
                          disabled={contractsOptions.length === 1}
                        />
                      </div>
                      <div className="col-lg-6 mt-3 px-lg-2">
                        <h3 className="h6 fw-normal">Taxa de serviço</h3>
                        <Input
                          readOnly
                          value={`${
                            contractSelected.service_type === 'Valor'
                              ? 'R$'
                              : ''
                          }${parseFloat(contractSelected?.service || '0')
                            .toFixed(2)
                            .replace('.', ',')}${
                            contractSelected.service_type === 'Porcentagem'
                              ? '%'
                              : ''
                          }`}
                          name="serviceTax"
                          className="input"
                        />
                      </div>
                      <div className="col-lg-6 mt-3 px-lg-2">
                        <h3 className="h6 fw-normal">Taxa de processamento</h3>
                        <Input
                          readOnly
                          value={`${
                            contractSelected.tax_type === 'Valor' ? 'R$' : ''
                          }${parseFloat(contractSelected?.tax || '0')
                            .toFixed(2)
                            .replace('.', ',')}${
                            contractSelected.tax_type === 'Porcentagem'
                              ? '%'
                              : ''
                          }`}
                          name="processingTax"
                          className="input"
                        />
                      </div>
                      <div className="col-lg-4 mt-3 px-lg-2">
                        <h3 className="h6 fw-normal">Meta de inscrições</h3>
                        <Input
                          type="number"
                          min={0}
                          name="targetRegistration"
                          className="input"
                          value={targets.registration}
                          onChange={handleTargetRegistration}
                          onWheel={(e: any) => e.target.blur()}
                        />
                      </div>
                      <div className="col-lg-4 mt-3 px-lg-2">
                        <h3 className="h6 fw-normal">Meta de receita</h3>
                        <InputMask
                          onChange={handleTargetRevenue}
                          value={targets.revenue.toFixed(2)}
                          name="targetRevenue"
                          className="input"
                          kind="money"
                        />
                      </div>
                      <div className="col-lg-4 mt-3 px-lg-2">
                        <h3 className="h6 fw-normal">Meta de Ticket médio</h3>
                        <Input
                          readOnly
                          value={formatPrice(
                            targets.revenue / targets.registration
                          )}
                          name="targetAvarageTicket"
                          className="input"
                        />
                      </div>
                      <div className="col-12 mt-4 px-lg-2">
                        <h3 className="h6 fw-normal">Formas de pagamentos</h3>
                        <InputCheckbox
                          type="checkbox"
                          name="paymentsMethods"
                          className="check-payments mt-2 flex-column justify-content-start"
                          options={paymentMethodsOptions}
                          onChange={handleChangePaymentsMethods}
                        />
                        {creditPayment && (
                          <>
                            <h3 className="h6 fw-normal mt-3">
                              Máximo de parcelas
                            </h3>
                            <Select
                              name="maxInstallments"
                              className="input"
                              options={installments}
                              placeholder="Selecione"
                              onChange={handleChangeMaxInstallments}
                            />
                          </>
                        )}
                      </div>
                    </Form>

                    <Form
                      ref={formRef}
                      onSubmit={handleSubmit}
                      className={`${progressNumber === 9 ? 'row' : 'd-none'}`}
                    >
                      <div className="col-12">
                        <h2 className="h4 fw-semibold text-center mt-5 mb-4">
                          9 - Resumo e finalizar
                        </h2>
                        <p className="mb-4 text-center">
                          Seu evento esta quase lá! Basta revisar os dados e
                          salvar
                        </p>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <h2 className="h4 fw-semibold mb-0">Dados básicos</h2>
                          {statusName === 'Rascunho' ? (
                            <button
                              type="submit"
                              className="mb-0 h6 fw-normal text-white btn-new px-5 py-2"
                            >
                              Salvar
                            </button>
                          ) : (
                            <Link
                              to={`${process.env.PUBLIC_URL}/eventos`}
                              className="text-center mb-0 h6 fw-normal text-white btn-new px-3 py-2"
                            >
                              Voltar aos eventos
                            </Link>
                          )}
                        </div>
                      </div>
                      {Object.keys(basicData).length > 0 && (
                        <div className="col-lg-8 px-3">
                          <div className="row">
                            <div className="col-lg-6 px-lg-2 mt-3">
                              <h3 className="h6 fw-normal">Nome do evento</h3>
                              <Input
                                name="name"
                                type="text"
                                value={name}
                                readOnly
                                className="pe-none input"
                              />
                            </div>
                            <div className="col-lg-2 px-lg-2 mt-3">
                              <h3 className="h6 fw-normal">Tipo</h3>
                              <Input
                                name="type"
                                type="text"
                                value={typeName}
                                readOnly
                                className="pe-none input"
                              />
                            </div>
                            <div className="col-lg-2 px-lg-2 mt-3">
                              <h3 className="h6 fw-normal">Data Inicio</h3>
                              <InputDate
                                className="py-1 pe-none"
                                name="date"
                                selected={new Date(basicData.date)}
                                colorSvg="#5D5D5D"
                              />
                            </div>
                            <div className="col-lg-2 px-lg-2 mt-3">
                              <h3 className="h6 fw-normal">Data Fim</h3>
                              <InputDate
                                className="py-1 pe-none"
                                name="end_date"
                                selected={new Date(basicData.end_date)}
                                colorSvg="#5D5D5D"
                              />
                            </div>
                            <div className="col-lg-6 px-lg-2 mt-3">
                              <h3 className="h6 fw-normal">Fim das Vendas</h3>
                              <InputDate
                                className="py-1 pe-none"
                                name="sales_end_date"
                                selected={new Date(basicData.sales_end_date)}
                                colorSvg="#5D5D5D"
                              />
                            </div>
                            <div className="col-lg-6 px-lg-2 mt-3">
                              <h3 className="h6 fw-normal">Status</h3>
                              <Input
                                name="status"
                                type="text"
                                value={statusName}
                                readOnly
                                className="pe-none input"
                              />
                            </div>
                            <div className="col-12 px-lg-2 mt-3">
                              <h3 className="h6 fw-normal">Endereço</h3>
                              <Input
                                value={`${basicData.address}, ${
                                  parseInt(basicData.number, 10) === 0
                                    ? 'S/N'
                                    : basicData.number
                                } - ${basicData.neighborhood} - ${
                                  basicData.city
                                } / ${basicData.state} CEP: ${
                                  basicData.zipcode
                                }`}
                                name="address_summary"
                                type="text"
                                readOnly
                                className="pe-none input"
                              />
                            </div>

                            <div className="col-12 px-lg-2 mt-3">
                              <iframe
                                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_KEY}&q=${addressMap}&zoom=15`}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="map"
                              />
                            </div>
                            <div className="col-12 px-lg-2 mt-3">
                              <h3 className="h6 fw-normal">Descrição</h3>
                              <Textarea
                                name="description"
                                rows={5}
                                value={basicData.description}
                                className="pe-none rounded"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-lg-4 px-3 pt-3">
                        <div>
                          <button
                            type="button"
                            onClick={() => setBtnPhotoProd(true)}
                            className={`${
                              btnPhotoProd
                                ? 'btn-photo-prod-active'
                                : 'btn-photo-prod'
                            } bg-transparent h6 fw-normal roboto`}
                          >
                            Galeria de fotos
                          </button>
                          <button
                            type="button"
                            onClick={() => setBtnPhotoProd(false)}
                            className={`${
                              !btnPhotoProd
                                ? 'btn-photo-prod-active'
                                : 'btn-photo-prod'
                            } bg-transparent h6 fw-normal roboto ms-3`}
                          >
                            Produtos
                          </button>
                        </div>
                        {btnPhotoProd ? (
                          <div>
                            {oldFilesInfo.map((file) => (
                              <div
                                key={file.id}
                                className="d-flex justify-content-between arquives p-3 mt-3"
                              >
                                <div className="d-flex align-items-center">
                                  <img src={file.url} alt={file.name} />
                                  <p className="ms-3 mb-0">{file.name}</p>
                                </div>
                              </div>
                            ))}
                            {filesInfo.map((file, index) => (
                              <div
                                key={index}
                                className="d-flex justify-content-between arquives p-3 mt-3"
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                  />
                                  <p className="ms-3 mb-0">{file.name}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="d-flex overflow-auto pb-2">
                            <div className="w-card-summary px-2 mt-3">
                              <div className="d-flex">
                                {products.map((product, index) => (
                                  <div
                                    key={product.id}
                                    className="kits py-4 px-3 d-flex position-relative"
                                  >
                                    {product.sizes?.length > 0 && (
                                      <div className="d-flex flex-column w-tam">
                                        {product.sizes.map((size: any) => (
                                          <span
                                            key={size.id}
                                            className={`mb-2 ${
                                              size.active ? 'active' : ''
                                            }`}
                                          >
                                            {size.size}
                                          </span>
                                        ))}
                                      </div>
                                    )}
                                    <div className="mx-auto">
                                      <Img
                                        src={product.archive_url as string}
                                        className="product-photo"
                                      />
                                      {product.colors?.length > 0 && (
                                        <div className="d-flex justify-content-center align-items-center mt-2">
                                          {product.colors.map(
                                            (color, colorIndex) => (
                                              <button
                                                key={color.id}
                                                type="button"
                                                className="border-0 bg-transparent btn-color"
                                                onClick={() =>
                                                  handleSelectColor(
                                                    colorIndex,
                                                    index
                                                  )
                                                }
                                              >
                                                <ColorBox
                                                  color={color.hex}
                                                  selected={color.selected}
                                                />
                                              </button>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        <h3 className="poppins py-4">Preços e vagas</h3>
                      </div>
                      {batches.map((batch, index) => (
                        <div key={batch.input_id} className="col-lg-6">
                          <div className="bg-category p-3 mb-3">
                            <h5 className="roboto h5 fw-semibold">
                              Lote {index + 1}
                            </h5>
                            <p className="mb-0">
                              Vagas: {batch.qtdPlaces || 0} | Validade:{' '}
                              {format(batch.expiration, 'dd/MM/yyyy')}
                            </p>
                          </div>
                          {modalities.map((modality) => (
                            <Fragment key={modality.id}>
                              {modality.categories?.map((category) => (
                                <div
                                  key={category.id}
                                  className="bd-lots all-lots mb-3 p-3 d-flex justify-content-between align-items-center"
                                >
                                  <h3 className="h6 mb-0 fw-semibold roboto d-flex align-items-center">
                                    {modality.name}: {category.name}
                                  </h3>
                                  <div>
                                    <span className="w-75 mx-auto roboto h6 fw-normal mb-2 d-block">
                                      Qtd. de vagas
                                    </span>
                                    <Input
                                      type="number"
                                      name="qtd-places"
                                      placeholder="0"
                                      value={
                                        batch.batchesCategories
                                          ? batch.batchesCategories[
                                              batch.batchesCategories?.findIndex(
                                                (batchCategory) =>
                                                  batchCategory.category_id ===
                                                  category.id
                                              )
                                            ]?.quantity || 0
                                          : 0
                                      }
                                      readOnly
                                      className="pe-none w-75 mx-auto input"
                                    />
                                  </div>

                                  <div>
                                    <span className="w-75 mx-auto roboto h6 fw-normal mb-2 d-block">
                                      Preços
                                    </span>
                                    <InputMask
                                      kind="money"
                                      name="price"
                                      placeholder="R$ 0,00"
                                      value={(batch.batchesCategories
                                        ? batch.batchesCategories[
                                            batch.batchesCategories?.findIndex(
                                              (batchCategory) =>
                                                batchCategory.category_id ===
                                                  category.id &&
                                                batchCategory.modality_id ===
                                                  modality.id
                                            )
                                          ]?.price || 0
                                        : 0
                                      ).toFixed(2)}
                                      className="pe-none w-75 mx-auto input"
                                    />
                                  </div>
                                </div>
                              ))}
                            </Fragment>
                          ))}
                        </div>
                      ))}
                      <div className="col-12">
                        <h3 className="poppins py-4">Financeiro</h3>
                      </div>
                      <div className="col-lg-9">
                        <div className="row">
                          <div className="col-12 px-lg-2">
                            <h3 className="h6 fw-normal">Contrato</h3>
                            <Select
                              name="type"
                              className="pe-none input"
                              options={contractsOptions}
                              placeholder="Selecione"
                              disabled={contractsOptions.length === 1}
                            />
                          </div>
                          <div className="col-lg-6 mt-4 px-lg-2">
                            <h3 className="h6 fw-normal">Taxa de serviço</h3>
                            <Input
                              readOnly
                              value={`${
                                contractSelected.service_type === 'Valor'
                                  ? 'R$'
                                  : ''
                              }${parseFloat(contractSelected?.service || '0')
                                .toFixed(2)
                                .replace('.', ',')}${
                                contractSelected.service_type === 'Porcentagem'
                                  ? '%'
                                  : ''
                              }`}
                              name="serviceTax"
                              className="input"
                            />
                          </div>
                          <div className="col-lg-6 mt-4 px-lg-2">
                            <h3 className="h6 fw-normal">
                              Taxa de processamento
                            </h3>
                            <Input
                              readOnly
                              value={`${
                                contractSelected.tax_type === 'Valor'
                                  ? 'R$'
                                  : ''
                              }${parseFloat(contractSelected?.tax || '0')
                                .toFixed(2)
                                .replace('.', ',')}${
                                contractSelected.tax_type === 'Porcentagem'
                                  ? '%'
                                  : ''
                              }`}
                              name="processingTax"
                              className="input"
                            />
                          </div>
                          <div className="col-lg-4 mt-4 px-lg-2">
                            <h3 className="h6 fw-normal">Meta de inscrições</h3>
                            <Input
                              type="number"
                              readOnly
                              min={0}
                              name="targetRegistration"
                              className="input"
                              value={targets.registration}
                            />
                          </div>
                          <div className="col-lg-4 mt-4 px-lg-2">
                            <h3 className="h6 fw-normal">Meta de receita</h3>
                            <Input
                              readOnly
                              value={formatPrice(targets.revenue)}
                              name="targetRevenue"
                              className="input"
                            />
                          </div>
                          <div className="col-lg-4 mt-4 px-lg-2">
                            <h3 className="h6 fw-normal">
                              Meta de Ticket médio
                            </h3>
                            <Input
                              readOnly
                              value={formatPrice(targets.avarageTicket)}
                              name="targetAvarageTicket"
                              className="input"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 px-lg-2">
                        <h3 className="h6 fw-normal mb-0">
                          Formas de pagamentos
                        </h3>
                        <InputCheckbox
                          type="checkbox"
                          name="paymentsMethods"
                          className="check-payments mt-2 flex-column justify-content-start pe-none"
                          options={paymentMethodsOptions}
                          onChange={handleChangePaymentsMethods}
                        />
                        {creditPayment && (
                          <>
                            <h3 className="h6 fw-normal mb-1">
                              Máximo de parcelas
                            </h3>
                            <Input
                              name="maxInstallments"
                              className="input"
                              value={maxInstallments}
                              readOnly
                            />
                          </>
                        )}
                      </div>
                    </Form>

                    <div className="row">
                      <div className="col-12 d-flex justify-content-center mt-4">
                        {progressNumber !== 1 &&
                          !location.pathname.includes('resumo') && (
                            <button
                              type="button"
                              onClick={() =>
                                setProgressNumber(progressNumber - 1)
                              }
                              className="mb-0 me-4 h6 fw-normal text-white btn-new px-5 py-2 mt-2"
                            >
                              Anterior
                            </button>
                          )}
                        {progressNumber !== 9 && (
                          <button
                            type="button"
                            onClick={() => handleClickNext(progressNumber)}
                            className="mb-0 me-4 h6 fw-normal text-white btn-new px-5 py-2 mt-2"
                          >
                            Próximo
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading active={loading} text="Salvando..." />
      <Modal
        className="modal-filter"
        size="lg"
        centered
        show={show}
        onHide={handleClose}
      >
        <Form ref={productFormRef} onSubmit={handleSubmitProduct}>
          <Modal.Header className="justify-content-between align-items-center border-0 px-3 px-sm-5 pb-0 pt-0">
            <Modal.Title className="mb-0 mt-4">
              <h2 className="title mb-0">Novo produto</h2>
            </Modal.Title>
            <button
              type="button"
              onClick={handleClose}
              className="border-0 bg-transparent"
            >
              <KrClose size={20} color="#989898" />
            </button>
          </Modal.Header>
          <Modal.Body className="pt-0 px-3 px-sm-5">
            <div className="row">
              <div className="col-12">
                <hr />
                <h3>Dados do produto</h3>
              </div>
              <div className="col-12">
                <label className="d-block mt-3">
                  <span className="h6 fw-normal">Nome do produto</span>
                  <Input name="name" type="text" className="input mt-2" />
                </label>
              </div>
              <div className="col-12">
                <InputCheckbox
                  type="checkbox"
                  name="color_variation"
                  className="mt-3 d-block"
                  options={[
                    {
                      label: 'Possui variação cor?',
                      value: 'Possui variação cor?',
                      selected: checkVariationColor,
                    },
                  ]}
                  onChange={handleChangeCheckVariationColor}
                />
              </div>
              {checkVariationColor ? (
                <div className="col-12">
                  {colors.map((color, index) => (
                    <div className={index + 1 ? 'mt-4' : ''}>
                      <ProductColors
                        color={color}
                        onChangeColor={(colorData) =>
                          handleChangeColor(colorData, index)
                        }
                      />
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={handleClickAddNewColor}
                    className="w-100 p-2 bg-transparent add-new-color mt-3"
                  >
                    {' '}
                    <HiPlus size={16} color="#D4D4D4" className="me-2" />
                    Adicionar nova cor
                  </button>
                </div>
              ) : (
                <>
                  <div className="col-12">
                    <InputCheckbox
                      type="checkbox"
                      name="size_variation"
                      className="mt-3 d-block"
                      options={[
                        {
                          label: 'Possui variação de tamanho?',
                          value: 'Possui variação de tamanho?',
                          selected: checkVariationSize,
                        },
                      ]}
                      onChange={() =>
                        setCheckVariationSize(!checkVariationSize)
                      }
                    />
                  </div>
                  {checkVariationSize ? (
                    <div className="col-12">
                      <div className="variations text-center my-3">
                        <div className="d-flex align-items-center variation-row">
                          <div>
                            <p className="mb-0 h6">Tam.</p>
                          </div>
                          <div>
                            <p className="mb-0 h6 text-start">
                              Qtd. Disponível
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center variation-row">
                          <div>
                            <p className="mb-0 size">PP</p>
                          </div>
                          <div>
                            <Input
                              name="qtyPp"
                              type="number"
                              className="input"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center variation-row">
                          <div>
                            <p className="mb-0 size">P</p>
                          </div>
                          <div>
                            <Input
                              name="qtyP"
                              type="number"
                              className="input"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center variation-row">
                          <div>
                            <p className="mb-0 size">M</p>
                          </div>
                          <div>
                            <Input
                              name="qtyM"
                              type="number"
                              className="input"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center variation-row">
                          <div>
                            <p className="mb-0 size">G</p>
                          </div>
                          <div>
                            <Input
                              name="qtyG"
                              type="number"
                              className="input"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center variation-row">
                          <div>
                            <p className="mb-0 size">GG</p>
                          </div>
                          <div>
                            <Input
                              name="qtyGg"
                              type="number"
                              className="input"
                            />
                          </div>
                        </div>
                      </div>
                      {qtySizeError && (
                        <small className="error text-danger">
                          {qtySizeError}
                        </small>
                      )}
                    </div>
                  ) : (
                    <div className="col-12">
                      <label className="d-block mt-3">
                        <span className="h6 fw-normal">Qtd. Disponível</span>
                        <Input
                          name="qty"
                          type="number"
                          className="input mt-2"
                        />
                      </label>
                    </div>
                  )}
                  <div className="col-12">
                    <label
                      htmlFor="photos"
                      className="w-100 label-modal text-center mt-3"
                      onDrop={handleDropColor}
                      onDragOver={handleDragOver}
                    >
                      <MdUpload size={48} color="#FF6900" />
                      <span className="d-block">
                        Arraste e solte o arquivo aqui para
                        <br />
                        adicionar uma imagem
                      </span>
                      <span className="d-inline-flex my-2">
                        Selecionar Imagem
                      </span>
                      <span className="d-block">
                        Extensões aceitas: jpg, png, jpeg.
                      </span>
                    </label>
                    <Input
                      id="photos"
                      type="file"
                      name="photos"
                      className="d-none"
                      onChange={handleFileColorChange}
                    />
                    {filesColor.map((file, index) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className="d-flex justify-content-between arquives p-3 mt-3"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                          />
                          <p className="ms-3 mb-0">{file.name}</p>
                        </div>
                        <button
                          type="button"
                          className="bg-transparent border-0"
                          onClick={() => handleDeleteColor(index)}
                        >
                          <IoMdClose size={24} color="#8C8C8C" />
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4 px-5">
            <button
              type="submit"
              className="me-auto btn-filter d-flex align-items-center justify-content-center btn h6 fw-normal py-2 px-4 mt-2 mb-0"
            >
              Adicionar produto
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default UpdateEvent;
