/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FocusEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { IoMdRemove } from 'react-icons/io';

import Select, { IOption } from '~/components/Select';
import { ICategoryData } from '..';

interface ICategory {
  categories: IOption[];
  selectedCategory: ICategoryData;
  onSelectCategory(value: IOption): void;
  onChangeCategory(value: string): void;
  onFocus?: FocusEventHandler<any> | undefined;
  onBlur?: FocusEventHandler<any> | undefined;
  onClickAddNew(): void;
  onRemoveCategory(): void;
}

const Category: React.FC<ICategory> = ({
  categories: categoriesData,
  selectedCategory,
  onSelectCategory,
  onChangeCategory,
  onFocus,
  onBlur,
  onClickAddNew,
  onRemoveCategory,
}) => {
  const [categories, setCategories] = useState<IOption[]>([]);
  const [isWritten, setIsWritten] = useState(false);
  const [valueSelected, setValueSelected] = useState('');

  useEffect(() => {
    setCategories(
      categoriesData.map((category) => {
        return {
          ...category,
          selected: !isWritten && selectedCategory.id === category.id,
        };
      })
    );
  }, [isWritten, categoriesData, selectedCategory.id]);

  const handleChangeCategory = useCallback(
    (value) => {
      setIsWritten(true);
      onChangeCategory(value);
      setValueSelected(value);
    },
    [onChangeCategory]
  );

  const handleSelectCategory = useCallback(
    (option) => {
      onSelectCategory(option);
      setValueSelected(option.value);
      setIsWritten(false);
    },
    [onSelectCategory]
  );

  const handleClickRemoveCategory = useCallback(() => {
    setValueSelected('');
    setIsWritten(false);
    onRemoveCategory();
  }, [onRemoveCategory]);

  return (
    <div className="bd-modality d-flex justify-content-between align-items-center p-2 mb-2">
      <Select
        name={`add-modality-${selectedCategory.input_id}`}
        placeholder="Selecionar categoria"
        className="border-0 p-0"
        options={categories}
        onChange={handleSelectCategory}
        onChangeText={handleChangeCategory}
        onFocus={onFocus}
        onBlur={onBlur}
        addNew={categories.length === 0 && !!selectedCategory.name}
        textBtnAddNew={`Adicionar e usar a categoria ${selectedCategory.name}`}
        onClickAddNew={onClickAddNew}
        valueSelected={valueSelected}
      />
      <button
        type="button"
        className="bg-transparent border-0 ms-2"
        onClick={handleClickRemoveCategory}
      >
        <IoMdRemove color="#000" size={16} />
      </button>
    </div>
  );
};

export default Category;
