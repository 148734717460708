import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  min-width: calc(100% - 88px);

  h1 {
    font-size: 26px;
    font-weight: 500;
  }

  hr {
    background: #e1e1e1;
    opacity: 1;
  }

  a {
    :hover {
      color: unset;
    }
  }

  .container-fluid {
    max-width: 1460px;
  }

  .btn-new {
    border-radius: 4px;
    background: #ff6900;
    border: none;
  }

  .bg-white {
    border-radius: 8px;
  }

  .bg-balance {
    border-radius: 8px;
    background: linear-gradient(268deg, #ff9e2d 0%, #ff6900 100%);
  }
`;

export const Filter = styled.div`
  h2 {
    color: #000;
    font-family: 'Roboto';
  }
`;

export const Balance = styled.div`
  font-family: 'Roboto';

  .btn-withdraw {
    font-family: 'Poppins';
    color: #fff;
    width: 200px;
    border-radius: 4px;
    background: #002f6c;
    padding: 6px;
  }

  .min-w {
    min-width: 223px;
  }

  .min-w-bal {
    min-width: 106px;
  }

  .bg-arrow {
    background-color: #ff8935;
    line-height: 14px;
  }

  .poppins {
    font-family: 'Poppins';
  }
`;

export const Cashflow = styled.div`
  h2 {
    position: relative;
    z-index: 1;
  }

  hr {
    position: relative;
    z-index: 1;
  }
  .table {
    div {
      border: none;
    }
  }

  .tb-row {
    div:nth-child(1) {
      color: #000;
      font-weight: 600;
    }
    div:nth-child(4) {
      color: #ff003d;
    }
    div:nth-child(5) {
      color: #00b15c;
    }
  }

  /* .tb-header .tb-row button {
    svg {
      display: none;
    }
  } */
  .number-id {
    width: 189px !important;
  }

  .content {
    padding-top: 5.5rem;
    margin-top: -4.5rem;
  }
  .content {
    ::-webkit-scrollbar {
      background: transparent;
      width: 5px;
      height: 8px;
      border: none;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #002f6c;
      border-radius: 4px;
    }
  }
`;
export const Extract = styled.div`
  font-family: 'Roboto';
  p {
    color: #878787;
  }
  .bd {
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }

  .btn-filter {
    margin-top: 0px;
  }

  .btn-ticket {
    background-color: #ff842f;
    padding: 4px 8px;
    height: 32px;
    width: 32px;
    display: inline-block;
    img {
      width: 16px;
      height: 16px;
    }
  }

  .green {
    color: #00b15c;
  }

  .gray {
    color: #878787;
  }

  .blue {
    color: #00b2ff;
  }

  .tooltip-total:after {
    background-color: #002f6c;
    color: #fff;
    border: 1px solid #002f6c;
    border-radius: 15px;
    box-sizing: border-box;
    content: attr(data-tooltip);
    display: none;
    padding: 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    position: absolute;
    bottom: 50px;
    width: 250px;
    z-index: 10000;
  }

  .tooltip-total:hover:after {
    display: block;
  }
`;

export const Modal = styled(ModalComponent)`
  backdrop-filter: blur(3px);

  .title {
    color: #000;
    font-size: 26px;
    font-weight: 500;
  }

  h3 {
    font-size: 26px;
    font-weight: 500;
  }

  h4 {
    font-family: 'Roboto';
    color: #000;
  }

  span {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
  }

  p {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    color: #5d5d5d !important;
  }

  .roboto {
    font-family: 'Roboto';
  }

  .height {
    height: 40px;
    border-radius: 4px;
  }

  .input {
    border: 1px solid #d3d3d3 !important;

    input {
      color: #202020;

      ::placeholder {
        color: #d3d3d3;
      }
    }
  }

  .height-date {
    height: 31px;
  }

  .btn-filter {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }
`;
