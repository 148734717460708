import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

import circle from '~/assets/icons/switch-circle.svg';
import circleWhite from '~/assets/icons/switch-circle-white.svg';

export const Container = styled.div`
  min-width: calc(100% - 88px);

  h1 {
    font-size: 26px;
    font-weight: 500;
  }

  hr {
    background: #e1e1e1;
    opacity: 1;
  }

  a {
    :hover {
      color: unset;
    }
  }

  .container-fluid {
    max-width: 1460px;
  }

  .form-switch .form-check-input:focus {
    background-image: url(${circle});
  }
  .form-check-input:checked {
    background-color: #ff6900;
    border-color: #ff6900;
    background-image: url(${circleWhite}) !important;
  }
  .form-check-input {
    width: 3rem;
    height: 1.5rem;
    background-color: transparent;
    border-color: #d3d3d3;
  }

  .btn-new {
    border-radius: 4px;
    background: #ff6900;
    border: none;
  }

  .bg-white {
    border-radius: 8px;
  }
`;

export const Filter = styled.div`
  h2 {
    color: #000;
    font-family: 'Roboto';
  }
`;

export const Cards = styled.div`
  font-family: 'Roboto';
  h3 {
    color: #5d5d5d;
    font-size: 16px;
    font-weight: 400;
  }

  p {
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }

  .default {
    max-width: 96px;
    max-height: 96px;
  }

  .bg-orange {
    background-color: #ff842f;
    padding: 4px 8px;
    height: 32px;
    width: 32px;
    display: inline-block;
  }
`;

export const Modal = styled(ModalComponent)`
  backdrop-filter: blur(3px);

  .title {
    color: #000;
    font-size: 26px;
    font-weight: 500;
  }

  h3 {
    font-size: 26px;
    font-weight: 500;
  }

  h4 {
    font-family: 'Roboto';
    color: #000;
  }

  span {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
  }

  p {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    color: #5d5d5d !important;
  }

  .roboto {
    font-family: 'Roboto';
  }

  .height {
    height: 40px;
    border-radius: 4px;
  }

  .input {
    border: 1px solid #d3d3d3 !important;

    input {
      color: #202020;

      ::placeholder {
        color: #d3d3d3;
      }
    }
  }

  .height-date {
    height: 31px;
  }

  .btn-filter {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }
`;
