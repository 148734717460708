import React from 'react';

import { HiPlus } from 'react-icons/hi';
import { Container } from './styles';

const BtnNewEvent: React.FC = () => {
  return (
    <Container
      to={`${process.env.PUBLIC_URL}/eventos/novo-evento`}
      className="mb-0 h6 fw-normal text-white btn-new px-5 py-2"
    >
      <HiPlus size={16} color="#fff" className="me-2" />
      Novo Evento
    </Container>
  );
};

export default BtnNewEvent;
