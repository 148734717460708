import styled from 'styled-components';

interface IProps {
  src: string;
}

export const Container = styled.div`
  min-width: calc(100% - 88px);

  h1 {
    font-size: 26px;
    font-weight: 500;
  }

  h3 {
    font-family: 'Roboto';
  }

  hr {
    background: #e1e1e1;
    opacity: 1;
  }

  a {
    :hover {
      color: unset;
    }
  }

  p {
    color: #878787;
    font-size: 14px;
    font-weight: 400;
  }

  .pending {
    color: #ff003d;
  }

  .visualized {
    color: #ffa800;
  }

  .solved {
    color: #00b15c;
  }

  .date {
    color: #8c8c8c;
  }

  .status {
    font-family: 'Roboto';
  }

  .bg-orange {
    background-color: #ff842f;
    padding: 4px 8px;
  }

  .bg-contact {
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 0.87);
    padding: 5px 8px;
    color: #5e5e5e;
    font-weight: 400;
  }

  .container-fluid {
    max-width: 1460px;
  }

  .btn-new {
    border-radius: 4px;
    background: #ff6900;
    border: none;
  }

  .bg-white {
    border-radius: 8px;
  }
`;

export const Avatar = styled.div<IProps>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 37px;
  min-height: 37px;
  border-radius: 64px;
  border: 1px solid #8c8c8c;
`;
