import React from 'react';
import { IProps } from './interfaces';

const KrEdit: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.8 25.38C29.8 16.77 22.66 10.13 20.52 8.33999L23.7 2.14999C23.9 1.75999 23.79 1.28999 23.44 1.01999C21.02 -0.820009 17.42 0.499991 16.22 1.02999C11.3 -1.10001 8.20999 1.86999 8.07999 1.99999C7.77998 2.29999 7.72999 2.76999 7.95999 3.11999L11.32 8.37999C9.11998 10.23 2.09999 16.82 2.09999 25.32C2.09999 25.43 1.95999 28.03 3.87999 29.96C5.17999 31.26 7.05999 31.92 9.42999 31.92C9.67999 31.92 9.92998 31.92 10.19 31.9L15.89 31.92H15.94H15.99L21.64 31.9C21.91 31.91 22.17 31.92 22.42 31.92C24.83 31.92 26.7 31.26 28 29.96C29.92 28.03 29.79 25.43 29.79 25.37L29.8 25.38ZM15.84 2.83999C16.09 2.95999 16.39 2.95999 16.64 2.83999C17.55 2.37999 20.01 1.47999 21.71 2.10999L18.84 7.68999H16.01C16.01 7.68999 15.98 7.68999 15.96 7.69999C15.94 7.69999 15.93 7.68999 15.91 7.68999H13.02L9.93998 2.86999C10.89 2.28999 12.96 1.45999 15.86 2.83999H15.84ZM26.74 28.7C25.68 29.76 24 30.24 21.7 30.11L15.95 30.13L10.15 30.11C7.90998 30.23 6.22999 29.76 5.16999 28.71C3.81999 27.37 3.88999 25.45 3.88999 25.38C3.88999 17.18 11.4 10.64 12.82 9.47999H15.89C15.89 9.47999 15.92 9.47999 15.94 9.46999C15.96 9.46999 15.97 9.47999 15.99 9.47999H19.06C20.48 10.64 27.99 17.19 27.99 25.44C27.99 25.46 28.06 27.36 26.73 28.7H26.74Z"
        fill={color}
      />
      <path
        d="M14.87 18.06H18.11C18.44 18.06 18.71 17.79 18.71 17.46C18.71 17.13 18.44 16.86 18.11 16.86H16.7V16C16.7 15.67 16.43 15.4 16.1 15.4C15.77 15.4 15.5 15.67 15.5 16V16.86H14.87C14.07 16.86 13.43 17.51 13.43 18.3V19.54C13.43 20.34 14.08 20.98 14.87 20.98H17.26C17.39 20.98 17.5 21.09 17.5 21.22V22.82C17.5 22.95 17.39 23.06 17.26 23.06H14.02C13.69 23.06 13.42 23.33 13.42 23.66C13.42 23.99 13.69 24.26 14.02 24.26H15.7V25.12C15.7 25.45 15.97 25.72 16.3 25.72C16.63 25.72 16.9 25.45 16.9 25.12V24.26H17.26C18.06 24.26 18.7 23.61 18.7 22.82V21.22C18.7 20.42 18.05 19.78 17.26 19.78H14.87C14.74 19.78 14.63 19.67 14.63 19.54V18.3C14.63 18.17 14.74 18.06 14.87 18.06Z"
        fill={color}
      />
    </svg>
  );
};

export default KrEdit;
