import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  backdrop-filter: blur(3px);

  .title {
    color: #000;
    font-size: 26px;
    font-weight: 500;
  }

  h3 {
    font-size: 26px;
    font-weight: 500;
  }

  h4 {
    font-family: 'Roboto';
    color: #000;
  }

  span {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
  }

  p {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    color: #5d5d5d !important;
  }

  .roboto {
    font-family: 'Roboto';
  }

  .height {
    height: 40px;
    border-radius: 4px;
  }

  .height-date {
    height: 31px;
  }

  .btn-filter {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }
`;
