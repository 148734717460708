import React, { useCallback, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import { Modal } from './styles';
import Input from '../Input';
import InputMask from '../InputMask';
import { KrClose } from '../KoroIcons';

interface IModal {
  show: boolean;
  onClose(): void;
}

const ModalNewUser: React.FC<IModal> = ({ show, onClose }) => {
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [celphone, setCelphone] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');

  const handleName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const handleCpf = useCallback((e) => {
    setCpf(e.target.value);
  }, []);

  const handleCelphone = useCallback((e) => {
    setCelphone(e.target.value);
  }, []);

  const handleEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleChangeZipCode = useCallback(async (e) => {
    const { value } = e.target;
    if (value.length === 9) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      setZipCode(response.data.cep);
      setState(response.data.uf);
      setCity(response.data.localidade);
      setAddress(response.data.logradouro);
      setNeighborhood(response.data.bairro);
    }
  }, []);

  const handleState = useCallback((e) => {
    setState(e.target.value);
  }, []);

  const handleCity = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const handleAddress = useCallback((e) => {
    setAddress(e.target.value);
  }, []);

  const handleNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.value);
  }, []);

  const handleNumber = useCallback((e) => {
    setNumber(e.target.value);
  }, []);

  const handleComplement = useCallback((e) => {
    setComplement(e.target.value);
  }, []);

  useEffect(() => {
    if (onClose) {
      onClose;
      setName('');
      setCpf('');
      setCelphone('');
      setEmail('');
      setState('');
      setZipCode('');
      setCity('');
      setAddress('');
      setNeighborhood('');
      setNumber('');
      setComplement('');
    }
  }, [onClose]);

  return (
    <Modal
      className="zoom modal-filter"
      size="lg"
      centered
      show={show}
      onHide={onClose}
    >
      <Form onSubmit={() => console.log('submit')}>
        <Modal.Header className="justify-content-between align-items-center border-0 px-3 px-sm-5 pb-0 pt-0">
          <Modal.Title className="mb-0 mt-4">
            <h2 className="title mb-0">Adicionar usuário</h2>
          </Modal.Title>
          <button
            type="button"
            onClick={onClose}
            className="border-0 bg-transparent"
          >
            <KrClose size={20} color="#989898" />
          </button>
        </Modal.Header>
        <Modal.Body className="container pt-0 px-3 px-sm-5">
          <div className="row">
            <div className="col-12">
              <hr className="mb-5" />

              <h4 className="h6 fw-normal">Nome completo</h4>
              <Input
                name="voucher_name"
                type="text"
                className="height"
                onChange={handleName}
                value={name}
              />
            </div>

            <div className="col-lg-6 mt-3">
              <h4 className="h6 fw-normal">CPF</h4>
              <InputMask
                kind="custom"
                options={{ mask: '999.999.999-99' }}
                name="document"
                className="height"
                onChange={handleCpf}
                value={cpf}
              />
            </div>
            <div className="col-lg-6 mt-3">
              <h4 className="h6 fw-normal">Telefone</h4>
              <InputMask
                kind="cel-phone"
                name="phone"
                className="height"
                onChange={handleCelphone}
                value={celphone}
              />
            </div>
            <div className="col-lg-12 mt-3">
              <h4 className="h6 fw-normal">E-mail</h4>
              <Input
                name="email"
                type="email"
                className="height"
                onChange={handleEmail}
                value={email}
              />
            </div>

            <h2 className="title mt-4">Endereço</h2>
            <div className="col-lg-3 mt-3">
              <h4 className="h6 fw-normal">CEP</h4>
              <InputMask
                kind="custom"
                options={{ mask: '99999-999' }}
                name="zipcode"
                className="height"
                onChange={handleChangeZipCode}
                value={zipCode}
              />
            </div>
            <div className="col-lg-7 mt-3">
              <h4 className="h6 fw-normal">Logradouro</h4>
              <Input
                name="street"
                className="height"
                onChange={handleAddress}
                value={address}
              />
            </div>
            <div className="col-lg-2 mt-3">
              <h4 className="h6 fw-normal">Número</h4>
              <Input
                name="number"
                className="height"
                onChange={handleNumber}
                value={number}
              />
            </div>
            <div className="col-lg-4 mt-3">
              <h4 className="h6 fw-normal">Bairro</h4>
              <Input
                name="neighborhood"
                className="height"
                onChange={handleNeighborhood}
                value={neighborhood}
              />
            </div>
            <div className="col-lg-4 mt-3">
              <h4 className="h6 fw-normal">Cidade</h4>
              <Input
                name="city"
                className="height"
                onChange={handleCity}
                value={city}
              />
            </div>
            <div className="col-lg-4 mt-3">
              <h4 className="h6 fw-normal">Estado</h4>
              <Input
                name="state"
                className="height"
                onChange={handleState}
                value={state}
              />
            </div>
            <div className="col-lg-12 mt-3">
              <h4 className="h6 fw-normal">Complemento</h4>
              <Input
                name="complement"
                className="height"
                onChange={handleComplement}
                value={complement}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4 px-5">
          <button
            type="submit"
            className="ms-auto btn-filter d-flex align-items-center justify-content-center btn h6 fw-normal py-2 px-4 mt-2 mb-0"
          >
            Salvar
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalNewUser;
