import React from 'react';
import { IProps } from './interfaces';

const KrTargetRevenueBars: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2706)">
        <path
          d="M14.37 17.62H9.48C9.02 17.62 8.66 17.99 8.66 18.44V31.11C8.66 31.57 9.03 31.93 9.48 31.93H14.37C14.83 31.93 15.19 31.56 15.19 31.11V18.44C15.19 17.98 14.82 17.62 14.37 17.62ZM13.55 30.29H10.3V19.27H13.55V30.29Z"
          fill={color}
        />
        <path
          d="M5.99 23.26L0.949998 23.23C0.739998 23.21 0.549998 23.31 0.399998 23.46C0.249998 23.6 0.169998 23.8 0.169998 24.01V31.09C0.169998 31.51 0.509998 31.86 0.939998 31.86L5.98 31.89C6.18 31.89 6.38 31.81 6.52 31.66C6.67 31.52 6.75 31.32 6.75 31.11V24.03C6.75 23.61 6.41 23.26 5.98 23.26H5.99ZM5.21 30.33L1.72 30.31V24.78L5.21 24.8V30.33Z"
          fill={color}
        />
        <path
          d="M22.75 11.72H17.86C17.39 11.72 17.01 12.1 17.01 12.57V31.11C17.01 31.58 17.39 31.96 17.86 31.96H22.75C23.22 31.96 23.6 31.58 23.6 31.11V12.57C23.6 12.1 23.22 11.72 22.75 11.72ZM21.9 30.26H18.7V13.42H21.9V30.26Z"
          fill={color}
        />
        <path
          d="M31.14 2.97998H26.25C25.75 2.97998 25.35 3.37998 25.35 3.87998V31.11C25.35 31.61 25.75 32.01 26.25 32.01H31.14C31.64 32.01 32.04 31.61 32.04 31.11V3.87998C32.04 3.37998 31.64 2.97998 31.14 2.97998ZM30.24 30.21H27.15V4.77998H30.24V30.21Z"
          fill={color}
        />
        <path
          d="M3.47 15.68C3.59 15.68 3.71 15.64 3.82 15.57L17.11 5.99002C17.38 5.80002 17.44 5.42002 17.25 5.15002C17.06 4.88002 16.68 4.82002 16.41 5.01002L3.12 14.59C2.85 14.78 2.79 15.16 2.98 15.43C3.1 15.59 3.28 15.68 3.47 15.68Z"
          fill={color}
        />
        <path
          d="M14.93 3.26001L18.89 3.88001L18.27 7.84001C18.22 8.17001 18.44 8.47001 18.77 8.53001C18.8 8.53001 18.83 8.53001 18.86 8.53001C19.15 8.53001 19.41 8.32001 19.45 8.02001L20.25 2.88001L15.11 2.08001C14.79 2.03001 14.48 2.25001 14.42 2.58001C14.37 2.91001 14.59 3.21001 14.92 3.27001L14.93 3.26001Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2706">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrTargetRevenueBars;
