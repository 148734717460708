import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Roboto';

  .small {
    font-size: 12px;
  }

  .bg-filter {
    background-color: transparent;
  }

  .btn-filter {
    background: #002f6c;
    border: none;
    color: #fff;
    margin-top: 28px;
  }

  .btn-clear {
    color: #989898;
    font-size: 14px;
    font-weight: 400;
  }

  .height {
    height: 34px;
    border-radius: 4px;
  }

  .bd-select {
    border: 1px solid #d3d3d3;
  }
`;
