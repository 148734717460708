import React from 'react';
import { IProps } from './interfaces';

const KrEdit: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2682 0.0143072C24.0854 0.10914 23.0236 0.651041 22.244 1.55872L21.0209 2.99476L3.29277 23.8308C3.14492 23.9934 3.05083 24.2102 3.01051 24.4269L2.01591 30.5775C1.9487 30.9975 2.09655 31.4175 2.41913 31.702C2.64762 31.8916 2.91643 32 3.21212 32C3.33309 32 3.46749 31.9865 3.58846 31.9458L9.462 30.0085C9.67705 29.9408 9.86522 29.8188 9.99963 29.6427L27.3783 9.19953L28.9374 7.35706C29.7036 6.44938 30.0799 5.29784 29.9858 4.11921C29.8917 2.92702 29.3541 1.85677 28.4536 1.07101C27.5531 0.298805 26.4106 -0.0805254 25.2413 0.0143072H25.2682ZM25.4563 2.45286C25.9805 2.41222 26.5047 2.57479 26.9079 2.92702C27.3111 3.27926 27.5531 3.76697 27.6068 4.30887C27.6471 4.85077 27.4724 5.36558 27.1364 5.772L26.7063 6.28681L23.6687 3.65859L24.0988 3.14378C24.4483 2.73736 24.9321 2.47996 25.4698 2.43931L25.4563 2.45286ZM8.41364 27.8003L4.74435 29.006L5.36262 25.1721L21.7333 5.92103L24.7709 8.54924L8.40019 27.8003H8.41364Z"
        fill={color}
      />
    </svg>
  );
};

export default KrEdit;
