import React from 'react';
import { IProps } from './interfaces';

const KrList: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5206_2681)">
        <path
          d="M11.42 9.03002H26.37C26.7 9.03002 26.97 8.76002 26.97 8.43002C26.97 8.10002 26.7 7.83002 26.37 7.83002H11.42C11.09 7.83002 10.82 8.10002 10.82 8.43002C10.82 8.76002 11.09 9.03002 11.42 9.03002Z"
          fill={color}
        />
        <path
          d="M8.65 6.08999L6.52 8.77999L5.86 8.05999C5.64 7.81999 5.26 7.79999 5.01 8.01999C4.77 8.23999 4.75 8.61999 4.97 8.86999L6.1 10.1C6.21 10.22 6.37 10.29 6.54 10.29C6.54 10.29 6.55 10.29 6.56 10.29C6.73 10.29 6.9 10.2 7.01 10.06L9.58 6.80999C9.79 6.54999 9.74 6.16999 9.48 5.96999C9.22 5.76999 8.84 5.80999 8.64 6.06999L8.65 6.08999Z"
          fill={color}
        />
        <path
          d="M26.37 16.6C26.7 16.6 26.97 16.33 26.97 16C26.97 15.67 26.7 15.4 26.37 15.4H11.42C11.09 15.4 10.82 15.67 10.82 16C10.82 16.33 11.09 16.6 11.42 16.6H26.37Z"
          fill={color}
        />
        <path
          d="M8.65 14L6.52 16.69L5.86 15.97C5.64 15.73 5.26 15.71 5.01 15.93C4.77 16.15 4.75 16.53 4.97 16.78L6.1 18.01C6.21 18.13 6.37 18.2 6.54 18.2H6.56C6.73 18.2 6.9 18.11 7.01 17.97L9.58 14.72C9.79 14.46 9.74 14.08 9.48 13.88C9.22 13.67 8.84 13.72 8.64 13.98L8.65 14Z"
          fill={color}
        />
        <path
          d="M26.58 22.97H11.63C11.3 22.97 11.03 23.24 11.03 23.57C11.03 23.9 11.3 24.17 11.63 24.17H26.58C26.91 24.17 27.18 23.9 27.18 23.57C27.18 23.24 26.91 22.97 26.58 22.97Z"
          fill={color}
        />
        <path
          d="M8.65 21.92L6.52 24.61L5.86 23.89C5.64 23.65 5.26 23.63 5.01 23.85C4.77 24.07 4.75 24.45 4.97 24.7L6.1 25.93C6.21 26.05 6.37 26.12 6.54 26.12H6.56C6.73 26.12 6.9 26.03 7.01 25.89L9.58 22.64C9.79 22.38 9.74 22 9.48 21.8C9.22 21.6 8.84 21.64 8.64 21.9L8.65 21.92Z"
          fill={color}
        />
        <path
          d="M29.26 0.039978H2.74C1.25 0.039978 0.0400009 1.24998 0.0400009 2.73998V29.27C0.0400009 30.76 1.25 31.97 2.74 31.97H29.27C30.76 31.97 31.97 30.76 31.97 29.27V2.73998C31.97 1.24998 30.76 0.039978 29.27 0.039978H29.26ZM30.16 29.26C30.16 29.76 29.76 30.16 29.26 30.16H2.74C2.24 30.16 1.84 29.76 1.84 29.26V2.73998C1.84 2.23998 2.24 1.83998 2.74 1.83998H29.27C29.77 1.83998 30.17 2.23998 30.17 2.73998V29.27L30.16 29.26Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5206_2681">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrList;
