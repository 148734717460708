import styled from 'styled-components';

export const Container = styled.div`
  small {
    font-size: 12px;
  }
  .qty-size {
    width: 191px;
  }
  .border-danger {
    border: 1px solid #dc3545 !important;
  }

  .bd-lots {
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }

  .all-lots {
    width: 100%;
  }

  .all-lots :nth-child(1),
  .all-lots :nth-child(2),
  .all-lots :nth-child(3) {
    width: 33.33%;
  }
`;
